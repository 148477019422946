import React from 'react';

export default function ListOptionsArray({createOptions = [] }) {

    return (
        <React.Fragment>
            { createOptions?.map((element, index) => (
              <option key={index} value={element}>{element}</option>
            ))}
        </React.Fragment>
    );
  }