/* eslint-disable eqeqeq */
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext, useState } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import clsx from 'clsx';
import { useEffect } from 'react';
import { getAllFornecedor } from '../../../services/fornecedores';
import { isBibiUser } from '../../../utils/roles';
import DialogFornecedores from '../../../components/ModalFornecedores';
import { useLoading } from '../../../providers/LoadingProvider';
import {
  ActionButton,
  Actions,
  Button,
  EmptyText,
  FilesContainer,
  FolderFileContainer,
  FolderHeader,
  FolderMoveContainer,
  InputFile,
  InputSearch,
  ModalActions,
  ModalFoldersContainer,
  MoveButton,
} from './styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { FolderService } from '../../../services/folder.service';
import {
  Assignment,
  DeleteForever,
  FolderRounded,
  Redo,
  RemoveRedEye,
} from '@material-ui/icons';
import { useDebounce } from './useDebounce';
import { upload } from '../../../services/upload';
import { raiseAlert } from '../../../components/Alert';
import BaseModal from '../../../components/BaseModal';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  title: {
    fontSize: 36,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    padding: '0rem 0rem 5rem 0rem',
  },
  whiteSet: {
    backgroundColor: '#FFFFFF',
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-4rem',
  },
  button: {
    height: '100%',
    padding: '.6rem 1.4rem',
    border: '1px solid #FFFFFF',
    fontWeight: 'bold',
    borderRadius: '10px',
    background: '#FFFFFF',
    cursor: 'pointer',
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  buttonSave: {
    width: '100%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export function Files() {
  const classes = useStyles();
  const { userState } = useContext(UserContext);
  const [suppliers, setSuppliers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [folders, setFolders] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState([]);
  const { setLoading } = useLoading();
  const [supplierSelected, setSupplierSelected] = useState(null);

  const history = useHistory();
  const { supplier, folder } = useParams();

  async function getSuppliers(sup) {
    const response = await getAllFornecedor();

    const s = response.data
      .map(({ ID, NOME }) => ({
        value: ID,
        label: NOME,
      }))
      .find((s) => s.value == sup);

    setSupplierSelected(s);

    setSuppliers(response.data);
  }

  async function getFiles(folder) {
    setFiles([]);
    setLoading(true);
    const data = await FolderService.getFilesByFolder(folder);
    setFiles(data);
    setLoading(false);
  }

  async function getFolders() {
    if (!supplier) {
      setAllFolders([]);
      return;
    }

    let data = [];
    data = await FolderService.getBySupplier(supplier);
    setAllFolders(data);

    if (folder) {
      await getFiles(folder);
      setPath(getFolderPath(data));
      setFolders(data.filter((item) => item?.PASTA == folder));
    } else {
      setFolders(data.filter((item) => item?.PASTA == null));
    }
  }

  function handleAddFolder(newFolder) {
    setAllFolders((folders) => [newFolder, ...folders]);
    setFolders((folders) => [newFolder, ...folders]);
  }

  function handleAddFile(newFile) {
    setFiles((files) => [newFile, ...files]);
  }

  useEffect(() => {
    getSuppliers(supplier);
  }, [supplier]);

  useEffect(() => {
    getFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier, folder]);

  function getFolderPath(allFolders, arr = [], parentFolder = null) {
    if (!folder) return [];

    let newArr = [];

    if (arr.length == 0 && !parentFolder) {
      const folderFinded = allFolders.find((item) => item.ID == folder);
      newArr = [folderFinded];
      if (!folderFinded) {
        return [];
      }
      return getFolderPath(allFolders, newArr, folderFinded?.PASTA ?? null);
    }

    if (arr.length > 0) {
      if (parentFolder) {
        const finded = allFolders.find((item) => item.ID == parentFolder);
        newArr = [finded, ...arr];
        return getFolderPath(allFolders, newArr, finded?.PASTA ?? null);
      }

      if (!parentFolder) {
        return arr;
      }
    }
  }

  function handleRemoveItem(type, id) {
    if (type == 'folder') {
      setFolders((folders) => folders.filter(({ ID }) => ID != id));
    } else {
      setFiles((files) => files.filter(({ ID }) => ID != id));
    }
  }

  function handleUpdateItem(type, item) {
    if (type == 'folder') {
      setFolders((folders) => folders.filter(({ ID }) => ID != item.ID));
    } else {
      setFiles((files) => files.filter(({ ID }) => ID != item.ID));
    }
  }

  function handleChangeSupplier({ value }) {
    history.push(`/arquivos/${value}`);
  }

  function handleOpenFolder(folder) {
    history.push(`/arquivos/${supplier}/${folder}`);
  }

  return (
    <div className={classes.root}>
      <ToastContainer />
      <Container component="main" className={classes.container}>
        <Box
          className={clsx(`${classes.orangeSet} `, {
            fit: isBibiUser(userState?.tipo),
          })}
          textAlign="center"
        >
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              ARQUIVOS
            </Typography>
          </Box>
        </Box>
        <Box className={`header-div ${classes.whiteSet}`}>
          <Box className={`${classes.mainCards}`}>
            <Grid
              container
              spacing={4}
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={'auto'} md={6} lg={6}>
                <InputSearch
                  id="brand-select"
                  classNamePrefix="select"
                  options={suppliers.map((supplier) => ({
                    value: supplier.ID,
                    label: supplier.NOME,
                  }))}
                  placeholder="Selecione um fornecedor"
                  onChange={(e) => handleChangeSupplier(e)}
                  noOptionsMessage={() => 'Nenhum fornecedor encontrado'}
                  value={supplierSelected}
                />
              </Grid>
            </Grid>
          </Box>
          <div style={{ width: '100%' }}>
            {supplier && (
              <Folder
                folders={folders}
                openFolder={handleOpenFolder}
                addFolder={handleAddFolder}
                addFile={handleAddFile}
                removeItem={handleRemoveItem}
                updateItem={handleUpdateItem}
                folder={folder}
                files={files}
                supplier={supplier}
                path={path}
                allFolders={allFolders}
              />
            )}
          </div>
        </Box>
      </Container>
      <DialogFornecedores
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </div>
  );
}

function Folder({
  folders,
  openFolder,
  folder,
  supplier,
  addFolder,
  files,
  path = [],
  removeItem,
  addFile,
  allFolders,
  updateItem,
}) {
  const history = useHistory();
  const isEmpty = folders.length == 0 && files.length == 0;
  const { setLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [item, setItem] = useState(null);

  async function createFolder() {
    const lastFolder = await FolderService.create(folder, supplier);
    toast(`Pasta criada`, {
      position: 'bottom-right',
      hideProgressBar: true,
    });
    addFolder({
      ID: lastFolder.id,
      FORNECEDOR: supplier,
      PASTA: folder,
      NOME: 'Nova Pasta',
    });
  }

  function handleClick(supplier, folder) {
    if (folder) {
      history.push(`/arquivos/${supplier}/${folder}`);
    } else {
      history.push(`/arquivos/${supplier}`);
    }
  }

  async function handleChangeFile(event) {
    const file = event.target.files[0];
    const { name, type } = file;
    setLoading(true);
    try {
      const { url } = await upload(file);
      const lastFile = await FolderService.createFile(
        name,
        url,
        folder,
        type,
        supplier
      );
      addFile({
        CAMINHO: url,
        ID: lastFile.id,
        NOME: name,
      });

      toast(`Arquivo adicicionado`, {
        position: 'bottom-right',
        hideProgressBar: true,
      });
      event.target.value = '';
      setLoading(false);
    } catch (e) {
      event.target.value = '';
      raiseAlert('Ocorreu um erro ao adicionar arquivo');
      setLoading(false);
    }
  }

  return (
    <section>
      <FolderHeader>
        <h3>
          <span onClick={() => handleClick(supplier)}>Home / </span>
          {path?.map((item, index) => (
            <span
              key={item?.ID}
              onClick={() => handleClick(supplier, item?.ID)}
              style={{ color: index == path.length - 1 ? '#FE5D00' : 'black' }}
            >
              {item?.NOME} /{' '}
            </span>
          ))}
        </h3>
        <Button onClick={createFolder}>Adicionar pasta</Button>
        {supplier && folder && (
          <InputFile>
            <input type="file" onChange={handleChangeFile} />
            Adicionar arquivo
          </InputFile>
        )}
      </FolderHeader>

      {isEmpty && !folder && (
        <EmptyText>
          Ainda não foram adicionados arquivos compartilhados com você.
        </EmptyText>
      )}
      {isEmpty && folder && <EmptyText>Está pasta está vazia</EmptyText>}

      <FilesContainer>
        {folders.map((folder) => (
          <FolderFile
            key={folder.ID}
            item={folder}
            type="folder"
            handleClick={() => openFolder(folder.ID)}
            removeItem={removeItem}
            onOpenModal={(item) => {
              setItem(item);
              setType('folder');
              setOpen(true);
            }}
          />
        ))}
        {files.map((file) => (
          <FolderFile
            key={file.ID}
            item={file}
            type="file"
            removeItem={removeItem}
            onOpenModal={(item) => {
              setItem(item);
              setType('file');
              setOpen(true);
            }}
          />
        ))}
      </FilesContainer>
      <ModalMove
        open={open}
        onClose={() => {
          setOpen(false);
          setItem(null);
          setType('');
        }}
        type={type}
        item={item}
        folders={allFolders}
        updateItem={updateItem}
      />
    </section>
  );
}

const iconParams = {
  htmlColor: '#ED6608',
  style: {
    height: 70,
    width: 70,
  },
};
function FolderFile({ item, type, handleClick, removeItem, onOpenModal }) {
  const { ID, NOME } = item;
  const [value, setValue] = useState(NOME);
  const debouncedValue = useDebounce(value, 800);

  async function updateName() {
    if (debouncedValue == NOME) return;
    try {
      if (type == 'folder') {
        await FolderService.edit(ID, debouncedValue);
        toast(`Pasta renomeada`, {
          position: 'bottom-right',
          hideProgressBar: true,
        });
      } else {
        await FolderService.editFile(ID, debouncedValue);
        toast(`Arquivo renomeado`, {
          position: 'bottom-right',
          hideProgressBar: true,
        });
      }
    } catch (e) {}
  }

  useEffect(() => {
    updateName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  function seeFile(event) {
    event.stopPropagation();
    window.open(`https://cxd.bibi.com.br:3636${item.CAMINHO}`, '_blank');
  }

  async function destroy(event) {
    event.stopPropagation();
    try {
      if (type == 'folder') {
        await FolderService.delete(item.ID);
        toast(`Pasta deletada`, {
          position: 'bottom-right',
          hideProgressBar: true,
        });
      } else {
        await FolderService.deleteFile(item.ID);
        toast(`Arquivo deletado`, {
          position: 'bottom-right',
          hideProgressBar: true,
        });
      }
      removeItem(type, item.ID);
    } catch (e) {
      raiseAlert(e?.response?.data?.message ?? 'Ocorreu algum erro ao excluir');
    }
  }

  async function openModalMove(event) {
    event.stopPropagation();
    onOpenModal(item);
  }

  return (
    <FolderFileContainer>
      <div className="square" onClick={handleClick}>
        {type == 'folder' ? (
          <FolderRounded {...iconParams} />
        ) : (
          <Assignment {...iconParams} />
        )}

        <Actions>
          {type == 'file' && (
            <ActionButton onClick={(event) => seeFile(event)}>
              <RemoveRedEye
                htmlColor="#003407"
                style={{ height: 20, width: 20 }}
              />
            </ActionButton>
          )}
          <ActionButton onClick={(event) => destroy(event)}>
            <DeleteForever
              htmlColor="#d50000"
              style={{ height: 20, width: 20 }}
            />
          </ActionButton>
          <ActionButton onClick={openModalMove}>
            <Redo htmlColor="#092c94" style={{ height: 20, width: 20 }} />
          </ActionButton>
        </Actions>
      </div>
      <p
        contentEditable
        onInput={({ target }) => setValue(target.innerText)}
        suppressContentEditableWarning={true}
      >
        {NOME}
      </p>
    </FolderFileContainer>
  );
}

function ModalMove({ open, onClose, type, item, folders, updateItem }) {
  const [foldersFiltereds, setFolderFiltereds] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [path, setPath] = useState([]);
  const { setLoading } = useLoading();

  function getFolderPath(allFolders, arr = [], parentFolder = null) {
    if (!selectedFolder) return;

    let newArr = [];

    if (arr.length == 0 && !parentFolder) {
      const folderFinded = allFolders.find(
        (item) => item.ID == selectedFolder.ID
      );
      newArr = [folderFinded];
      return getFolderPath(allFolders, newArr, folderFinded.PASTA);
    }

    if (arr.length > 0) {
      if (parentFolder) {
        const finded = allFolders.find((item) => item.ID == parentFolder);
        newArr = [finded, ...arr];
        return getFolderPath(allFolders, newArr, finded.PASTA);
      }

      if (!parentFolder) {
        return arr;
      }
    }
  }

  useEffect(() => {
    setSelectedFolder('');
    setPath([]);
  }, [item]);

  useEffect(() => {
    if (selectedFolder) {
      setPath(getFolderPath(folders));
      setFolderFiltereds(
        folders.filter(({ PASTA }) => PASTA == selectedFolder.ID)
      );
    } else {
      setFolderFiltereds(folders.filter(({ PASTA }) => PASTA == null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder, folders]);

  async function onMove() {
    setLoading(true);
    try {
      if (type == 'folder') {
        await FolderService.move(item.ID, selectedFolder.ID ?? null);
      } else {
        await FolderService.moveFile(item.ID, selectedFolder.ID);
      }
      updateItem(type, item);
      onClose();
    } catch (e) {
      raiseAlert(`Erro ao mover ${type == 'folder' ? 'pasta' : 'arquivo'}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <BaseModal
      open={open}
      handleClose={() => onClose(false)}
      cardStyle={{ minHeight: '80%' }}
    >
      <h3>
        Mover {type == 'folder' ? 'pasta' : 'arquivo'} -{' '}
        <span style={{ color: '#ED6608' }}>{item?.NOME}</span>
      </h3>

      <h4>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedFolder(null);
          }}
        >
          Home /{' '}
        </span>
        {path.map((item, index) => (
          <span
            key={item.ID}
            onClick={() => {
              setSelectedFolder(folders.find(({ ID }) => ID == item.ID));
            }}
            style={{
              color: index == path.length - 1 ? '#FE5D00' : 'black',
              cursor: 'pointer',
            }}
          >
            {item.NOME} /{' '}
          </span>
        ))}
      </h4>

      <ModalFoldersContainer>
        {foldersFiltereds
          .filter((folder) => folder?.ID != item?.ID)
          .map((i) => (
            <FolderMoveContainer
              key={i.ID}
              onClick={() => setSelectedFolder(i)}
            >
              <div className="square">
                <FolderRounded
                  {...iconParams}
                  style={{ height: 50, width: 50 }}
                />
              </div>
              <p>{i.NOME}</p>
            </FolderMoveContainer>
          ))}
      </ModalFoldersContainer>
      {foldersFiltereds.length == 0 && (
        <EmptyText>Está pasta está vazia</EmptyText>
      )}

      <ModalActions>
        <MoveButton onClick={onClose}>Cancelar</MoveButton>

        {((type == 'file' && selectedFolder) || type == 'folder') && (
          <MoveButton
            onClick={onMove}
            disabled={
              foldersFiltereds.find((i) => i?.ID == item?.ID) ||
              item?.PASTA == selectedFolder?.ID
            }
          >
            Mover pra cá
          </MoveButton>
        )}
      </ModalActions>
    </BaseModal>
  );
}
