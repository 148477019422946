import styled from "styled-components";

export const Container = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; 
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 27%;
  border-radius: 10px;
  /* @media(max-width: 1200px) {
    width:30%;
  } */
  @media(max-width: 1100px) {
    width:50%;
  }
  @media(max-width: 1000px) {
    width: 75%;
  } 
  h2 {
    text-align: center;
    font-family: Nunito;
    color: #FE5D00;
    padding: 0px;
    margin: 0px;
  }
  p{
    font-size: 16px;
    font-family: Nunito;
    line-height: 1.5em;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 16px;
    font-family: Nunito;
    margin-bottom: 5px;
    @media(max-width: 1000px) {
      font-size: 14px;
    } 
  }
  input {
    font-family: Nunito;
    height: 30px;
    margin-bottom: 15px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding-left: 8px;
  }
  button {
    font-family: Nunito;
    font-size: 22px;
    height: 50px;
    color: #ffffff;
    background-color: #FE5D00;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover{
        cursor: pointer;
    }
  }
`;
