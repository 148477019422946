import { Button } from "@material-ui/core";
import styled from "styled-components";

export const FakeButton = styled(Button)`
    height: 2.5em;
    padding: 6px 10px;
    margin:  ${props => props.margin ? props.margin : '10px 15px 10px 0'};
    font-weight: 500;
    color: #FE5D00;
    border: 2px solid #FE5D00;
    text-transform: none;
    pointer-events: none;

    @media(max-width: 425px) {
        margin: 10px auto;
    }

    &:hover {
        background-color: transparent;
    }
`