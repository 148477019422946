import React, { createRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoModal from '../InfoModal';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: 'auto',
    minWidth: 'fit-content',
    padding: '26px 22px',
    '&:hover': {
      cursor: "pointer",
      boxShadow: '0px 0px 5px #FE5D00'
    }
  },
  image: {
    height: 150,
    width: 150,
    borderRadius: 5,
    marginBottom: 8,
    objectFit: 'cover'
  },
  title: {
    color: '#000000',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start',
  },
  media: {
    height: 140,
    display: 'flex'
  },
  expand: {
    margin: '0 0 0 auto'
  },
  category: {
    marginBottom: 0,
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  categoryTag: {
    color: theme.palette.secondary.main,
    padding: theme.spacing(2 / 3, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600
  },
  collapseText: {
    color: '#6C6C6C',
    fontSize: 14,
    fontWeight: 500,
    //lineHeight: '1.8em',
    textAlign: 'start'
  },
  textLegend: { color: "#000000", textDecoration: 'none' },
  cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
  crossed: {
    textDecoration: 'line-through'
  },
  infoIcon: {
    color: "#FE5D00"
  },
  partner: {
    color: "#B7B7B7"
  },
  opened: {
    fontSize: '1.5rem',
    marginTop: '10px'
  },
  flag: {
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  bar: {
    height: '3px',
    backgroundColor: '#0E9CFF',
    marginTop: '5px'
  },
  bar2: {
    height: '3px',
    width: '30%',
    backgroundColor: '#06C8A9',
    marginTop: '5px'
  },
  boxCenter: {
    padding: '2rem 3rem',
  },
  noHasData: {
    fontSize: '16px',
    color: '#B7B7B7',
    textAlign: 'center'
  }
}));

export default function CardOrderValue({ value }) {

  const classes = useStyles();
  const cardRef = createRef();
  const [open, setOpen] = useState(false);

  const hasData = value?.VLR_ORDENS_ABERTAS > 0 || value?.VLR_ORDENS_ARECEBER > 0;

  const totalValue = value?.VLR_ORDENS_ABERTAS + value?.VLR_ORDENS_ARECEBER;
  const widthOpened = 100 * (value?.VLR_ORDENS_ABERTAS ? value?.VLR_ORDENS_ABERTAS : 0) / totalValue;
  const widthAReceber = 100 * (value?.VLR_ORDENS_ARECEBER ? value?.VLR_ORDENS_ARECEBER : 0) / totalValue;

  return (
    <>
      <Card ref={cardRef} className={classes.root}>
        <CardContent className={classes.cardPadding} padding={0}>
          <Box textAlign="left" display={"flex"} alignItems={"center"}>
            <Box mr={1}>
              <Typography className={classes.yourPosition} component="h1" variant="body2">
                Valor das ordens
              </Typography>
            </Box>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize={'small'} onClick={() => setOpen(true)} />
          </Box>
          {hasData && (
            <><Box textAlign="left" display={"flex"}  >
              <Box mr={1} style={{ width: '100%' }}>
                <Typography className={classes.opened} component="h1" variant="body2">
                  {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.VLR_ORDENS_ABERTAS)} <span className={classes.flag}> Em aberto</span>
                </Typography>
                <div className={classes.bar} style={{ width: `${widthOpened}%` }}></div>
              </Box>
            </Box>
              <Box textAlign="left" display={"flex"} alignItems={"center"}>
                <Box mr={1} style={{ width: '100%' }}>
                  <Typography className={classes.opened} component="h1" variant="body2">
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.VLR_ORDENS_ARECEBER)} <span className={classes.flag}>  Faturados a receber</span>
                    <div className={classes.bar2} style={{ width: `${widthAReceber}%` }}></div>
                  </Typography>
                </Box>
              </Box></>)}

          {!hasData && (
            <Box textAlign="left" display={"flex"} alignItems={"center"} justifyContent="center" className={classes.boxCenter}>
              <Typography component="p" variant="subtitle2" className={classes.noHasData}>
                Em breve você verá os valores a receber das ordens
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <InfoModal open={open} handleClose={() => setOpen(false)} type="orderValue" />
    </>
  );
}