import React from "react";
import {
  ProgramaDiv,
  PartnersDiv,
  DivTeste,
  BodyProgram,
  PartnersImg,
  ImagePartners,
  Retangulo,
  ImgMenina,
  ProgramTitle,
} from "./styles";
import toxico from "../../../../assets/ICONE_N-TOXICO-verde-01.svg";
import couro from "../../../../assets/ICONE-COURO-MACIO.svg";
import fisioflex from "../../../../assets/LOGO PALMILHA FISIOFLEX_ok.svg";
import drop from "../../../../assets/LogoDrop.svg";
import puc from "../../../../assets/LogoPUC.svg";
import menina from "../../../../assets/menina.svg";

export default function Programa() {
  return (
    <ProgramaDiv>
      <ProgramTitle>
        {/* <TitleToSmooth id="programa"></TitleToSmooth> */}
        <h1>PROGRAMA</h1>
      </ProgramTitle>
      <PartnersDiv>
        <DivTeste>
          <BodyProgram>
            <p>
              O Ninho de Inovação – Fornecedores é um programa de inovação
              aberta da Bibi, que tem como objetivo aproximar e conectar
              empresas, startups, empreendedores e fornecedores atuais com o
              ambiente Bibi, visando com isso propiciar inovações que gerem
              resultados econômico-financeiros e vantagens competitivas mútuas.
            </p>
            <PartnersImg>
              <ImagePartners src={toxico} alt="toxico" />
              <ImagePartners src={fisioflex} alt="fisioflex" />
              <ImagePartners src={drop} alt="drop" />
              <ImagePartners src={couro} alt="couro" />
              <ImagePartners src={puc} alt="puc" />
            </PartnersImg>
            <p>
              {" "}
              É uma excelente oportunidade de iniciar ou estreitar
              relacionamentos com uma das maiores fabricantes de calçados
              infantis do Brasil.
            </p>
          </BodyProgram>
        </DivTeste>
        <Retangulo>
          <ImgMenina src={menina} alt="menina" />
        </Retangulo>
      </PartnersDiv>
    </ProgramaDiv>
  );
}
