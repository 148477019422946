import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, TablePagination } from '@material-ui/core';
import clsx from 'clsx';
import {
  AccordionContent,
  ButtonOrange,
  ButtonOrangeGrid,
  ButtonWhite,
  ButtonWhiteGrid,
  FilterButtonsContainer,
  FilterContainer,
  FilterFieldsContainer,
  InfoDiv,
  Input,
} from './styles';
import OrderItensModal from '../OrderItensModal';
import { confirmOrder, getOrderItems } from '../../services/orders';
import { SelectFormChallenge } from '../FormChallenge/style';

const useStyles = makeStyles(() => ({
  title: {
    color: '#000000',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start',
  },
  subtitle: {
    color: '#000000',
    //margin: '2em auto auto 25px',
    '@media(max-width: 425px)': {
      //margin: '35px auto',
      textAlign: 'justify',
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    '@media(max-width: 425px)': {
      display: 'grid',
    },
  },
  descriptionDiv: {
    marginBottom: '0px',
    marginLeft: '25px',
    marginTop: 'auto',
    maxWidth: '70%',
    '@media(max-width: 425px)': {
      maxWidth: '100%',
    },
  },
  titleStatusDiv: {
    display: 'flex',
    '@media(max-width: 425px)': {
      display: 'grid',
    },
  },
  gridContainer: {
    display: 'flex',
  },
  conntentAcordion: {
    width: '100%',
    boxShadow: '0px 0px 0px #6C6C6C',
    border: '1px solid #00000029',
    borderRadius: '5px',
  },
  accordion: {
    padding: '6px',
    margin: '0 !important',
    border: '1px solid #00000029',
    borderLeft: '0px',
    borderRight: '0px',
    borderRadius: '0 !important',
  },
  accordionTop: {
    padding: '6px',
    margin: '0 !important',
    borderRadius: '5px 5px 0 0 !important',
  },
  accordionBottom: {
    padding: '6px',
    margin: '0 !important',
    borderRadius: '0 0 5px 5px !important',
  },
  fileItem: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '5px',
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000000',
  },
  chip: {
    //margin: 2,
    height: '2em!important',
    margin: '8px!important',
    padding: '4px 4px!important',
    '&:hover': {
      backgroundColor: '#FE5D00',
    },
  },
  media: {
    height: 140,
    maxHeight: 140,
    minHeight: 140,
    width: '100%',
    maxWidth: 160,
    minWidth: 160,
    margin: '0 auto',
  },
  right: {
    margin: 'auto auto auto 0',
  },
  liFile: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '0 15px',
  },
  iconFile: {
    fontSize: 70,
    color: '#C6C6C6',
  },
  align: {
    alignItems: 'center',
  },
  iconColor: { color: '#FFFFFF!important' },
  textAdapt: {
    margin: '8px 0 8px',
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 500,
    height: 'fit-content',
    maxHeight: '3em',
    '&:hover': {
      backgroundColor: '#FE5D00',
    },
  },
  textButton: {
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 500,
  },
  partner: {
    color: '#B7B7B7',
    fontSize: 18,
    textAlign: 'center',
  },
  circle: {
    height: '5px',
    width: '5px',
    background: 'black',
    borderRadius: '50%',
  },
}));

export default function CardOrderList({ orders, userId, codPessoaSft }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('Todas');
  const [items, setItems] = useState([]);
  const [orderSelected, setOrderSelected] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [searchState, setSearchState] = useState('Todas');

  const [page, setPage] = React.useState(0);

  const states = ['Todas', ...new Set(orders.map((order) => order.UF))];

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const filteredOrders = useCallback(
    () =>
      orders
        .filter(({ STATUS }) => {
          if (search === 'Todas') {
            return true;
          }
          return STATUS.toLowerCase().includes(search.toLowerCase());
        })
        .filter(({ NUMERO_ORDEM_COMPRA }) => {
          if (searchString) {
            return `${NUMERO_ORDEM_COMPRA}`.includes(searchString);
          }
          return true;
        })
        .filter(({ UF }) => {
          if (searchState === 'Todas') return true;
          return UF === searchState;
        }),
    [orders, search, searchString, searchState]
  )();

  const openModal = async (id) => {
    setOrderSelected(id);
    const items = await getOrderItems(id);
    setItems(items);
    setOpen(true);
  };

  const confirm = async (id) => {
    await confirmOrder(id, +codPessoaSft, userId);
    window.location.reload();
  };

  const circleColor = (status) => {
    const colors = {
      Recebida: '#06C8A9',
      Aberta: '#FFCC2F',
      Atrasada: '#FE5D00',
      Confirmada: '#22A7F0',
    };
    return colors[status];
  };

  const labelText = (status, data = '') => {
    const texts = {
      Recebida: `Entregue`,
      Aberta: `Previsão ${data}`,
      Atrasada: 'Confirmar a previsão de entrega',
      Confirmada: 'Confirmada',
    };
    return texts[status];
  };

  function handleSearch({ target }) {
    if (target.value !== '') setPage(0);
    setSearchString(target.value);
  }
  function handleSearchState({ target }) {
    if (target.value !== 'UF') setPage(0);
    setSearchState(target.value);
  }

  return (
    <>
      <Grid container>
        <Box className={classes.header}>
          <Box width={'100%'} display="grid">
            <Box className={classes.titleStatusDiv}>
              <Typography
                className={classes.title}
                component="h2"
                variant="subtitle1"
              >
                Listagem de ordens
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.header}>
          <FilterContainer>
            <FilterButtonsContainer>
              <ButtonWhiteGrid
                style={
                  search === 'Todas'
                    ? { background: '#FE5D00', color: 'white' }
                    : {}
                }
                onClick={() => {
                  setSearch('Todas');
                  setPage(0);
                }}
              >
                Todas
              </ButtonWhiteGrid>
              <ButtonWhiteGrid
                style={
                  search === 'Aberta'
                    ? { background: '#FE5D00', color: 'white' }
                    : {}
                }
                onClick={() => {
                  setSearch('Aberta');
                  setPage(0);
                }}
              >
                Abertas
              </ButtonWhiteGrid>
              <ButtonWhiteGrid
                style={
                  search === 'Atrasada'
                    ? { background: '#FE5D00', color: 'white' }
                    : {}
                }
                onClick={() => {
                  setSearch('Atrasada');
                  setPage(0);
                }}
              >
                Atrasadas
              </ButtonWhiteGrid>
              <ButtonWhiteGrid
                style={
                  search === 'Confirmada'
                    ? { background: '#FE5D00', color: 'white' }
                    : {}
                }
                onClick={() => {
                  setSearch('Confirmada');
                  setPage(0);
                }}
              >
                Confirmadas
              </ButtonWhiteGrid>
              <ButtonWhiteGrid
                style={
                  search === 'Recebida'
                    ? { background: '#FE5D00', color: 'white' }
                    : {}
                }
                onClick={() => {
                  setSearch('Recebida');
                  setPage(0);
                }}
              >
                Entregues
              </ButtonWhiteGrid>
            </FilterButtonsContainer>
            <FilterFieldsContainer>
              <SelectFormChallenge onChange={handleSearchState}>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </SelectFormChallenge>
              <Input placeholder="Número" onChange={handleSearch} />
            </FilterFieldsContainer>
          </FilterContainer>
        </Box>

        <Box className={classes.conntentAcordion} mt={3}>
          {filteredOrders.slice(page * 5, page * 5 + 5).map((order, index) => (
            <Accordion
              key={`accordion_orders_${index}`}
              square={true}
              className={clsx(classes.accordion, {
                [classes.accordionTop]: index === 0,
                [classes.accordionBottom]: index === orders.length - 1,
              })}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading} component="h2">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        height: '20px',
                        width: '20px',
                        background: circleColor(order.STATUS),
                        borderRadius: '50%',
                        display: 'block',
                      }}
                    ></div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '130px' }}>
                        {order.NUMERO_ORDEM_COMPRA}
                      </div>{' '}
                      <ButtonWhiteGrid
                        style={
                          order?.STATUS === 'Atrasada'
                            ? { fontSize: '11px', padding: '10px 5px' }
                            : {}
                        }
                      >
                        {labelText(
                          order.STATUS,
                          new Date(order.DATA_PREV_ENTREGA).toLocaleDateString()
                        )}
                      </ButtonWhiteGrid>
                      <ButtonOrangeGrid style={{ marginLeft: '1rem' }}>
                        {order.UF}
                      </ButtonOrangeGrid>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionContent>
                  <div className="infos">
                    <InfoDiv>
                      <p className="label">
                        Criação:
                        <span>
                          {new Date(order.DATA_EMISSAO).toLocaleDateString()}
                        </span>
                      </p>
                      <p className="label">
                        Valor Total:
                        <span>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(order.VLR_TOTAL)}
                        </span>
                      </p>
                      <p className="label">
                        Quantidade de itens:
                        <span>{order.QTD_ITENS}</span>
                      </p>
                    </InfoDiv>
                    <InfoDiv>
                      <p className="label">
                        Status:
                        <span>{order.STATUS}</span>
                      </p>
                      <p className="label">
                        Previsão de entrega Original:
                        <span>
                          {new Date(
                            order.DATA_PREV_ENTREGA
                          ).toLocaleDateString()}
                        </span>
                      </p>
                    </InfoDiv>
                  </div>
                  <div className="buttons">
                    <ButtonWhite
                      onClick={() => openModal(order.NUMERO_ORDEM_COMPRA)}
                    >
                      Consultar itens
                    </ButtonWhite>
                    <ButtonOrange
                      onClick={() => confirm(order.NUMERO_ORDEM_COMPRA)}
                    >
                      Confirmar previsão
                    </ButtonOrange>
                  </div>
                </AccordionContent>
              </AccordionDetails>
            </Accordion>
          ))}
          <TablePagination
            component="div"
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            count={filteredOrders.length}
            rowsPerPage={5}
            page={page}
            onChangePage={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Box>
      </Grid>
      <OrderItensModal
        open={open}
        handleClose={() => setOpen(false)}
        items={items}
        order={orderSelected}
      />
    </>
  );
}
