import api from "./api"

export const getOrders = (supplier) => {
  return api.get(`ocs?ID=${supplier}`)
    .then(({ data }) => data)
    .catch(() => [])
}

export const getValueTransitioned = (supplier) => {
  return api.get(`ocs/valorTransacionado?ID=${supplier}`)
    .then(({ data }) => data)
    .catch(() => [])
}

export const getOrderItems = async (id) => {
  const orders = await api.get(`ocs/itens?ID=${id}`)
  return orders.data;
}

export const confirmOrder = async (ORDEM_COMPRA, COD_PESSOA_SFT, USUARIO) => {
  const order = await api.post(`ocs/confirmar`, {
    ORDEM_COMPRA, COD_PESSOA_SFT, USUARIO
  })
  return order.data;
}

export const getOrderPizzaGraph = async (supplier) => {
  const orders = await api.get(`ocs/status?ID=${supplier}`)
  return orders.data;
}