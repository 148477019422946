import api from './api';

export const createMensagem = async (id,data) => {
    return await api.post(`desafios/historicos?ID=${id}`,data).then(async response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
    
} 
export const deleteMensagem = async (messageid) => {
   
    return await api.delete(`desafios/historicos?ID=${messageid}`).then(async response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
    
} 
