import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import { raiseSuccess, raiseAlert } from '../Alert';
import { Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { deleteMaterial, deleteAttachment, downloadAttachment, downloadMaterial } from '../../services/file';
import { UserContext } from '../../providers/UserProvider';
import { isBibiUser, isSupplierUser } from '../../utils/roles';

const useStyles = makeStyles((theme) => ({
    sectionFile: {
        width: '100%',
        marginBottom: '15px'
    },
    ulFile: {
        width: '100%',
        display: 'flex',
        paddingInlineStart: 'inherit'
    },
    liFile: {
        cursor: 'pointer',
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '0 15px'
    },
    align: {
        textAlign: 'start',
        marginLeft: '1em',
        '@media(min-width:500px) and (max-width: 960px)': {
            textAlign: 'center',
        }
    },
    iconFile: {
        fontSize: 70,
        color: '#C6C6C6'
    },
    iconChangeEdit: {
        cursor: 'pointer',
        fontSize: '22px',
        position: 'relative',
        float: 'right',
        right: '0',
        marginTop: '5px',
        marginRight: '5px',
        color: '#FFFFFF',
        backgroundColor: '#FE5D00',
        borderRadius: '10px'
    },
    fitObject: {
        minWidth: 'fit-content',
        maxWidth: 'fit-content',
        width: 'fit-content',

    }
}));

export default function ListFiles({
    files = [],
    edit = false,
    //type
    isAttachment = false,
    isMaterials = false,
    //permission
    canEdit = false,
    setUpdateChallenge }) {
    const classes = useStyles();
    const { userState } = useContext(UserContext);

    const handleDownload = async (id, nome) => {
        if (isMaterials) {
            await downloadMaterial(id, nome).then(resp => {
                if (resp.status === 200) {
                    //raiseSuccess(resp.message)
                } else {
                    raiseAlert(resp.message)
                }
            }).catch(err => {
                raiseAlert("Erro ao baixar arquivo." || err.message)
            })
        } else if (isAttachment) {
            await downloadAttachment(id, nome).then(resp => {
                if (resp.status === 200) {
                    //raiseSuccess(resp.message)
                } else {
                    raiseAlert(resp.message)
                }
            }).catch(err => {
                raiseAlert("Erro ao baixar arquivo." || err.message)
            })
        }
    }
    const handleDelete = async (ID) => {
        if (isBibiUser(userState?.tipo) && isMaterials) {
            await deleteMaterial(ID).then(resp => {
                if (resp.status === 200) {
                    raiseSuccess(resp.message)
                    setUpdateChallenge(true)
                } else {
                    raiseAlert(resp.message)
                }
            }).catch(err => {
                raiseAlert(err.message)
            })
        } else if (isSupplierUser(userState?.tipo) && isAttachment) {
            await deleteAttachment(ID).then(resp => {
                if (resp.status === 200) {
                    raiseSuccess(resp.message)
                    setUpdateChallenge(true)
                } else {
                    raiseAlert(resp.message)
                }
            }).catch(err => {
                raiseAlert(err.message)
            })
        }


    }

    /* const download = async (path,NOME) => {
        if(path && path !== '')
        
        await axios({
          url: path, //your url
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
           const type = response.headers['content-type']
           const url = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: 'UTF-8' }));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', NOME); //or any other extension
           document.body.appendChild(link);
           link.click();
           return { status:200, message:'Download deve finalizar em segundos...'}
        }).catch(err=>{
            return { status:400, message:  err.data.message || "Erro ao baixar arquivo."}
        });
      
      } */
    return (
        <section className={classes.sectionFile}>
            <Box display={'flex'}>
                <Grid container spacing={2} className={classes.ulFile}>
                    {files?.map((file, index) => (
                        <Grid item xs={6} sm={5} md={2} lg={2} className={classes.liFile} key={index} >
                            <Box textAlign="start">
                                <Box className={classes.fitObject}>
                                    {canEdit ? (<CancelIcon className={classes.iconChangeEdit} onClick={() => { handleDelete(file?.ID) }} />) : ('')}
                                    <InsertDriveFileIcon className={classes.iconFile} onClick={() => { handleDownload(file?.ID, file?.NOME) }} />
                                </Box>

                                <Typography /* className={classes.align} */ component="p" variant="subtitle2"
                                    onClick={() => { handleDownload(file?.ID, file?.NOME) }}
                                >
                                    {file?.NOME}
                                </Typography>
                            </Box>
                        </Grid>

                    ))}
                </Grid>

            </Box>
        </section>
    )


}