import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    boxStyle: {
        display: 'flex',
        width: '180px',
        boxShadow: '0px 0px 1px #6C6C6C',
        borderRadius: '5px',
        backgroundColor: '#fafafa',
        height: '2.5em',
        margin: '0 auto',
        '@media(min-width: 938px)' : {
            marginLeft: '25px'
        },
        '@media(min-width: 1200px)' : {
            margin: '0 0 auto auto'
        }
    },
    textStyle: {
        textAlign: 'center',
        margin: 'auto',
        color: '#000000',
        fontWeight: 600
    }
}));

export default function FakeButtonStatus({text}) {
    const classes = useStyles();

    return (
        <Box className={classes.boxStyle}>
            <Typography className={classes.textStyle} component="p" variant="subtitle2">
                {text}
            </Typography>
        </Box>
    )
}