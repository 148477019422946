import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ModalPermissions } from './ModalPermission';
import { RemoveRedEye } from '@material-ui/icons';
import { useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  tittleContact: {
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  copyButton: {
    width: '20%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export function TableUsers({ users, supplier, roleType }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState({});

  function handleCopyButton() {
    navigator.clipboard.writeText(
      `${window.location.host}/cadastrar/usuario/${supplier}`
    );
    toast('Link de cadastro copiado!', {
      position: 'bottom-right',
      hideProgressBar: true,
    });
  }

  function handleClickUser({ EMAIL, NOME }) {
    setUser({
      email: EMAIL,
      name: NOME,
    });
    setOpenDialog(true);
  }

  return (
    <>
      <ModalPermissions
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        user={user}
        roleType={roleType}
      />
      <Box display="flex" style={{ marginTop: '25px' }}>
        <Box className={`header-div mob ${classes.filterDiv}`}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              className={classes.tittleContact}
              component="h2"
              variant="subtitle1"
            >
              Usuários
            </Typography>
            <button className={classes.copyButton} onClick={handleCopyButton}>
              Copiar link de cadastro
            </button>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}> Nome</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}> Email</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => {
                    return (
                      <TableRow key={user.EMAIL}>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {user.NOME}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {user.EMAIL}
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                          <button
                            style={{
                              border: 'none',
                              background: 'transparent',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleClickUser(user)}
                          >
                            <RemoveRedEye />
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
