import api from './api';

export const getPropostaDetails = async (id) => {

  return await api.get(`desafios/propostas/detalhes?ID=${id}`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const propostaDesafiosWithParams = async (id, params, data) => {

  const final_params = `FORNECEDOR=${id}` + (params ? `&${params}` : '')
  return await api.post(`desafios/propostas?${final_params}`, data).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const subcriberDesafiosWithParams = async (id, params) => {

  const final_params = `FORNECEDOR=${id}` + (params ? `&${params}` : '')
  return await api.post(`desafios/propostas?${final_params}`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const propostaUpdateDesafiosWithParams = async (PROP_ID, data) => {


  return await api.put(`desafios/propostas?ID=${PROP_ID}`, data).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}
export const getSuppliersProposals = async () => {

  return await api.get(`/desafios/propostas/fornecedores`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const getChallengeResponses = async (fornecedor) => {
  return api.get(`desafios/propostas/fornecedores/respostas?ID=${fornecedor}`)
}