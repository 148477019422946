/* eslint-disable eqeqeq */
import { Box, Checkbox, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext, useState } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import clsx from 'clsx';
import { Input, InputSearch, Section, Select } from './styles';
import { useEffect } from 'react';
import {
  getAllFornecedor,
  updateSupplier,
} from '../../../services/fornecedores';
import { isBibiUser } from '../../../utils/roles';
import DialogFornecedores from '../../../components/ModalFornecedores';
import { getGroups } from '../../../services/subgroup';
import { raiseAlert, raiseSuccess } from '../../../components/Alert';
import { useLoading } from '../../../providers/LoadingProvider';
import { Certificates } from '../../../components/Certificate/Certificates';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  title: {
    fontSize: 36,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    padding: '0rem 0rem 5rem 0rem',
  },
  whiteSet: {
    backgroundColor: '#FFFFFF',
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-4rem',
  },
  button: {
    height: '100%',
    padding: '.6rem 1.4rem',
    border: '1px solid #FFFFFF',
    fontWeight: 'bold',
    borderRadius: '10px',
    background: '#FFFFFF',
    cursor: 'pointer',
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  buttonSave: {
    width: '100%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export default function Suppliers() {
  const classes = useStyles();
  const { userState } = useContext(UserContext);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  async function getSuppliers() {
    const response = await getAllFornecedor();
    setSuppliers(response.data);
  }
  useEffect(() => {
    getSuppliers();
  }, []);

  function handleUpdateSupplier(data) {
    const updated = suppliers.map((supplier) => {
      if (supplier.ID == data.ID) {
        return {
          ...supplier,
          ...data,
        };
      }
      return supplier;
    });

    setSuppliers(updated);
    setSupplier(suppliers.find(({ ID }) => data.ID == ID));
  }

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box
          className={clsx(`${classes.orangeSet} `, {
            fit: isBibiUser(userState?.tipo),
          })}
          textAlign="center"
        >
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              Fornecedores
            </Typography>
          </Box>
        </Box>
        <Box className={`header-div ${classes.whiteSet}`}>
          <Box className={`${classes.mainCards}`}>
            <Grid
              container
              spacing={4}
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={'auto'} md={6} lg={6}>
                <InputSearch
                  id="brand-select"
                  classNamePrefix="select"
                  options={suppliers.map((supplier) => ({
                    value: supplier.ID,
                    label: supplier.NOME,
                    ...supplier,
                  }))}
                  placeholder="Selecione um fornecedor"
                  onChange={(e) => setSupplier(e)}
                  noOptionsMessage={() => 'Nenhum fornecedor encontrado'}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <button
                  className={classes.button}
                  onClick={() => setOpenDialog(true)}
                >
                  Ver todos
                </button>
              </Grid>
            </Grid>
          </Box>
          <SupplierSelected
            supplier={supplier}
            updateList={handleUpdateSupplier}
          />
        </Box>
      </Container>
      <DialogFornecedores
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </div>
  );
}

function SupplierSelected({ supplier, updateList }) {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [data, setData] = useState({});
  const { setLoading } = useLoading();

  async function fetchData() {
    const responseGroups = await getGroups();
    setGroups(responseGroups.data);
  }

  function handleChangeGroup(e) {
    setData({ ...data, ID_GRUPO: e.target.value, SUB_GRUPO: '' });
    getSubgrupos(e.target.value);
  }

  function getSubgrupos(group) {
    if (!group) return;
    setSubGroups([]);
    setSubGroups(groups.find(({ ID }) => ID == group)?.SUBGRUPOS ?? []);
  }

  async function update() {
    let params = {};

    if (data.PARCEIRO_DESDE) {
      params = {
        PARCEIRO_DESDE: data.PARCEIRO_DESDE,
      };
    }

    try {
      setLoading(true);
      await updateSupplier(data.ID, {
        NOME: data.NOME,
        SUB_GRUPO: data.SUB_GRUPO,
        COD_PESSOA_SFT: data.COD_PESSOA_SFT,
        DIAS_NOTIFICAR_OC: data.DIAS_NOTIFICAR_OC,
        POS_RANKING: data.POS_RANKING,
        DESTAQUE_COMPETITIVIDADE: data.DESTAQUE_COMPETITIVIDADE ? 1 : 0,
        DESTAQUE_INOVACAO: data.DESTAQUE_INOVACAO ? 1 : 0,
        DESTAQUE_SERVICOS: data.DESTAQUE_SERVICOS ? 1 : 0,
        DESTAQUE_SUSTENTABILIDADE: data.DESTAQUE_SUSTENTABILIDADE ? 1 : 0,
        HOMOLOGADO: data.HOMOLOGADO,
        ...params,
      });
      // updateList(data);
      setLoading(false);
      setTimeout(() => raiseSuccess('Dados do fornecedor atualizados'), 600);
    } catch (e) {
      setLoading(false);
      setTimeout(
        () => raiseAlert('Erro ao atualizar os dados do fornecedor'),
        600
      );
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let parceiroDesde = '';
    if (supplier?.PARCEIRO_DESDE) {
      parceiroDesde = supplier.PARCEIRO_DESDE.slice(0, 10);
    }

    getSubgrupos(supplier?.ID_GRUPO);

    setData({
      ID: supplier?.ID,
      NOME: supplier?.NOME ?? '',
      ID_GRUPO: supplier?.ID_GRUPO ?? '',
      SUB_GRUPO: supplier?.SUB_GRUPO ?? '',
      COD_PESSOA_SFT: supplier?.COD_PESSOA_SFT ?? '',
      PARCEIRO_DESDE: parceiroDesde,
      DIAS_NOTIFICAR_OC: supplier?.DIAS_NOTIFICAR_OC ?? '',
      POS_RANKING: supplier?.POS_RANKING ?? '',
      DESTAQUE_COMPETITIVIDADE: supplier?.DESTAQUE_COMPETITIVIDADE == 1,
      DESTAQUE_INOVACAO: supplier?.DESTAQUE_INOVACAO == 1,
      DESTAQUE_SERVICOS: supplier?.DESTAQUE_SERVICOS == 1,
      DESTAQUE_SUSTENTABILIDADE: supplier?.DESTAQUE_SUSTENTABILIDADE == 1,
      HOMOLOGADO: supplier?.HOMOLOGADO,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  if (!supplier?.NOME) return <></>;
  return (
    <>
      <Section style={{ marginTop: '-2rem' }}>
        <h2>Dados básicos</h2>
        <Grid
          container
          spacing={4}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <label>Nome:</label>
            <Input
              placeholder="Nome do fornecedor"
              value={data.NOME}
              onChange={({ target }) =>
                setData({ ...data, NOME: target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label>Grupo:</label>
            <Select onChange={handleChangeGroup} value={data?.ID_GRUPO}>
              <option value="vazio">Selecione</option>
              {groups?.map(({ ID, NOME }) => (
                <option key={ID} value={ID}>
                  {NOME}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <label>Subgrupo:</label>
            <Select
              value={data.SUB_GRUPO}
              onChange={({ target }) =>
                setData({ ...data, SUB_GRUPO: target.value })
              }
            >
              <option value="vazio">Selecione</option>
              {subGroups?.map(({ ID, SUB_GRUPO }) => (
                <option key={ID} value={ID}>
                  {SUB_GRUPO}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <label>ID:</label>
            <Input
              placeholder="ID"
              value={data.COD_PESSOA_SFT}
              onChange={({ target }) =>
                setData({ ...data, COD_PESSOA_SFT: target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label>Parceiro desde: </label>
            <Input
              type="date"
              value={data.PARCEIRO_DESDE}
              onChange={({ target }) =>
                setData({ ...data, PARCEIRO_DESDE: target.value })
              }
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <h2>Ranking</h2>
        <Grid
          container
          spacing={4}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <label>Posição:</label>
            <Input
              type="number"
              value={data.POS_RANKING}
              onChange={({ target }) =>
                setData({ ...data, POS_RANKING: target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}></Grid>

          <Grid item xs={12} sm={12} md={2} lg={3}>
            <Checkbox
              classes={{
                root: classes.checkColor,
                checked: classes.checkColor,
              }}
              value={data?.DESTAQUE_INOVACAO}
              checked={data?.DESTAQUE_INOVACAO}
              onChange={() =>
                setData({
                  ...data,
                  DESTAQUE_INOVACAO: !data.DESTAQUE_INOVACAO,
                })
              }
            />
            <label>Destaque inovação:</label>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={3}>
            <Checkbox
              classes={{
                root: classes.checkColor,
                checked: classes.checkColor,
              }}
              value={data?.DESTAQUE_COMPETITIVIDADE}
              checked={data?.DESTAQUE_COMPETITIVIDADE}
              onChange={() =>
                setData({
                  ...data,
                  DESTAQUE_COMPETITIVIDADE: !data.DESTAQUE_COMPETITIVIDADE,
                })
              }
            />
            <label>Destaque competitividade:</label>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={3}>
            <Checkbox
              classes={{
                root: classes.checkColor,
                checked: classes.checkColor,
              }}
              value={data?.DESTAQUE_SERVICOS}
              checked={data?.DESTAQUE_SERVICOS}
              onChange={() =>
                setData({
                  ...data,
                  DESTAQUE_SERVICOS: !data.DESTAQUE_SERVICOS,
                })
              }
            />
            <label>Destaque serviços:</label>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={3}>
            <Checkbox
              classes={{
                root: classes.checkColor,
                checked: classes.checkColor,
              }}
              value={data?.DESTAQUE_SUSTENTABILIDADE}
              checked={data?.DESTAQUE_SUSTENTABILIDADE}
              onChange={() =>
                setData({
                  ...data,
                  DESTAQUE_SUSTENTABILIDADE: !data.DESTAQUE_SUSTENTABILIDADE,
                })
              }
            />
            <label>
              Destaque sustentabilidade: {data.DESTAQUE_SUSTENTABILIDADE}
            </label>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <h2>Notificações</h2>
        <Grid
          container
          spacing={4}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <label>Notificar em:</label>
            <Select
              value={data.DIAS_NOTIFICAR_OC}
              onChange={({ target }) =>
                setData({ ...data, DIAS_NOTIFICAR_OC: target.value })
              }
            >
              <option value={null}>Selecione</option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={3}>3</option>
              <option value={5}>5</option>
            </Select>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <h2>Fornecedor Homologado</h2>
        <Grid
          container
          spacing={4}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Select
              value={data.HOMOLOGADO}
              onChange={({ target }) =>
                setData({ ...data, HOMOLOGADO: target.value })
              }
            >
              <option value={null}>Selecione</option>
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </Select>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <h2>Certificados</h2>
        <Certificates supplier={supplier.ID} isAdminScreen />
      </Section>

      <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <button
            type="button"
            className={classes.buttonSave}
            onClick={() => update()}
          >
            Salvar
          </button>
        </Grid>
      </Grid>
    </>
  );
}
