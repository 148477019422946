import React from "react";
import { Overlay, Container } from "./styles";
import bibiLogo from "../../assets/logo.svg"
import "animate.css"

export default function Loader() {
  return (
    <Overlay>
      <Container>
        <img src={bibiLogo} alt="Logo da bibi" className="animate__animated animate__pulse animate__infinite" />
      </Container>
    </Overlay>
  )
}