import React from "react";
import { Container, ContainerMaior } from "./styles";

export default function StyledMenu() {
  return (
    <ContainerMaior>
      <Container>
        <div className="wrapper">
          <nav className="nav__wrapper" id="navbar-example">
            <ul className="nav">
              <li role="presentation" className="active">
                <a href="#section1">
                  <span className="nav__counter">01</span>
                  <h3 className="nav__title">
                    <strong>Escolha e Inscrição.</strong>
                  </h3>
                  <p className="nav__body"></p>
                </a>
              </li>

              <li role="presentation">
                <a href="#section2">
                  <span className="nav__counter">02</span>
                  <h3 className="nav__title">
                    <strong>Detalhe do Desafio e Proposta Inicial</strong>
                  </h3>
                  <p className="nav__body"></p>
                </a>
              </li>

              <li role="presentation">
                <a href="#section3">
                  <span className="nav__counter">03</span>
                  <h3 className="nav__title">
                    <strong>Apresentação Detalhada e Conexão</strong>
                  </h3>
                  <p className="nav__body"></p>
                </a>
              </li>

              <li role="presentation">
                <a href="#section4">
                  <span className="nav__counter">04</span>
                  <h3 className="nav__title">
                    <strong>Teste Piloto</strong>
                  </h3>
                  <p className="nav__body"></p>
                </a>
              </li>

              <li role="presentation">
                <a href="#section5">
                  <span className="nav__counter">05</span>
                  <h3 className="nav__title">
                    <strong>Análise de Resultados e Conexão</strong>
                  </h3>
                  <p className="nav__body"></p>
                </a>
              </li>
            </ul>
          </nav>

          <section className="section section1" id="section1">
            <div className="sec-text"><strong>01.</strong> Escolha o desafio e inscreva-se.</div>
          </section>

          <section className="section section2" id="section2">
            <div className="sec-text">
              <strong>02.</strong> Você poderá pesquisar entre os desafios em aberto e verificar
              aquele(s) que acredita ter aderência com sua(s) proposta(s).
            </div>
          </section>

          <section className="section section3" id="section3">
            <div className="sec-text">
              <strong>03.</strong> Você irá detalhar a proposta inicial apresentada, a partir do
              feedback dado pela Bibi na etapa anterior (proposta inicial). A
              Bibi selecionará propostas para ir adiante e realizar testes
              piloto.
            </div>
          </section>

          <section className="section section4" id="section4">
            <div className="sec-text">
              <strong>04.</strong> Dentro dos critérios e prazos acordados, serão executados os
              testes piloto das soluções apresentadas.
            </div>
          </section>

          <section className="section section5" id="section5">
            <div className="sec-text">
              <strong>05.</strong> Serão analisados e avaliados os testes realizados e, caso sejam
              aprovados, a empresa se tornará fornecedora e ou parceira da Bibi,
              definindo-se termos comerciais e dando fim ao desafio.
            </div>
          </section>
        </div>
      </Container>
    </ContainerMaior>
  );
}
