import api from "./api";

const route = '/fornecedores/permissoes';
const routeUser = '/fornecedores/usuarios/permissoes/';

export const ROLE_TYPE = {
  BIBI: 'bibi',
  SUPPLIER: 'supplier'
}

export const PermissionService = {
  async all(roleType) {
    let params = {}
    if (roleType === ROLE_TYPE.BIBI) {
      params = { bibi: true }
    } else {
      params = { fornecedor: true }
    }
    const response = await api.get(route, {
      params
    })
    return response.data
  },

  async getByUser(email) {
    const response = await api.get(`${routeUser}`, {
      params: { email }
    })
    return response.data
  },

  async add(email, id) {
    const response = await api.post(`${routeUser}`, {
      email, permissionId: id
    })
    return response.data
  },

  async remove(id, email) {
    const response = await api.delete(`${routeUser}${id}`, {
      params: { email }
    })
    return response.data
  }
}