import React, { useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { generalTexts } from '../../utils/utils';
import { Box } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// import { Chart as ChartJS } from 'react-chartjs-2';
import { Filler } from "chart.js";
import InfoModal from '../InfoModal';

import ChartJS from "chart.js/auto";

ChartJS.register(Filler);

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: 'auto',
    minWidth: 'fit-content',
    padding: '14px 22px',
    '&:hover': {
      cursor: "pointer",
      boxShadow: '0px 0px 5px #FE5D00'
    }
  },
  image: {
    height: 150,
    width: 150,
    borderRadius: 5,
    marginBottom: 8,
    objectFit: 'cover'
  },
  title: {
    color: '#000000',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start'
  },
  media: {
    height: 140,
    display: 'flex'
  },
  expand: {
    margin: '0 0 0 auto'
  },
  category: {
    marginBottom: 0,
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  categoryTag: {
    color: theme.palette.secondary.main,
    padding: theme.spacing(2 / 3, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600
  },
  collapseText: {
    color: '#6C6C6C',
    fontSize: 14,
    fontWeight: 500,
    //lineHeight: '1.8em',
    textAlign: 'start'
  },
  textLegend: { color: "#000000", textDecoration: 'none' },
  cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
  crossed: {
    textDecoration: 'line-through'
  },
  infoIcon: {
    color: "#FE5D00"
  },
  partner: {
    color: "#B7B7B7",
    textAlign: 'center'
  },
  noData: {
    padding: '3rem 13rem',
    textAlign: 'center'
  },
}));

export default function CardINegotiationEvolutionSupplierGraph({ graphData, width = 'auto' }) {
  const classes = useStyles();
  const cardRef = createRef();
  const [open, setOpen] = useState(false);
  var delayed;

  const options = {
    maintainAspectRatio: false,
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      filler: {
        propagate: false
      },
      labels: {
        font: {
          size: 18,
          family: 'Nunito'
        }
      },
      title: {
        display: false,
        text: generalTexts.homeInvoiceStatus
      }
    },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  }

  const data = {
    labels: [...graphData.map(g => g.ANO)],
    datasets: [
      {
        data: [...graphData.map(g => g.TOTAL)],
        fill: "start",
        backgroundColor: "rgba(14, 156, 255, 0.2)",
        borderColor: "#0E9CFF"
      }
    ]
  };

  return (
    <>
      <Card ref={cardRef} className={classes.root}>
        <CardContent className={classes.cardPadding} padding={0}>
          <Box textAlign="left" display={"flex"} alignItems={"center"}>
            <Box mr={1}>
              <Typography className={classes.yourPosition} component="h1" variant="body2">
                {generalTexts.homeNegotiationEvolution}
              </Typography>
            </Box>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize={'small'} onClick={() => setOpen(true)} />
          </Box>

          {graphData.length > 0 ? (
            <Box>
              <Line data={data} options={options} height={'auto'} width={'auto'} />
            </Box>
          ) : (
            <Box className={classes.noData} >
              <Typography className={classes.partner} component="p" variant="subtitle2">
                Em breve você verá a evolução das suas negociações aqui
              </Typography>
            </Box>
          )}

        </CardContent>
      </Card>
      <InfoModal open={open} handleClose={() => setOpen(false)} type="evolutionNegotiation" />
    </>
  );
}