import styled from "styled-components";
import ReactSelect from 'react-select'


export const InputSearch = styled(ReactSelect)`
  & .Select__control {
    background: #efefef;
  }
  & .Select__option:hover {
    background: #efefef;
  }
  & .Select__option--is-selected {
    background: #a1a1a1;
  }
`;

export const Input = styled.input`
  margin-top:.5rem; 
  height: 2.5em;
  padding: 0.5em 1em;
  width: 100%;
  color: #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px #6C6C6C;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid #C6C6C6;
  font-size: 14px;
  font: normal 300 1rem Nunito !important;
  letter-spacing: -1.06px;
  &:focus {
      outline: none;
  }
`;

export const Select = styled.select`
  margin-top:.5rem; 
  height: 2.5em;
  width: 100%;
  padding: 0 1.5em 0 0.5em;
  border: 1px solid #C6C6C6;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px #6C6C6C;
  border-radius: 5px;
  text-align: left;
  letter-spacing: -1.06px;
  opacity: 1;
  font: normal 300 1rem Nunito !important;
  font-size: 14px;
  color: #000000;
  &:focus {
    outline: none;
  }
`;

export const Section = styled.section`
  margin-top: 2rem
`;