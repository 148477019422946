import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';

import UserProvider from './providers/UserProvider';
import Layout from "./Layout";
import theme from "./theme";
import { LoadingProvider } from './providers/LoadingProvider';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <UserProvider>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </UserProvider>
      </LoadingProvider>
    </ThemeProvider>
  )
}

export default App;
