import styled from "styled-components";

export const Input = styled.input`
  width: 50px;
  border-radius: 5px;
  border: 1px solid #C6C6C6;
  padding: 5px 0;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 5px 0;
  border: 1px solid #C6C6C6;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px #6C6C6C;
  border-radius: 5px;
  text-align: left;
  letter-spacing: -1.06px;
  opacity: 1;
  font: normal 300 1rem Nunito !important;
  font-size: 12px;
  color: #000000;
  &:focus {
    outline: none;
  }
`;