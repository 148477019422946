import React from 'react';

import Model from '../../../components/Model/index'
import { textsDescriptions, textsTitles } from '../../../utils/utils';

export default function AdminCategory() {
  
  return (
    <React.Fragment>
      <Model urlModel="categorias" nomeModel={textsTitles.textTitleCategory} descricaoModel={textsDescriptions.textDescriptionCategory} />
    </React.Fragment>
  );
}