import React from "react";
import {
  Container,
  ContentComoFunciona,
  AspasImgA,
  AspasImgB,
  DivSpanText,
  SpanOne,
  SpanTwo,
  InovacaoSubtitle,
  InvencaoSubtitle,
} from "./styles";
import aspasA from "../../../../assets/a.svg";
import aspasB from "../../../../assets/b.svg";
import { TitleToSmooth } from "../../../../styles";

export default function ComoFunciona() {
  return (
    <>
      <Container>
        <TitleToSmooth id="como">COMO FUNCIONA</TitleToSmooth>
        <h1>COMO FUNCIONA</h1>
        <ContentComoFunciona>
          <AspasImgA src={aspasA} alt={"aspasA"} />
          <p>
            Buscamos empresas que tenham soluções com aderência aos desafios{" "}
            apresentados, ou que estejam dispostas a cocriar conosco essas
            soluções através de no mínimo, um MVP (Produto Mínimo Viável)
            passível de teste e validação.
          </p>
          <AspasImgB src={aspasB} alt={"aspasB"} />
        </ContentComoFunciona>
        <DivSpanText>
          <SpanOne>
            <InovacaoSubtitle>Inovação</InovacaoSubtitle>
            Essa ideia, solução, melhoria, deve estar direcionada para
            solucionar alguma das oportunidades
            <br />
            apresentadas nos desafios e efetivamente agregar mais valor aos
            stakeholders envolvidos gerando
            <br />
            resultados.
          </SpanOne>
          <SpanTwo>
            <InvencaoSubtitle>Invenção</InvencaoSubtitle>
            Não basta ter uma ideia boa, mas que não seja economicamente viável.
          </SpanTwo>
        </DivSpanText>
      </Container>
    </>
  );
}
