import React, { useState, useEffect } from 'react';
import { Container, ModalContent } from './styles';
import Swal from 'sweetalert2';
import api from '../../../../services/api';

export default function ModalEmail({ nomeDesafio }) {

  const modal = document.getElementById("myModal");

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [desafio, setDesafio] = useState("");

  useEffect(() => {
    setDesafio(nomeDesafio);
  }, [nomeDesafio]);

  window.onclick = function (event) {
    const modal = document.getElementById("myModal");
    if (event.target === modal) {
      modal.style.display = "none";
      setName('');
      setContact('');
      setEmail('');
      setTelefone('');
      setDesafio('')
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      empresa: name,
      contato: contact,
      email: email,
      telefone: telefone,
      desafio: desafio,
    };

    try {
      await api.post("utils/sendMail", data);
      Swal.fire({
        zIndex: 10000,
        icon: "success",
        title: "Sucesso!",
        text: "Cadastro confirmado!",
      }).then((result) => {
        if (result.isConfirmed) {
          modal.style.display = "none";
        }
        setName('');
        setContact('');
        setEmail('');
        setTelefone('');
        setDesafio('')
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Ops...",
        text: "Não foi possivel, tente novamente!",
      });
    }
  }

  return (
    <Container id="myModal">
      <ModalContent>
        <h2>Calma ai...</h2>
        <p>
          Estamos ajustando os últimos detalhes desse novo projeto. Enquanto
          isso você já pode realizar a pré-inscrição, enviando as informações
          da sua empresa:
        </p>
        <form onSubmit={handleSubmit}>
          <span>Nome:</span>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          ></input>
          <span>Contato:</span>
          <input
            placeholder="seu nome"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          ></input>
          <span>E-mail:</span>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>
          <span>Telefone:</span>
          <input
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            required
          ></input>
          <span>Desafio de Interesse:</span>
          <input
            value={desafio}
            onChange={(e) => setDesafio(e.target.value)}
            required
          ></input>
          <button type="submit">Guardar o meu lugar</button>
        </form>
      </ModalContent>
    </Container>
  )
}