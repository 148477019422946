import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import initPic from '../../assets/backgroundOne.png';
import { useHistory } from 'react-router-dom';
import { textsLoginPage, textsProfilePage, generalTexts, mockPath } from '../../utils/utils';
import { sessionSignIn, rescuePassword } from '../../services/session';
import { setAuthToken } from '../../services/token';
import { raiseAlert, raiseSuccess } from '../../components/Alert';
import { UserContext } from '../../providers/UserProvider';
import { validMail } from '../../utils/functions';
import { Alert } from '@material-ui/lab';
import { LineScalePulseOut } from 'react-pure-loaders';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${initPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    //marginTop: theme.spacing(8),
    //marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  title: {
    fontSize: 36
  },
  subtitle: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: 'center'
  },
  form: {

    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),


  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  input: {
    fontSize: '16px!important',
    fontWeight: '300!important'
  }
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const { userState, setUserState } = useContext(UserContext);
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function validateEmail() {
      if (email.length > 0) {
        let check = await validMail(email)
        if (check === false) setError(textsProfilePage.validEmail)
        else setError("")
      }
      else setError("")
    }
    validateEmail();
  }, [email]);

  const handleForgotPassword = async () => {
    if (forgotPassword) setForgotPassword(false)
    else setForgotPassword(true)
  }
  const handleRescuePassword = async (event) => {
    event.preventDefault();

    if (error.length > 0) { raiseAlert(textsProfilePage.validEmail); return; }
    else if (email.length < 1) { raiseAlert(generalTexts.notEmpty); return; }
    else
      setRequesting(true)

    await rescuePassword(email).then(async (resp) => {
      if (resp.status === 200) {
        raiseSuccess(resp.data?.message)
        handleForgotPassword();
      } else {
        raiseAlert(resp.error)
      }
      setRequesting(false)
    }).catch(async err => {
      raiseAlert(err.error)
      setRequesting(false)
    });
  }



  const handleLogin = async (event) => {
    event.preventDefault();
    if (error.length > 0) { raiseAlert(textsProfilePage.validEmail); return; }
    else if (email.length < 1 || password.length < 1) { raiseAlert(generalTexts.notEmpty); return; }
    else
      setRequesting(true)
    await sessionSignIn({ EMAIL: email, SENHA: password }).then(async (resp) => {

      if (resp.status === 200) {
        setAuthToken(resp.data?.sessionToken)
        setUserState({ ...userState, ...resp.data, ID: resp.data?.fornecedor })

      } else {
        raiseAlert(resp.error)
      }
      setRequesting(false)
    }).catch(async err => {
      raiseAlert(err.error)
      setRequesting(false)
    });
  }

  return (
    <div className={`centralize ${classes.root}`}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} textAlign="center">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {forgotPassword ? (textsLoginPage.rescuePassword) : (textsLoginPage.title)}
            </Typography>
            <Box className={`xs-screen ${classes.subtitle}`}>
              <Typography variant="subtitle2">
                {textsLoginPage.subtitle}
              </Typography>
              <Button color="primary" onClick={() => { history.push(`${mockPath}cadastrar`) }}
                className={`${classes.textAdapt}`}>
                <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                  {textsLoginPage.link}
                </Typography>
                <span style={{ color: '#000000', textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}>.</span>
              </Button>

            </Box>

          </Box>

          <form className={classes.form} noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (forgotPassword) {
                handleRescuePassword(e)
              } else {
                handleLogin(e)
              }
            }}
          >
            <Grid item xs={12}>
              {error.length > 0 ? (<Alert severity="error">{error}</Alert>) : ('')}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsLoginPage.email}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  className={classes.input}
                  onChange={event => setEmail(event.target.value)}
                  autoComplete="email"
                />
              </Grid>
              {forgotPassword ? ('') : (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {textsLoginPage.password}
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    type="password"
                    id="password"
                    className={classes.input}
                    onChange={event => setPassword(event.target.value)}
                    autoComplete="current-password"
                  />
                </Grid>
              )}


            </Grid>
            <Box mt={2}>
              <Button color="primary" onClick={handleForgotPassword}
                className={`${classes.textAdapt}`}>
                <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                  {forgotPassword ? (textsLoginPage.cancel) : (textsLoginPage.forgotPassword)}
                </Typography>

              </Button>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}

            >
              {requesting ? (
                <LineScalePulseOut
                  color={'#FFFFFF'}
                  className="loading-center"
                  loading={requesting}
                />
              ) : (
                forgotPassword ? (textsLoginPage.send) : (textsLoginPage.enter)
              )}

            </Button>
            <Box mt={1} mb={2}>
              <Button color="primary" onClick={() => { history.push(`/`) }}
                className={`${classes.textAdapt}`}>
                <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                  {textsLoginPage.returnLanding}
                </Typography>
              </Button>
            </Box>
          </form>
        </Paper>

      </Container>
    </div>
  );
}