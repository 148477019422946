
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Main from "./pages/Main";
import ResetPassword from "./pages/ResetPassword";
import SupplierHome from "./pages/Supplier/Home";
import SupplierInvoiceOrder from "./pages/Supplier/InvoiceOrder";
import MyChallenges from "./pages/Supplier/MyChallenges";
import MyChallengesDetails from "./pages/Supplier/MyChallenges/Details";
import MyChallengesSubscriber from "./pages/Supplier/MyChallenges/Subscriber";
import MyChallengesProposal from "./pages/Supplier/MyChallenges/Proposal";
import MyAdminChallengeProposal from "./pages/Admin/Proposal";
import RegisterChallenge from "./pages/Admin/Register";
import EditChallenge from "./pages/Admin/Edit";
import Perfil from "./pages/Supplier/Profile";
import Area from "./pages/Admin/Zone";
import Home from "./pages/Admin/Home";
import Group from "./pages/Admin/Group";
import Category from "./pages/Admin/Category";
import Ranking from "./pages/Supplier/Ranking";
import { Files } from "./pages/Admin/Files"

import OldLandingPage from "./pages/LandPage";
import LandPage2 from "./pages/LandPage2.0";
import NotFound from "./components/NotFound";
import { mockPathDesafios } from "./utils/utils";
import Suppliers from "./pages/Admin/Suppliers";
import News from "./pages/Admin/News";
import SignUpSupplier from "./pages/SignUpSupplier";
import { Users } from "./pages/Admin/Users";
import { SupplierFiles } from "./pages/Supplier/SupplierFiles";
import { Settings } from "./pages/Admin/Settings";


const desafios = mockPathDesafios;
const viewSupplier = "/visualizar-fornecedor/"


const publicRoutes = [
  { path: `${desafios}todos-desafios`, name: "Main", component: Main },
  { path: `${desafios}desafio-detalhes`, name: "MyChallengesDetails", component: MyChallengesDetails },
  { path: desafios, name: "Desafios", component: OldLandingPage },
  { path: `/404`, name: "404", component: NotFound },
  { path: `/nova-senha`, name: "ResetPassword", component: ResetPassword },
  { path: `/login`, name: "Login", component: Login },
  { path: `/cadastrar`, name: "SignUp", component: SignUp },
  { path: `/cadastrar/usuario/:supplier`, name: "SignUp", component: SignUpSupplier },
  { path: `/`, name: "LandPage2", component: LandPage2 },
  { path: `/login`, name: "Login", component: Login }
].map(route => ({ ...route, exact: true }))

const adminRoutes = [
  { path: `${desafios}proposta`, name: "MyAdminChallengeProposal", component: MyAdminChallengeProposal },
  { path: `${desafios}novo-desafio`, name: "RegisterChallenge", component: RegisterChallenge },
  { path: `${desafios}editar-desafio`, name: "EditChallenge", component: EditChallenge },
  { path: `/principal`, name: "Main", component: Main },
  { path: `/areas`, name: "Area", component: Area },
  { path: `/home`, name: "Home", component: Home },
  { path: `/grupos`, name: "Group", component: Group },
  { path: `/categorias`, name: "Category", component: Category },
  { path: `/fornecedores`, name: "Supplier", component: Suppliers },
  { path: `/fornecedores`, name: "Supplier", component: Suppliers },
  { path: `/usuarios`, name: "Users", component: Users },
  { path: `/news`, name: "News", component: News },
  { path: `/configuracoes`, name: "News", component: Settings },
  { path: `/arquivos`, name: "Files", component: Files },
  { path: `/arquivos/:supplier`, name: "Files", component: Files },
  { path: `/arquivos/:supplier/:folder`, name: "Files", component: Files },
  { path: `${viewSupplier}home/:id/:pessoa`, name: "SupplierHomeAdmin", component: SupplierHome },
  { path: `${viewSupplier}home/:id/`, name: "SupplierHomeAdmin", component: SupplierHome },
  { path: `${viewSupplier}ordens-de-compra/:id/:pessoa`, name: "SupplierInvoiceOrderAdmin", component: SupplierInvoiceOrder },
  { path: `${viewSupplier}ordens-de-compra/:id/`, name: "SupplierInvoiceOrderAdmin", component: SupplierInvoiceOrder },
  { path: `${viewSupplier}perfil/:id/`, name: "SupllierProfileAdmin", component: Perfil },
  { path: `${viewSupplier}perfil/:id/:pessoa`, name: "SupllierProfileAdmin", component: Perfil },
].map(route => ({ ...route, exact: true, can: 'admin' }))

const supplierRoutes = [
  { path: `/principal`, name: "SupplierHome", component: SupplierHome, },
  { path: `/ordens-de-compra`, name: "SupplierInvoiceOrder", component: SupplierInvoiceOrder },
  { path: `/ranking`, name: "Ranking", component: Ranking },
  { path: `/perfil`, name: "Perfil", component: Perfil },
  { path: `/arquivos`, name: "Arquivos", component: SupplierFiles },
  { path: `/arquivos/:folder`, name: "Arquivos", component: SupplierFiles },
  { path: `${desafios}meus-desafios`, name: "MyChallenges", component: MyChallenges },
  { path: `${desafios}meu-desafio/enviar-proposta`, name: "MyChallengesSubscriber", component: MyChallengesSubscriber },
  { path: `${desafios}meu-desafio`, name: "MyChallengesProposal", component: MyChallengesProposal },
].map(route => ({ ...route, exact: true, can: 'supplier' }))

const routes = [
  ...publicRoutes,
  ...adminRoutes,
  ...supplierRoutes
]

export default routes;