import api from './api';

export const getAllModels = async (urlParam) => {
    
    return await api.get(`${urlParam}`).then(response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
}

export const createModel = async (urlParam, data) => {

    return await api.post(`${urlParam}`, data).then(async response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
} 

export const updateModel = async (urlParam, params, data) => {
    return await api.put(`${urlParam}`, data , params ).then(async response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
} 

export const deleteModel = async (urlParam, params) => {

    return await api.delete(`${urlParam}`, params).then(async response => {
        return response
    }).catch((err) =>{
        return { status:400, error: err.response.data.message}
    });
} 