import styled from "styled-components";
import { Select, Chip } from "@material-ui/core";



export const SelectChipFormChallenge = styled(Select)`
    height: 100%;
    width: 100%;
    padding: 0 1.5em 0 0.5em;
    border: 1px solid #C6C6C6;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #6C6C6C;
    border-radius: 5px;
    text-align: left;
    font: normal 300 1rem Nunito;
    letter-spacing: -1.06px;
    color: #8D8D8D;
    opacity: 1;

    &:focus {
        outline: none;
    }

    .MuiSelect-selectMenu {
        white-space: normal !important;    
    }
`

export const ChipFormChallenge = styled(Chip)`
    height: 2.5em;
    margin: 10px;
    padding: 6px 12px;
    border-radius: 5px;
    font-weight: 500;
    color: #FFF;
    font: normal 300 1rem Nunito;
    background-color: #FE5D00;
`