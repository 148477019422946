import styled from "styled-components";

export const ButtonWhite = styled.button`
  background-color: white;
  color: #FE5D00;
  border: solid 2px #FE5D00;
  border-radius: 8px;
  padding: 10px;
  width: 110px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8rem;
  cursor: pointer;
  `;

export const ButtonOrange = styled.button`
  cursor: pointer;
  color: white;
  background-color: #FE5D00;
  border: solid 2px #FE5D00;
  border-radius: 8px;
  padding: 10px;
  width: 110px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8rem;
`;

export const ButtonWhiteGrid = styled.button`
  background-color: white;
  color: #FE5D00;
  border: solid 2px #FE5D00;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: .8rem;
  cursor: pointer;
  font-family: 'Nunito'
`;
export const ButtonOrangeGrid = styled.button`
  color: white;
  background-color: #FE5D00;
  border: solid 2px #FE5D00;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: .8rem;
  margin-right: 12px;
  font-family: 'Nunito'
`;

export const ButtonOrangeGridState = styled.button`
  color: white;
  background-color: #FE5D00;
  border: solid 2px #FE5D00;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: .8rem;
  margin-right: 12px;
  font-family: 'Nunito'
`;

export const Input = styled.input`
  padding: 8px 20px;
  justify-content: flex-end;
  color: #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px #6C6C6C;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid #C6C6C6;
  font-size: 14px;
  font: normal 300 1rem Nunito !important;
  letter-spacing: -1.06px;
  &:focus {
    outline: none;
  }

`;

export const FilterContainer = styled.div`
  margin-top: 15px; 
  display: flex; 
  justify-content: space-between; 
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 12px
  }

  input {
    width: 100%;
  }
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
export const FilterFieldsContainer = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const AccordionContent = styled.div`
  display: flex; 
  justify-content: space-between; 
  flex: 1;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  .infos {
    display: flex; 
    justify-content: flex-start; 
    gap: 2rem; 
    margin: .5rem 0 1rem 2rem;

    @media (max-width: 1030px) {
      flex-direction: column;
    }
  }

  .buttons {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: 1220px) {
      flex-direction: column;
    }

    @media (max-width: 550px) {
      margin-left: 1.8rem;
      flex-direction: row;
    }
  }
`;

export const InfoDiv = styled.div`
  display: block;
  font-size: 1rem;

  > p.label {
    color: #B7B7B7;
    font-weight: 'normal';
    margin: 0;

    > span {
      font-weight: bold;
      color: black;
      margin-left: .4rem;
    }
  }
`;