/* eslint-disable eqeqeq */
import { useContactModal } from './hooks';
import BaseModal from '../../../../components/BaseModal';
import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import api from '../../../../services/api';
import ReactInputMask from 'react-input-mask';
import * as S from './styles';

const contactBaseObject = {
  COMPANY: '',
  CNPJ: '',
  MARKET: '',
  FUNDATION: '',
  PRODUCT: '',
  VISITOR: '',
  POSITION: '',
  EMAIL: '',
  PHONE: '',
  SUBGROUP: '',
};

export function ContactModal({ open, onClose }) {
  const { groups } = useContactModal();
  const [form, setForm] = useState(contactBaseObject);
  const [sended, setSended] = useState('EMPTY');
  const [subgroups, setSubgroups] = useState([]);
  const matches = useMediaQuery('(max-width:1500px)');
  const [error, setError] = useState(false);

  async function handleSendForm(event) {
    event.preventDefault();
    const hasEmptyField = Object.entries(form).some((item) => item[1] === '');

    if (hasEmptyField) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
      return;
    }
    setSended('PENDING');
    try {
      await api.post('fornecedores/dia-fornecedor', form);
      setSended('SENDED');
      setForm(contactBaseObject);

      setTimeout(() => {
        handleClose();
      }, 1300);
    } catch (e) {}
  }

  function handleSetForm(field, event) {
    setForm((form) => ({ ...form, [field]: event.target.value }));

    if (field == 'MARKET') {
      setSubgroups(groups.find((g) => g.NOME == event.target.value).SUBGRUPOS);
    }
  }

  function handleClose() {
    onClose();
    setForm(contactBaseObject);
    setSended('EMPTY');
  }

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      contentStyle={S.cardContentModalStyles}
      cardStyle={{
        width: matches ? '60%' : '40%',
        ...S.cardModalStyles,
      }}
    >
      <form onSubmit={handleSendForm}>
        {error && (
          <S.ErrorMessage>
            Todos os campos são obrigatórios, por favor revise o formulário!
          </S.ErrorMessage>
        )}
        <S.ContactFormGrid>
          <S.FormControl>
            <label htmlFor="COMPANY">Nome da sua empresa</label>
            <input type="text" onChange={(e) => handleSetForm('COMPANY', e)} />
          </S.FormControl>
          <S.FormControl>
            <label htmlFor="">Informe o produto ou serviço</label>
            <input type="text" onChange={(e) => handleSetForm('PRODUCT', e)} />
          </S.FormControl>

          <S.FormControl>
            <label htmlFor="">CNPJ</label>
            <ReactInputMask
              mask="99.999.999/9999-99"
              type="text"
              placeholder="99.999.999/9999-99"
              onChange={(e) => handleSetForm('CNPJ', e)}
            />
          </S.FormControl>
          <S.FormControl>
            <label htmlFor="">Nome do visitante</label>
            <input type="text" onChange={(e) => handleSetForm('VISITOR', e)} />
          </S.FormControl>

          <S.FormControl>
            <label htmlFor="">Mercado de atuação</label>
            <select onChange={(e) => handleSetForm('MARKET', e)}>
              <option value={0}>Selecione</option>
              {groups.map((group) => {
                return <option value={group.NOME}>{group.NOME}</option>;
              })}
            </select>
          </S.FormControl>
          <S.FormControl>
            <label htmlFor="">Função do visitante</label>
            <input type="text" onChange={(e) => handleSetForm('POSITION', e)} />
          </S.FormControl>

          <S.FormControl>
            <label htmlFor="">Subgrupo de atuação</label>
            <select onChange={(e) => handleSetForm('SUBGROUP', e)}>
              <option value={0}>Selecione</option>
              {subgroups.map((subgroup) => {
                return (
                  <option value={subgroup.ID}>{subgroup.SUB_GRUPO}</option>
                );
              })}
            </select>
          </S.FormControl>
          <S.FormControl>
            <label htmlFor="">E-mail visitante</label>
            <input type="text" onChange={(e) => handleSetForm('EMAIL', e)} />
          </S.FormControl>

          <S.FormControl>
            <label htmlFor="">Data de fundação da empresa</label>
            <ReactInputMask
              type="text"
              mask="99/99/9999"
              onChange={(e) => handleSetForm('FUNDATION', e)}
            />
          </S.FormControl>
          <S.FormControl>
            <label htmlFor="">Telefone visitante</label>
            <ReactInputMask
              mask="(99) 99999-9999"
              type="text"
              placeholder="(99) 99999-9999"
              onChange={(e) => handleSetForm('PHONE', e)}
            />
          </S.FormControl>
        </S.ContactFormGrid>

        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          {sended == 'EMPTY' && (
            <S.ContactButton colorScheme="primary">
              Enviar E-mail
            </S.ContactButton>
          )}
          {sended == 'PENDING' && (
            <S.ContactButton colorScheme="secondary" disabled>
              Enviando...
            </S.ContactButton>
          )}
          {sended == 'SENDED' && (
            <S.ContactButton colorScheme="secondary" disabled>
              E-mail enviado com sucesso
            </S.ContactButton>
          )}
        </div>
      </form>
    </BaseModal>
  );
}
