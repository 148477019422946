export const Palette = {
  type: 'light',
  primary: {
    light: '#F4F8F9',
    main: '#FE5D00',
    dark: '#e65400',
    contrastText: '#fff'
  },
  secondary: {
    light: '',
    main: '#FFFFFF',
    dark: '#313752',
    contrastText: '#fafafa'
  },
  default: {
    light: '#F4F8F9',
    main: '#000000',
    dark: '#2d9cdba6',
    contrastText: '#333333'
  }

}

export default Palette;