import { Box } from "@material-ui/core";
import React from "react";
import logoBibi from "../../../../assets/logo.svg";

export default function Footer() {
  return (
    <Box textAlign='center' mt={1} mb={1}>
      <img className="logo" src={logoBibi} alt="logo" height={40} width={70}/>
    </Box>
  );
}
