import styled from 'styled-components';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Fragment } from 'react';

const Title = styled.h3`
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
`;

export const TableTitle = Title;

export function DetailModal({ open, onClose, budgets }) {
  const groups = [...new Set([...budgets.map(({ GRUPO }) => GRUPO)])];

  const monthsNumber = [...new Set([...budgets.map(({ MES }) => MES)])];
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    month: 'long',
  });

  const months = monthsNumber.map((m) => {
    const date = new Date();
    date.setMonth(m - 1);
    return formatter.format(date);
  });

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="paper"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="max-onClose-dialog-title"
      style={{ padding: 0 }}
    >
      <div style={{ padding: '1rem' }}>
        <Title>Orçamento compras - grupo Bibi</Title>

        <TableContainer
          style={{
            marginTop: '2rem',
            position: 'relative',
          }}
        >
          <Table style={{ borderCollapse: 'separate' }}>
            <TableHead>
              <TableRow>
                <Column
                  rowSpan={2}
                  style={{
                    fontWeight: 'bold',
                    minWidth: '250px',
                    textAlign: 'center',
                    borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                    background: 'white',
                  }}
                >
                  AGRUPAMENTO
                </Column>
                {months.map((month) => (
                  <TableCell
                    key={month}
                    style={{
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      background: '#EC660C',
                      borderRight: 'solid 1px black',
                      fontWeight: '700',
                      color: '#fff',
                    }}
                    colSpan={2}
                  >
                    {month}{' '}
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    background: '#EC660C',
                    borderRight: 'solid 1px black',
                    fontWeight: '700',
                    color: '#fff',
                  }}
                >
                  Total
                </TableCell>
              </TableRow>

              <TableRow>
                {months.map((month) => (
                  <Fragment key={month}>
                    <TableCell
                      key={month}
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Meta
                    </TableCell>
                    <TableCell
                      key={month}
                      style={{
                        textTransform: 'uppercase',
                        borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Realizado
                    </TableCell>
                  </Fragment>
                ))}
                <TableCell
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  colSpan={2}
                >
                  Realizado
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {groups.map((item) => (
                <TableRow key={item}>
                  <Column
                    key={item}
                    style={{
                      fontWeight: 'normal',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '100px',
                      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                      maxWidth: '250px',
                    }}
                  >
                    {item}
                  </Column>
                  {monthsNumber.map((m) => {
                    return (
                      <Fragment key={m}>
                        <TableCell
                          style={{ width: '600px', fontWeight: 'normal' }}
                        >
                          {budgets
                            .find((b) => b.MES === m && b.GRUPO === item)
                            ?.META.toReal()}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                            fontWeight: 'normal',
                          }}
                        >
                          {budgets
                            .find((b) => b.MES === m && b.GRUPO === item)
                            ?.VALOR_TOTAL.toReal()}
                        </TableCell>
                      </Fragment>
                    );
                  })}
                  <TableCell>
                    {budgets
                      .filter((b) => b.GRUPO === item)
                      .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
                      .reduce((prev, next) => prev + next, 0)
                      .toReal()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableHead>
              <Column
                style={{
                  fontWeight: 'bold',
                  borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                }}
              >
                TOTAL
              </Column>
              {monthsNumber.map((m) => (
                <Fragment key={m}>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {budgets
                      .filter((i) => i.MES === m)
                      .map(({ META }) => META)
                      .reduce((prev, next) => prev + next, 0)
                      ?.toReal()}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
                    }}
                  >
                    {budgets
                      .filter((i) => i.MES === m)
                      .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
                      .reduce((prev, next) => prev + next, 0)
                      ?.toReal()}
                  </TableCell>
                </Fragment>
              ))}
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                {budgets
                  .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
                  .reduce((prev, next) => prev + next, 0)
                  .toReal()}
              </TableCell>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
    </Dialog>
  );
}

export const Column = styled(TableCell)`
  position: sticky;
  left: 0;
  background-color: white;
`;
