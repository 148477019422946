import styled from "styled-components";

export const Container = styled.section`
  background: #CDD2CE;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;

  > .title {
     margin: 0; 
     font-size: 1.4rem; 
     font-weight: bold
  }
`

export const SupplierDayHighlight = styled.p`
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  background: white;
  border-radius: 5px;
  padding: .5rem .8rem;
`;

export const Button = styled.button`
  background: #FE5D00;
  border: none;
  border-radius: 8px;
  padding: .5rem 1rem;
  color: white;
  font-size: 1.2rem;
  font-family: Nunito;
  font-weight: 400;
  cursor: pointer;
`;

// Modal de contato

export const cardModalStyles = {
  padding: '2rem',
  minHeight: 0,
  maxHeight: '90vh',
  overflowY: 'scroll',
}

export const cardContentModalStyles = {
  padding: '0 8px',
  fontWeight: 'normal'
}

export const ContactFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  justify-content: space-between;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

export const FormControl = styled.div`
  width: 100%;
  label {
    display: block;
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  input,
  select {
    width: 100%;
    border-radius: 5px;
    border: solid 0.5px #dadada;
    background: #fff;
    height: 35px;
    padding: 0 0.8rem;
    font-size: 16px;
  }
`;

export const ContactButton = styled.button`
  border: solid 2px #fe5d01;
  border-radius: 10px;
  background: #fff;
  background: ${props => props.colorScheme === 'primary' ? '#fff' : '#fe5d01'};
  height: 45px;
  padding: 0 4rem;
  margin-top: 2rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};;

  color: ${props => props.colorScheme === 'primary' ? '#fe5d01' : '#fff'};
  font-weight: bold;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
`;