import { createContext, useContext, useState } from "react";
import Loader from "../components/Loader";

const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (bool) => {
    if (!bool) {
      return setTimeout(() => setIsLoading(false), 500);
    }

    setIsLoading(true);
  }

  const value = { setLoading };

  return (
    <LoadingContext.Provider value={value}>
      {isLoading && <Loader />}
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}