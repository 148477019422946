/* eslint-disable react-hooks/exhaustive-deps */

import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { generalTexts } from "../../utils/utils";
import CommonMedal from "../../assets/gold_medal.svg";
import Gold from "../../assets/gold.svg";
import Silver from "../../assets/silver.svg";
import Bronze from "../../assets/bronze.svg";
import DialogFornecedores from '../ModalFornecedores';
import { useState } from 'react';
import InfoModal from '../InfoModal';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: 'auto',
    minWidth: 'fit-content',
    padding: '14px 22px',
    '&:hover': {
      cursor: "pointer",
      boxShadow: '0px 0px 5px #FE5D00'
    }
  },
  countUp: {
    fontSize: '48px',
  },
  title: {
    color: "#000000",
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start'
  },
  media: {
    height: 140,
    display: 'flex'
  },
  expand: {
    margin: '0 0 0 auto'
  },
  category: {
    marginBottom: 0,
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  categoryTag: {
    color: theme.palette.secondary.main,
    padding: theme.spacing(2 / 3, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600
  },
  collapseText: {
    color: '#6C6C6C',
    fontSize: 14,
    fontWeight: 500,
    //lineHeight: '1.8em',
    textAlign: 'start'
  },
  cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
  infoIcon: {
    color: "#FE5D00"
  },
  positionText: {
    color: "#B7B7B7",
    fontSize: 18,
    width: 'min-content'
  },
  yourPosition: { fontSize: 18 },
  ranking: { fontSize: 46 },
  rankingTotal: { fontSize: 16 }
}));

export default function CardRankingSupplier({ withoutCard = false, ranking, totalSuppliers }) {


  const classes = useStyles();
  const cardRef = createRef();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [medal, setMedal] = React.useState(CommonMedal);
  const [positionText, setPositionText] = React.useState(generalTexts.lookRanking);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    switch (ranking) {
      case 1:
        setMedal(Gold)
        setPositionText(generalTexts.goldPosition)
        setFlag(true)
        break;
      case 2:
        setMedal(Silver)
        setPositionText(generalTexts.silverPosition)
        setFlag(true)
        break;
      case 3:
        setMedal(Bronze)
        setPositionText(generalTexts.bronzePosition)
        setFlag(true)
        break;
      default:
        setMedal(CommonMedal)
        setPositionText(generalTexts.lookRanking)
        setFlag(false)
    }

  }, [ranking]);

  if (withoutCard) {


    return (
      <>
        <Box textAlign="left" display={"flex"} alignItems={"center"}>
          <Box mr={1}>
            <Typography className={classes.yourPosition} component="h1" variant="body2">
              {generalTexts.yourPosition}
            </Typography>
          </Box>
        </Box>
        <Box textAlign="left" display={"flex"} alignItems={'baseline'}>
          <Typography className={classes.ranking} component="h1" variant="body2">
            {ranking ? ranking : 0}
          </Typography>
          &nbsp;
          <Box mr={1}>
            <Typography className={classes.rankingTotal} component="h1" variant="body2">
              {"/"}{totalSuppliers ? (totalSuppliers) : ('150')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Card ref={cardRef} className={classes.root}>
        <DialogFornecedores openDialog={openDialog} setOpenDialog={setOpenDialog} />
        <CardContent className={classes.cardPadding} padding={0}>
          <Box textAlign="left" display={"flex"} alignItems={"center"}>
            <Box mr={1}>
              <Typography className={classes.yourPosition} component="h1" variant="body2">
                {generalTexts.yourPosition}
              </Typography>
            </Box>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize={'small'} onClick={() => setOpen(true)} />
          </Box>
          <Box textAlign="left" display={"flex"} alignItems={'baseline'}>
            <Typography className={classes.ranking} component="h1" variant="body2">
              {ranking ? ranking : 0}
            </Typography>
            &nbsp;
            <Box mr={1}>
              <Typography className={classes.rankingTotal} component="h1" variant="body2">
                {"/"}{totalSuppliers ? (totalSuppliers) : ('150')}
              </Typography>
            </Box>
          </Box>

          <Link to="ranking" style={{ textDecoration: 'none' }}>
            <Box textAlign="left" display={"flex"} alignItems={"center"}>
              <img src={medal} alt="icone medalha" />
              <Box pt={1} ml={0.5}>
                <Typography className={[flag ? classes.positionText : '', classes.infoIcon]} component="h1" variant="body2">
                  {positionText}
                </Typography>
              </Box>
            </Box>
          </Link>

        </CardContent>
      </Card>
      <InfoModal open={open} handleClose={() => setOpen(false)} type="ranking" />
    </>
  );
}