import React, { createRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';

import { fornecedores } from "../../utils/utils";
import CountUp from 'react-countup';
import DialogFornecedores from '../ModalFornecedores';

export default function CardCountUp({ suppliersNumber }) {
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: 'center',
      // width: 'auto',
      // minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: "pointer",
        boxShadow: '0px 0px 5px #FE5D00'
      }
    },
    countUp: {
      fontSize: '48px',
    },
    title: {
      color: "#000000",
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start'
    },
    media: {
      height: 140,
      display: 'flex'
    },
    expand: {
      margin: '0 0 0 auto'
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'start'
    },
    cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
  }));

  const classes = useStyles();
  const cardRef = createRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [height, setHeight] = useState('')

  useEffect(() => {
    if (window) {
      setTimeout(() => {
        const tamanho = document.getElementById('tamanho')
        setHeight(`${tamanho.offsetHeight}px`)
      }, 200)
    }
  }, [])

  return (
    <Card ref={cardRef} className={classes.root} style={{ height }}>
      <DialogFornecedores openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left">
          <Typography className={classes.title} component="h1" variant="subtitle1">
            {fornecedores[0]}
          </Typography>
        </Box>
        <Box textAlign="left" onClick={() => setOpenDialog(true)}>
          <CountUp delay={0} start={0} end={suppliersNumber}>
            {({ countUpRef }) => (
              <div>
                <span style={{ fontSize: '48px' }} className={classes.countUp} ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </Box>
      </CardContent>
    </Card>
  );
}