import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  typography,
  palette,
  overrides: {
    MuiTypography: {
      root: {
        overflowWrap: 'break-word'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.00)!important'
        }
      }
    },
    MuiInputBase: {
      root: {
        height: 36,
        fontSize: 16,
        fontWeight: 300
      },
      input: {
        padding: 0
      }
    },
    MuiInput: {
      formControl: {
        height: 'auto'
      }
    },
    MuiDataGrid: {
      root: {
        fontWeight: 500,
        '& MuiDataGrid-colCellTitle': {
          fontWeight: '600!important'
        }
      },

    },
    MuiButton: {
      root: {
        borderRadius: 5,
        '&#noHover1:hover,&#noHover2:hover': {
          backgroundColor: 'unset!important'
        },
      },
      startIcon: {
        marginRight: 0,
      },
      contained: {
        "&:hover": {
          boxShadow: `unset!important`,

        }
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0px!important',
        paddingLeft: 2,
        height: 'auto',
        '& #list_optionals_knowledge,#list_levels_knowledge, #list_optional_language, #list_level_language, #list_level_knowledge': {
          color: '#F2F2F2!important',
          borderRadius: 5,
          backgroundColor: '#56CCF2!important',
          padding: '5px 5px 5px 5px',
          fontWeight: '400!important',
        },
      },
      root: {
        marginTop: 17,
        padding: '0px!important',
        '& #level_language, #level_knowledge': {
          marginTop: 0,
        }
      },
      tag: {
        color: '#F2F2F2!important',
        backgroundColor: '#56CCF2!important',
        fontWeight: '400!important',
        margin: 8
      }
    },
    MuiChip: {
      deleteIcon: {
        color: '#F2F2F2!important'
      }
    },
    MuiCardContent: {
      root: {
        padding: '16px 8px',
      }
    },
    MuiStepper: {
      root: {
        padding: '16px 0px 16px 0px',
      }
    },
    MuiSelect: {
      root: {
      },
      selectMenu: {
        minHeight: '2.1876em'
      }
    },
    MuiFormLabel: {
      root: {
        marginBottom: 8
      }
    },
    MuiSkeleton: {
      text: {
        transform: 'unset'
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: '0px 16px 16px'
      }
    },
    MuiAccordionSummary: {
      content: {
        '&.Mui-expanded': { margin: 0 }
      }
    }
    ,
    MuiPaper: {
      root: {
        borderRadius: '5px!important',
      }
    },
    MuiDrawer: {
      paperAnchorLeft: {
        borderRadius: '0px!important',
      }
    },
    MuiAvatar: {
      colorDefault: {
        zIndex: '10!important',
        width: 30,
        height: 30,
        alignSelf: 'center',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 18,
        color: '#4F4F4F',
        border: '2px solid #F9F9F9!important',
        backgroundColor: '#F2F2F2'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        '&$focused': {
          borderColor: 'rgb(214 214 214 / 23%)!important',
          borderWidth: '0px!important'
        },
      },
      input: {
        padding: 10,
      },
      notchedOutline: {
      },
    },
    MuiButtonGroup: {
      root: {
        boxShadow: 'unset!important'
      },
      groupedContainedHorizontal: {
        "&:not": {
          "&(:last-child)": {
            border: 'unset!important'
          }
        }
      }
    },
    MuiDropzonePreviewList: {
      imageContainer: {
        display: 'block!important'
      },
      image: {
        color: 'inherit',
        boxShadow: 'inherit'
      }
    },
  },
});

export default theme;