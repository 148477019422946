import React, { useEffect, useState, useContext } from "react";
import logoBibi from "../../assets/logo.svg";
import { NavHeader } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery, Button, Typography } from '@material-ui/core';
import { headerSupplier, headerAdmin, mockPath } from '../../utils/utils';
import { UserContext } from "../../providers/UserProvider";
import { setAuthToken } from "../../services/token";
import clsx from 'clsx';
import MenuListComposition from "../MenuListComposition";
import { isBibiUser, isSupplierUser } from "../../utils/roles";


export default function Header() {

  const history = useHistory();
  const location = useLocation();
  const [selected, setSelected] = useState('/');
  const { userState, setUserState } = useContext(UserContext);
  let headerList = isSupplierUser(userState?.tipo) ? headerSupplier : (isBibiUser(userState?.tipo) ? headerAdmin : headerSupplier.filter(item => item === { text: 'SAIR', path: `${mockPath}sair` }));
  const [headerFake, setHeaderFake] = useState(null)


  const matches = useMediaQuery('(max-width:950px)');

  useEffect(() => {
    if (location.pathname.includes('visualizar-fornecedor')) {
      const id = location.pathname.split('/')[3];
      const pessoa = location.pathname.split('/')[4];
      let homeRoute = `visualizar-fornecedor/home/${id}`;
      let orderRoute = `visualizar-fornecedor/ordens-de-compra/${id}`;
      let profileRoute = `visualizar-fornecedor/perfil/${id}`;
      if (pessoa) {
        homeRoute += `/${pessoa}`
        orderRoute += `/${pessoa}`
        profileRoute += `/${pessoa}`
      }
      setHeaderFake([
        { text: 'HOME', path: `${mockPath}${homeRoute}` },
        { text: 'ORDENS DE COMPRA', path: `${mockPath}${orderRoute}` },
        { text: 'PERFIL', path: `${mockPath}${profileRoute}` },
      ])
    }
  }, [location])

  useEffect(() => {

    if (location?.pathname) {
      setSelected(location?.pathname)
    }

  }, [location])

  const goToAddress = async (address) => {
    window.open(address)
  };

  function goToPage(path) {
    if (path === '/sair') {
      setUserState({ sessionToken: null })
      setAuthToken(null)
      window.localStorage.setItem('isLogged', false)
      history.push(`${mockPath}`)
    } else {
      setSelected(path)
      if (matches) toggleMenu();
      history.push(path)
    }
  }

  function toggleMenu() {
    const menu = document.querySelector(".menu-toggle");
    const nav = document.querySelector(".site-nav");
    nav.classList.toggle("site-nav-open");
    menu.classList.toggle("open");
  }

  return (
    <NavHeader className='header-standand'>
      <div className="container header-div">
        <div className="logo-div">
          <a href={isSupplierUser(userState?.tipo) ? '/principal' : '/home'}>
            <img className="logo" src={logoBibi} alt="logo" />
          </a>
        </div>

        <nav className="site-nav">
          <ul>
            {!headerFake && headerList.filter(header => {
              if (userState?.tipo === 'F' || userState?.tipo === 'B') {
                return !header.role || userState.permissoes.includes(header.role)
              }

              return true
            })
              .map(header => {
                if (header.hasMenus) {
                  const children = header.children.filter(subHeader => {
                    if (userState?.tipo === 'F' || userState?.tipo === 'B') {
                      return !subHeader.role || userState.permissoes.includes(subHeader.role)
                    }

                    return true
                  })

                  return {
                    ...header,
                    children
                  }
                }

                return header
              }).filter(header => {
                if (header?.children) {
                  return header.children.length > 0
                }

                return true
              })
              .map((item, index) => (
                item?.hasMenus ? (
                  <li key={index}>
                    <MenuListComposition
                      parent={item}
                      goToPage={goToPage}
                      goToAddress={goToAddress}
                      permissoes={userState?.permissoes ?? []}
                      tipo={userState.tipo}
                    />
                  </li>
                ) : (
                  <li key={`header_item${index}`}>
                    <Button
                      color="primary"
                      onClick={() => item?.open ? goToAddress(item.path) : goToPage(item.path)}
                      className='text-adapt'
                      disableTouchRipple
                    >
                      <Typography
                        color="inherit"
                        className={clsx('fake-link', { 'selected-page': selected === item.path })}
                        variant="subtitle1">
                        {item.text}
                      </Typography>
                    </Button>
                  </li>
                )
              ))}

            {headerFake && headerFake.map((item, index) => (
              item?.hasMenus ? (
                <li key={index}><MenuListComposition parent={item} goToPage={goToPage} goToAddress={goToAddress} /></li>) : (
                <li key={`header_item${index}`}>
                  <Button color="primary" onClick={() => item?.open ? goToAddress(item.path) : goToPage(item.path)}
                    className='text-adapt'
                    disableTouchRipple>
                    <Typography color="inherit" className={clsx('fake-link', {
                      'selected-page': selected === item.path
                    })} variant="subtitle1">
                      {item.text}
                    </Typography>

                  </Button>
                </li>
              )
            ))}

          </ul>
        </nav>

        <div className="menu-toggle" onClick={() => toggleMenu()}>
          <div className="hamburger"></div>
        </div>
      </div>
    </NavHeader>
  );
}
