import { useEffect, useState } from 'react';
import { SettingsService } from '../../../../services/settings';
import { getGroups } from '../../../../services/subgroup';

export function useSupplierDay() {
  const [day, setDay] = useState('');
  const [contactOpen, setOpenContact] = useState(false);

  useEffect(() => {
    SettingsService.getById(1).then(({ VALOR }) => setDay(VALOR));
  }, []);

  return { day, contactOpen, setOpenContact };
}

export function useContactModal() {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups().then((response) => setGroups(response.data));
  }, []);

  return { groups };
}
