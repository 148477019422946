import React, {useState, useEffect} from 'react';
import { getAllModels } from '../../services/models';
import { raiseAlert } from '../Alert';

export default function ListOptionsModel({urlParam = '', createOptions = [] }) {

    const [models, setModels] = useState();
    
    useEffect(() => {
        if(urlParam !== '') {
            async function getOptionsModels() {
                await getAllModels(urlParam).then(response => {
                    setModels(response?.data)
                }).catch(async err => {
                    raiseAlert(err.error)
                });
            }
            getOptionsModels()
        }else if(createOptions.length > 0) {
            setModels(createOptions)
        }
    }, [ urlParam ])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <option selected value='' disabled>Selecione</option>
            { models?.map((model, index) => (
              <option key={model?.ID} value={model?.ID}>{model?.NOME}</option>
            ))}
        </React.Fragment>
    );
  }