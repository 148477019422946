import styled from "styled-components";
import { Button } from "@material-ui/core";

export const Video = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  iframe {
    top: 0;
    left: 0;
    width: 50%;
    height: 500px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    height:500px;
  }
`;


export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

export const MedalsImage = styled.img`
  max-width: 10em;
  max-height: 10em;
  width: 100%;
  height: 100vh;
`;

export const DivOne = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`;

export const DivTwo = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  height: 150px;
  button {
    font-family: Nunito;
    font-size: 24px;
    color: #ffffff;
    height: 70px;
    width: 270px;
    background-color: #06C8A9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover {
      cursor: pointer;
      background-color: #06c8a982;
    }
  }
`
export const FakeButton = styled(Button)`
    height: 3em;
    min-height: 3em;
    width: 12em;
    min-width: 12em;
    padding: 6px 10px;
    margin:  ${props => props.margin ? props.margin : '10px 15px 10px 0'};
    font-weight: 600;
    color: #FE5D00;
    background-color: #FFFFFF;
    border: 2px solid #FE5D00;
    text-transform: none;
    pointer-events: none;
    border-radius:10px;
    @media(max-width: 425px) {
        margin: 10px auto;
    }

    &:hover {
        background-color: transparent;
    }
`
;

