import goldMedal from "../../../assets/gold.svg";
import silverMedal from "../../../assets/silver.svg";
import bronzeMedal from "../../../assets/bronze.svg";
import sustentabilidade from "../../../assets/sustentabilidade-icon.svg";
import inovacao from "../../../assets/inovacao-icon.svg";
import servico from "../../../assets/servico.svg";
import competitividade from "../../../assets/competitividade.svg";

export function getFormattedRanking(ranking) {
  const podium = [];
  if (ranking.POS_1) {
    podium.push({ icon: goldMedal, name: ranking.POS_1, primaryInfo: 'Fornecedor', secondaryInfo: 'Ouro', isBreak: true })
  }

  if (ranking.POS_2) {
    podium.push({ icon: silverMedal, name: ranking.POS_2, primaryInfo: 'Fornecedor', secondaryInfo: 'Prata' })
  }

  if (ranking.POS_3) {
    podium.push({ icon: bronzeMedal, name: ranking.POS_3, primaryInfo: 'Fornecedor', secondaryInfo: 'Bronze' })
  }

  const rankings = [
    { title: 'Pódio', itens: podium }
  ]

  const primaryInfo = 'Destaque';
  if (ranking.SUSTENTABILIDADE.length > 0) {
    const { SUSTENTABILIDADE } = ranking
    const secondaryInfo = 'Sustentabilidade'
    const itens = SUSTENTABILIDADE.map(name => ({ icon: sustentabilidade, name, primaryInfo, secondaryInfo }))
    rankings.push({ title: 'Destaques sustentabilidade', itens })
  }

  if (ranking.INOVACAO.length > 0) {
    const { INOVACAO } = ranking
    const secondaryInfo = 'Inovação'
    const itens = INOVACAO.map(name => ({ icon: inovacao, name, primaryInfo, secondaryInfo }))
    rankings.push({ title: 'Destaques inovação', itens })
  }

  if (ranking.SERVICOS.length > 0) {
    const { SERVICOS } = ranking
    const secondaryInfo = 'Serviço'
    const itens = SERVICOS.map(name => ({ icon: servico, name, primaryInfo, secondaryInfo }))
    rankings.push({ title: 'Destaques serviços', itens })
  }

  if (ranking.COMPETITIVIDADE.length > 0) {
    const { COMPETITIVIDADE } = ranking
    const secondaryInfo = 'Competitividade'
    const itens = COMPETITIVIDADE.map(name => ({ icon: competitividade, name, primaryInfo, secondaryInfo }))
    rankings.push({ title: 'Destaques competitividade', itens })
  }


  return rankings;
}