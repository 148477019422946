import env from "react-dotenv";
import inovacao from "../assets/inovacao.svg";
import clipboard from "../assets/clipboard.png";
import handshake from "../assets/handshake.png";
const REGULATION_ADDRESS = "https://fornecedores.bibi.com/regulamento_desafios.pdf"
export const ETICS_GUIDE_ADDRESS = "https://calcadosbibi.vtexassets.com/arquivos/codigo_conduta.pdf"
export const SUSTENTABILITY_GUIDE_ADDRESS = "https://arquivos.bibi.com.br/MARKETING/sustentabilidade.pdf"
export const mockPath = '/'
export const mockPathDesafios = '/desafios/'

export const headerSupplier = [
  { text: 'HOME', path: `${mockPath}principal` },
  { text: 'ORDENS DE COMPRA', path: `${mockPath}ordens-de-compra`, role: 'OS' },
  {
    text: 'DESAFIOS', path: `${mockPathDesafios}todos-desafios`, hasMenus: true, role: 'DE', children: [
      { text: 'TODOS OS DESAFIOS', path: `${mockPathDesafios}todos-desafios` },
      { text: 'MEUS DESAFIOS', path: `${mockPathDesafios}meus-desafios` },
      { text: 'REGULAMENTO', path: env?.REACT_APP_REGULATION_ADDRESS || REGULATION_ADDRESS, open: true }
    ]
  },
  { text: 'RANKING', path: `${mockPath}ranking`, role: 'RA' },
  { text: 'ARQUIVOS', path: `${mockPath}arquivos`, role: 'ARQ' },
  { text: 'PERFIL', path: `${mockPath}perfil`, role: 'PE' },
  { text: 'SAIR', path: `${mockPath}sair` }
]

export const headerAdmin = [
  { text: 'HOME', path: `${mockPath}home` },
  { text: 'FORNECEDORES', path: `${mockPath}fornecedores`, role: 'FO' },
  { text: 'DESAFIOS', path: `${mockPath}principal` },
  { text: 'NOTÍCIAS', path: `${mockPath}news` },
  { text: 'ARQUIVOS', path: `${mockPath}arquivos`, role: 'ARQ' },
  {
    text: 'CADASTROS', hasMenus: true, children: [
      { text: 'ÁREAS', path: `${mockPath}areas`, role: 'AR' },
      { text: 'GRUPOS', path: `${mockPath}grupos`, role: 'GR' },
      { text: 'CATEGORIAS', path: `${mockPath}categorias`, role: 'CA' },
      { text: 'USUÁRIOS', path: `${mockPath}usuarios`, role: 'US' },
      { text: 'CONFIGURAÇÕES', path: `${mockPath}configuracoes` },
    ]
  },
  { text: 'SAIR', path: `${mockPath}sair` },
]

export const bibi = [
  'Bibi'
]

export const fornecedores = [
  'Fornecedores'
]


export const challengesMainStatus = [
  'Abertos',
  'Finalizados'
]

export const challengesMainStatusAdmin = [
  'Abertos',
  'Finalizados',
  'Fechados',
  'Novo'
]

export const filterKey = {
  'Abertos': 'Aberto',
  'Finalizados': 'Finalizado',
  'Inscrito': 'Inscrito',
  'Proposta enviada': 'Proposta enviada',
  'Proposta aceita': 'Proposta aceita',
  'Testes': 'Testes',
  'Implementado': 'Implementado',
  'Negado': 'Negado',
  'Fechados': 'Fechado'
}

export const myChallengesStatus = [
  'Inscrito',
  'Proposta enviada',
  'Proposta aceita',
  'Testes',
  'Implementado',
  'Negado'
]

export const textsMainPage = {
  homeTitle: 'Home',
  title: 'Desafios',
  subtitle: `Veja abaixo os desafios que estão buscando por soluções inovadoras.
    Eles podem ser a porta de entrada para você se tornar um parceiro da 
    Bibi ou fortalecer ainda mais nossa relação. 
    Aqui também estarão disponíveis nossos cases de sucesso.`
}

export const textsSupplierHomePage = {
  homeTitle: 'Home',
  title: 'Home',
  subtitle: ``
}

export const textsSupplierInvoiceOrderPage = {
  homeTitle: 'Ordens de compra',
  title: 'Ordens de compra',
  subtitle: ``
}

export const textsLoginPage = {
  title: 'Login',
  subtitle: 'Insira seus dados abaixo ou',
  link: 'cadastre-se',
  email: 'E-mail:',
  password: 'Senha:',
  confirmPassword: 'Confirmar senha:',
  enter: 'Entrar',
  send: 'Enviar',
  cancel: 'Cancelar',
  rescuePassword: 'Recuperar senha',
  newPassword: 'Nova senha',
  forgotPassword: 'Esqueci minha senha',
  returnLogin: 'Ir para login',
  returnLanding: 'Sobre o programa',
}

export const textsCadastroPage = {
  title: 'Cadastro',
  subtitle: 'Insira seus dados abaixo ou faça',
  link: 'login',
  subtitle2: `Evite utilizar e-mails de pessoas específicas dentro da empresa e/ou domínios como @gmail ou @hotmail, 
    a não ser que sejam a forma oficial de comunicação com a sua empresa.`,
  subtitle3: `Dentro da plataforma você poderá incluir os e-mails das pessoas que serão notificadas 
    a cada interação nos desafios que estarão participando.`,
  subtitle4: `Após enviar seus dados, a senha de acesso será enviada parao e-mail cadastrado.`,
  email: 'E-mail:',
  companyName: 'Nome da empresa:',
  agreeWith: 'Concordo com o',
  regulation: 'regulamento',
  ofProgram: 'do programa.',
  participate: 'Participar',
  registerSuccess: 'Cadastro feito com sucesso',
}

export const textsProfilePage = {
  emailNotify: '*Estes e-mails serão notificados quando a cada novo histórico.',
  add: 'Adicionar',
  validEmail: 'Digite um e-mail válido',
  contacts: 'Contatos',
  noContacts: 'Voce ainda não possui contatos salvos.',
  titleName: 'Nome',
  titleGroup: 'Grupo',
  titleBio: 'Bio',
}

export const textsFormChallenge = {
  titleName: 'Nome',
  titleCategory: 'Categoria',
  titleObjective: 'Objetivo',
  titleDetails: 'Detalhes',
  titleVideo: 'Vídeo',
  titleStatus: 'Status',
  titleSpotlight: 'Destaque',
  titleZones: 'Áreas',
  titleMaterial: 'Materiais Complementares'
}

export const textsMyChallenges = {
  title: 'Meus Desafios',
  subtitle: `Aqui estão os desafios que você já está participando,
    clique nele para acessar mais informações e o histórico
    de interações realizadas.`
}

export const textsTitles = {
  textTitleZone: `Áreas`,
  textTitleGroup: `Grupos`,
  textTitleCategory: `Categorias`,
  textTitleChallengeSubscriber: 'Proposta',
  textNewChallenge: 'Novo Desafio',
  textEditChallenge: 'Editar'
}

export const textsDescriptions = {
  textDescriptionZone: `São os setores internos que estarão relacionados com os desafios.`,
  textDescriptionGroup: `São agrupadores para serem vinculados aos fornecedores, para facilitar as notificações em massa.`,
  textDescriptionCategory: `São categorizações das tématicas que os desafios se propõem a resolver.`
}

export const textsFakeButtonStatus = {
  textChallengeSubscriber: 'Inscrito',
  textChallengeProposal: 'Proposta enviada'
}

export const textsTextAreaPlaceholder = {
  textChallengeSubscriber: `Descreva aqui sua solução com o máximo de detalhes possível.`,
  textChallengeProposal: 'Escreva aqui sua mensagem.',
  textFormChallenge: 'Explicação detalhada do desafio',
  textFormChallengeObjective: 'Objetivo do desafio',
  textBioFormProfille: 'Apresente a sua empresa. Em que área você pode contribuir com a Bibi?'
}

export const textsSubmitButtons = {
  textChallengeDetail: 'Tenho uma solução',
  textChallengeSubscriber: 'Enviar',
  textFormChallengeEdit: 'Editar',
  textChallengeProposal: 'Adicionar histórico ou mensagem',
  textChallengeAttachment: 'Salvar anexo(s)',
  textChallengeEditProposal: 'Salvar proposta',
  textFormChallenge: 'Salvar',
}

export const textsFooterDetail = {
  textChallengeDetail: `Após se inscrever você poderá descrever sua proposta de solução com o máximo de detalhes que tiver,
    além de incluir anexos que nos ajudem a entender melhor o seu projeto.`,
  textChallengeSubscriber: `Após enviar sua proposta de solução, fique atento na sua caixa de entrada que vamos entrar em contato dentro de alguns dias. Você poderá ver o histórico de interações acessando esta página em outro momento.`,
  textFormChallenge: `Após salvar os dados do novo desafio, ele ficará com o status “Fechado”. Enquanto isso você poderá revisar as informações. Quando estiver tudo pronto é só editar ele mudando o status para “Aberto” para ele ficar público.`
}

export const textsDropzone = {
  textMaterial: 'Clique para adicionar ou solte os arquivos aqui.',
  textImage: 'Solte a Imagem.',
  textBioProfileImage: `Solte aqui seu arquivo de apresentação `
}

export const textLandingPage2 = {
  premiacaoTitle: 'PROGRAMA DE AVALIAÇÃO',
  premiacaoText1: `Em 2010 buscando de melhorar o nível dos produtos e serviços da empresa foi criado o`,
  premiacaoTextProgram: `Programa de Avaliação de Fornecedores BIbi.`,
  premiacaoText2: `Estimulamos o desenvolvimento de nossos fornecedores e registramos aspectos qualitativos e quantitativos dos principais diferenciais competitivos do mercado:`,
  premiacaoText3: `Como parte desse programa, a Bibi realiza anualmente o `,
  premiacaoText4: `Encontro de Fornecedores.`,
  premiacaoText5: `Um evento que tem como objetivo alinhar as estratégias da Bibi com seus fornecedores e homenagear os que se destacaram no ano anterior.`,
  sustainabilityText1: `Além de desenvolver calçados pensando no desenvolvimento natural e saudável da criança, a Bibi se preocupa com o mundo em que vivemos. `,
  sustainabilityText2: `Aqui 100% dos resíduos são reciclados e reaproveitados, nada é descartado na natureza e toda a energia consumida vem de fontes renováveis. `,
  sustainabilityText3: `A Bibi é a marca de calçados infantis pioneira e recertificada pelo selo Diamante Origem Sustentável, uma certificação para as empresas brasileiras que incorporam medidas sustentáveis no processo produtivo.`
}

export const generalTexts = {
  hello: "Olá",
  since: "desde",
  yourPosition: "Sua posição",
  lookRanking: "Veja o ranking",
  goldPosition: "Fornecedor Ouro",
  silverPosition: "Fornecedor Prata",
  bronzePosition: "Fornecedor Bronze",
  partner: "Parceiro",
  pendingDocuments: "Você possui pendências em seus documentos",
  pendingInvoices: "Você possui ordens a serem confirmadas",
  noInvoiceMessage: "Quando você tiver ordens de compras, elas aparecerão aqui",
  noChallengesHomeSupplier: `Você ainda não está participando de nenhum dos nossos desafios, 
    clique abaixo para conhecer as oportunidades.`,
  noNews: 'Ainda não há novidades a serem mostradas aqui',
  logOutSuccess: 'Sessão encerrada com sucesso!',
  logOutFail: 'Houve um erro ao tentar sair. Tente novamente em instantes.',
  formDisable: 'Você deve preencher o formulário!',
  errorMessage: 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.',
  successMessage: 'Operação feita com sucesso!',
  notEmpty: 'Campos não podem ser vazios!',
  noItems: 'Voce ainda não possui itens salvos.',
  noInfo: 'Não há informações disponíveis aqui.',
  notEmptyImage: 'Imagem é um campo obrigatório.',
  homeInfoStatus: 'Interações / Status',
  homeInvoiceStatus: 'Ordens de compra / Status',
  homeNegotiationEvolution: 'Evolução das negociações',
  openChallenges: 'Desafios Abertos',
  challenges: 'Desafios',
  seeAllChallenges: 'Ver todos os desafios',
  newsBoard: 'Mural de novidades'
}

export const textsSlideTop = {
  partialText1: 'Oportunidades para',
  partialText2: 'negócios com a Bibi.',
  multiwords: [
    "CRIAR",
    "EXPANDIR",
    "FORTALECER",
    "CRIAR",
    "EXPANDIR",
    "FORTALECER",
    "CRIAR",
    "EXPANDIR",
    "FORTALECER",
    "CRIAR",
    "EXPANDIR",
    "FORTALECER",
    "CRIAR",
    "EXPANDIR",
    "FORTALECER",
  ],
  buttonText: 'Quero conhecer'
}

export const initialOpportunityObjects = [
  {
    image: clipboard, text: `Se inscrever no portal é o
    primeiro passo para iniciar 
    uma parceira conosco`, buttonText: 'Criar cadastro', path: `${mockPath}cadastrar`
  },
  {
    image: handshake, text: `Acesse o portal e acompanhe suas
    entregas, veja o ranking de
    fornecedores e muito mais.`, buttonText: 'Já sou parceiro', path: `${mockPath}login`
  },
  {
    image: inovacao, text: `Participe do programa de inovação
    com fornecedores da Bibi. Veja
    os desafio que estão abertos`, buttonText: 'Ver os desafios', path: `${mockPathDesafios}`
  },
]

