import { Container } from "./styles";

export function RankingCard({ item }) {
  const { icon, name, primaryInfo, secondaryInfo, isBreak } = item;

  return (
    <Container className={isBreak ? 'break' : ''}>
      <div className="circle">
        <img src={icon} alt="Icone card" />
      </div>
      <div className="info">
        <h2>{name}</h2>
        <span>{primaryInfo}</span>
        <span>{secondaryInfo}</span>
      </div>
    </Container>
  )
}