import api from "./api"

const route = 'configuracoes/';

export const SettingsService = {
  async getAll() {
    const response = await api.get(route)
    return response.data
  },

  async update(setting) {
    const response = await api.put(`${route}${setting.ID}`, { data: setting })
    return response.data
  },

  async getById(id) {
    const response = await api.get(`${route}${id}`)
    return response.data
  }
}