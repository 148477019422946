import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { generalTexts } from "../../utils/utils";

export default function CardProfileSupplier({ userState, nome = '', parceiroDesde = '', pendingDocuments = false }) {
  const padding = '14px 22px'
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: padding,
      '&:hover': {
        cursor: "pointer",
        boxShadow: '0px 0px 5px #FE5D00'
      }
    },
    countUp: {
      fontSize: '48px',
    },
    title: {
      color: "#000000",
      fontWeight: 'bold',
      textAlign: 'start',
      lineHeight: '40px'
    },
    media: {
      height: 140,
      display: 'flex'
    },
    expand: {
      margin: '0 0 0 auto'
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      //lineHeight: '1.8em',
      textAlign: 'start'
    },
    cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
    partner: {
      color: "#B7B7B7"
    },
    pendingDocuments: {
      color: "#FE5D00"
    },
    hello: {
      fontSize: 18
    }
  }));

  const classes = useStyles();
  const cardRef = createRef();
  // const [openDialog, setOpenDialog] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpenDialog(true)
  // };

  const getHeightCard = (name) => {
    let height = 150;

    if (name.length > 20) {
      height = 180;
    }

    if (pendingDocuments) {
      height = height + 10;
    }

    return {
      height: `${height}px`
    }
  }


  const getNameSize = (name) => {
    let fontSize = '36px';

    if (name.length > 20) {
      fontSize = '30px';
    }

    return {
      fontSize
    }
  }

  return (
    <Card ref={cardRef} className={classes.root} style={getHeightCard(nome)}>
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left">
          <Typography component="h1" variant="subtitle2" className={classes.hello}>
            {generalTexts.hello}
          </Typography>
        </Box>
        <Box textAlign="left">
          <Typography className={classes.title} component="h1" variant="subtitle1"
            style={getNameSize(nome)}
          >
            {nome}
          </Typography>
        </Box>
        <Box textAlign="left" display={"flex"}>
          <Typography className={classes.partner} component="p" variant="subtitle2">
            {generalTexts?.partner}{" "}{generalTexts.since}{":"}
          </Typography>
          &nbsp;
          <Typography className={classes.partner} component="p" variant="subtitle2">
            {parceiroDesde ? new Date(parceiroDesde).toLocaleDateString() : 'Sem data'}
          </Typography>
        </Box>
        {pendingDocuments ? (
          <Box textAlign="left" display={"flex"} alignItems={"center"}>
            <WarningIcon className={classes.pendingDocuments} />
            <Box pt={1} ml={0.5}>
              <Typography className={classes.pendingDocuments} component="h1" variant="subtitle2">
                {generalTexts.pendingDocuments}
              </Typography>
            </Box>
          </Box>
        ) : ('')
        }
      </CardContent>
    </Card>
  );
}