import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import InitialOpportunityCard from '../../../../components/InitialOpportunityCard';
import { initialOpportunityObjects } from '../../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'

  },
  title: {
    fontSize: 36,
    color: "#000000",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    margin: '3.5rem 0'
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 938px)': {
      display: 'block',
      textAlign: 'center',
      margin: 8
    },
  }

}));

export default function InitialOpportunity() {
  const classes = useStyles();

  return (
    <div id="oportunidades" className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1">
              Oportunidades
            </Typography>
          </Box>
        </Box>

        <Box className={classes.flex}>
          {initialOpportunityObjects.map((item, index) => (
            <InitialOpportunityCard key={`item-opportunity-${index}`} item={item} index={index} />
          ))}
        </Box>
      </Container>
    </div>
  );
}