import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { ChipFormChallenge, SelectChipFormChallenge } from './style';
import MenuItem from '@material-ui/core/MenuItem';
import { getAllModels } from '../../services/models';
import { raiseAlert } from '../Alert';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '8px',
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: '24px',
  },
}));

export default function SelectMultipleModel({urlParam = '', areas = [], setAreasIds ,handleChangeAreas = function(obj) {}}) {
    const classes = useStyles();
    const [models, setModels] = useState([]);
    const [names, setNames] = useState(areas);

    useEffect(() => {
        setNames(areas.map(item=> item?.NOME))
        setAreasIds(areas.map(item=> item?.ID))
    }, [areas])// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        async function getOptionsModels() {
            await getAllModels(urlParam).then(response => {
                setModels(response?.data)
            }).catch(async err => {
                raiseAlert(err.error)
            });
        }
        getOptionsModels()
    }, [urlParam])// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = async (event, child) => {
        const ul_list = document.getElementsByTagName("ul");
        let element;
        for(let el of ul_list){
           if(el.hasAttribute("role") && el.getAttribute("role") === "listbox"){ element = el }
        }
        
        if(event.target.value.length > 5) { 
            element.parentElement.remove();
            //if (menu_list) menu_list.setAttribute("style", "display: none;");
            raiseAlert("Escolha no máximo 5 áreas");
            return;
        }
        
        let remainElement = (event.target.value)?.filter(value=> (typeof value !== "undefined")).map(item => item )
            
        let ids_selected_add = []
        let areas_selected_add = []
        remainElement.forEach(el=>{
            for(var i = 0; i < models.length; i++){ 
                if (models[i]?.NOME === el) { 
                    ids_selected_add.push(models[i]?.ID);
                    areas_selected_add.push(models[i]);
                    break;}
                
            }
        })
           
        setAreasIds([...ids_selected_add])
        handleChangeAreas([...areas_selected_add])
        
    };

    const handleVerifyModel = modelSelect => {
        if(modelSelect === undefined) {
            return false;
        }
        return true;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    return (
        <Grid container spacing={2}>
           <Grid item xs={12}>
                <SelectChipFormChallenge
                    id="mutiple-chip"
                    multiple
                    value={names}
                    onChange={(e, c) => handleChange(e, c)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        handleVerifyModel(selected) ?
                            <div className={classes.chips}>
                                {selected.map((value, key) => (
                                    <ChipFormChallenge key={key} label={value} className={classes.chip} />
                                ))}
                            </div>
                        : ''
                    )}
                    MenuProps={MenuProps}
                    >
                        {models?.map(model => (
                            <MenuItem key={model?.ID} value={model?.NOME} id={model?.ID} >
                                {model?.NOME}
                            </MenuItem>
                        ))}
                </SelectChipFormChallenge>
           </Grid>
        </Grid>
    );
  }