import React from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { textsTitles } from '../../../utils/utils';
import FormChallenge from '../../../components/FormChallenge';


const useStyles = makeStyles((theme) => ({
    root:{
      flexGrow:1
    },
    container:{
      height: 'calc(100vh - 5em)',
      maxHeight: 'calc(100vh - 5em)',
      minHeight: 'calc(100vh - 5em)',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: 'white'
    },
    fakeBox: {
        height:'100%',
        width: '100%'
    },
    mainCards: {
        width: '70vw !important',
        maxWidth: '985px !important',
        margin: 'auto!important',
        position: 'relative',
        top: '-4rem',
        '@media(max-width: 425px)' : {
            top: '-18rem',
        }
    },
    title: {
        width: 'fit-content',
        fontSize:28,
        fontWeight: 600,
        color:"#FFFFFF",
        textTransform:'none',
        margin: '25px auto auto 0'
    },
    subtitle: {
        color:"#000000",
        margin: '2em auto auto 25px',
    },
    marginMobile: {
        '@media(max-width: 425px)' : {
            marginTop: '15em !important',
        }
    },
    orangeSet: {
        backgroundColor:"#FE5D00",
        //height:'25vh',
        height:'7em',
    }
}));


export default function RegisterChallenge() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container component="main" className={classes.container}>
                <Box className={classes.orangeSet} textAlign="center">
                    <Box className={classes.fakeBox}></Box> 
                        <Box className={`${classes.mainCards}`}>
                            <Box className={`${classes.marginMobile}`}>
                                <Box>
                                    <Typography className={classes.title} >
                                        {textsTitles.textNewChallenge}
                                    </Typography>
                                </Box>

                                <FormChallenge />
                            </Box>
                    </Box>
                </Box>
            </Container> 
        </div>
      );
    
}