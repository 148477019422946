import { raiseAlert } from '../components/Alert';
import api from './api';

export const getSupplierData = async () => {
  return await api.get(`fornecedores/count`);
}

export const getDesafiosDashData = async () => {
  return await api.get(`desafios/propostas/status/count`).then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  }).catch(({ error }) => raiseAlert(error));
} 