/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import clsx from 'clsx';
import { useContext, useState } from "react";
import CardRankingSupplier from "../../../components/CardRankingSupplier";
import { PodiumContainer } from "../../../components/PodiumContainer";
import { UserContext } from "../../../providers/UserProvider";

import useStyles, { ButtonsContainer } from "./styles"

import { useEffect } from "react";
import { getFornecedor } from "../../../services/fornecedores";
import { getRanking } from "../../../services/desafios";
import { getFormattedRanking } from "./formattedRanking";
import { useLoading } from '../../../providers/LoadingProvider';
import { getSupplierData } from "../../../services/home";
import Swal from "sweetalert2";
import { isBibiUser } from "../../../utils/roles";

const swalParams = {
  icon: 'error',
  title: 'Ops...',
  text: 'Ocorreu um erro ao buscar o ranking. Tente novamente mais tarde!',
}

export default function Ranking() {
  const classes = useStyles();
  const { userState } = useContext(UserContext);
  const labels = ['Qualidade', 'Inovação', 'Pós-venda', 'Flexibilidade'];
  const labels2 = ['Velocidade', 'Sustentabilidade', 'Confiabilidade', 'Competitividade'];
  const [ranking, setRanking] = useState(null);
  const [rankings, setRankings] = useState([]);
  const matches = useMediaQuery('(max-width:1366px)');
  const { setLoading } = useLoading();
  const [countSupplier, setCountSupplier] = useState(0)

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const supplierData = await getFornecedor(userState?.ID);
        const fRanking = await getRanking();
        const count = await getSupplierData()
        const formattedRankings = getFormattedRanking(fRanking);

        setRankings(formattedRankings)
        if (supplierData?.data[0]?.POS_RANKING) {
          setRanking(supplierData.data[0].POS_RANKING)
        }
        setCountSupplier(count.data.FORNECEDORES)
      } catch (e) {
        Swal.fire(swalParams)
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [])

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box className={clsx(`orangeDiv ${classes.orangeSet} `, {
          'fit-div': isBibiUser(userState?.tipo)
        })} textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              Ranking
            </Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: '30px' }}>
          <Box className={`header-div mob`}>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={'auto'} md={3} lg={3}>
                <CardRankingSupplier userState={userState} withoutCard={true} ranking={ranking} totalSuppliers={countSupplier} />
              </Grid>
              <Grid item xs={12} sm={'auto'} md={9} lg={9}>
                <p style={{
                  textAlign: 'left',
                  fontWeight: '400',
                  lineHeight: '30px', fontSize: '18px'
                }}>A Bibi estimula o desenvolvimento dos fornecedores. Registramos aspectos qualitativos e quantitativos dos principais diferenciais competitivos do mercado:</p>
              </Grid>
            </Grid>
            <Grid className={classes.buttons} container spacing={4} justify="flex-start" alignItems="flex-start" style={{ marginTop: '50px' }}>
              <ButtonsContainer>
                {labels.map((label, index) => {
                  return (
                    <span key={`${label}-${index}`} className={classes.label} style={matches ? { width: '160px' } : {}}>{label}</span>
                  )
                })}
              </ButtonsContainer>
            </Grid>
            <Grid className={classes.buttons} container spacing={4} justify="flex-end" alignItems="flex-end" style={{ marginTop: '30px' }}>
              <ButtonsContainer>
                {labels2.map((label, index) => {
                  return (
                    <span key={`${label}-${index}`} className={classes.label} style={matches ? { width: '160px' } : {}}>{label}</span>
                  )
                })}
              </ButtonsContainer>
            </Grid>
          </Box>
        </Box>
        {
          rankings.map(({ title, itens }) => {
            return (
              <Box key={title} style={{ marginTop: '45px' }}>
                <Box className={`header-div mob`}>
                  <PodiumContainer title={title} data={itens} />
                </Box>
              </Box>
            )
          })
        }

        <Box style={{ marginTop: '65px' }}>
          {/* <Box className={`header-div mob`}>
            <PodiumContainer title="Destaques" data={highlights} />
            <PodiumContainer title="Destaques" data={highlights} />
            <PodiumContainer title="Destaques" data={highlights} />
            <PodiumContainer title="Destaques" data={highlights} />
          </Box> */}
        </Box>
      </Container>
    </div >
  )
}