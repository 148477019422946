import { Button } from "@material-ui/core";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
    font-size: 18px;
    min-height: 6.5vh;
    width: 100%;
    padding: 6px 12px;
    margin-left: 0;
    margin-right: 25px;
    font-weight: 500;
    color: #ffffff;
    background-color:  ${props => props.disabled ? '#9e9e9e' : '#FE5D00'};
    border: 0;
    text-transform: none;

    &:hover {
        color: #ffffff;
        background-color:  ${props => props.disabled ? '#9e9e9e' : '#FE5D00'};
        border: 0;
    }
`