// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ranking: {
    title: 'Ranking',
    content: 'Esse quadro mostra sua posição no ranking de fornecedores de acordo com sua avaliação junto ao Programa de Avaliação de Fornecedores Bibi.'
  },
  evolutionNegotiation: {
    title: 'Evolução das negociações',
    content: 'Este gráfico mostra a sua evolução de negociações com a Bibi, agrupando os valores recebidos por mês nos últimos 3 anos.'
  },
  order: {
    title: 'Ordem de compra',
    content: ' Esse Gráfico lhe mostra a quantidade de Ordens de Compra de acordo com o status delas.'
  },
  orderValue: {
    title: 'Valores',
    content: 'O Campo superior informa o valor financeiro das Ordens de Compras por faturar à Bibi. O Campo inferior informa o valor Financeiro dos Títulos/NFs emitido contra a Bibi que você tem por receber.'
  },
  budget: {
    title: 'Compras e prazo de pagamento do mês vigente',
    content: 'Esse gráfico mostra orçamento por agrupamento e o realizado até momento.'
  },
  stock: {
    title: 'Estoque de matéria prima',
    content: 'Esse gráfico mostra o valor financeiro de estoque matéria prima e o valor de estoque obsoleto (180 dias)'
  }
}