import {
  Card,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery
} from '@material-ui/core';
import * as React from 'react';


export default function OrderItensModal({ open = false, handleClose, items = [], order }) {
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const matches = useMediaQuery('(max-width:950px)');



  const circleColor = (status) => {
    const colors = {
      'Tudo certo': '#06C8A9',
      'Entrega parcial': '#FFCC2F',
      'Pendente': '#FE5D00',
    }
    return colors[status]
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={{ width: '95%', padding: `15px 0 ${matches ? '2rem' : '0'} 1rem`, minHeight: '60%', position: 'relative' }}>
          <CardContent >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} style={{ fontWeight: 'bold', fontSize: matches ? '1.2rem' : '1.5rem' }}> Ordem de compra {order}</TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>Código</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem', minWidth: matches ? '200px' : '', maxWidth: matches ? '220px' : '' }}>Descrição</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>Quantidade</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>Saldo</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>Previsão Entrega</TableCell>
                    <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem', minWidth: matches ? '150px' : '' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.slice(page * 5, page * 5 + 5).map((item, index) => {
                    return (
                      <TableRow key={`item-${index}`}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>{item.CODIGO_ITEM}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>{item.DESCRICAO}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>{item.QUANTIDADE}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>{item.SALDO}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>{(new Date(item.DATA_PREV_ENTREGA)).toLocaleDateString()}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: matches ? '.8rem' : '1rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ height: '20px', width: '20px', background: circleColor(item.STATUS), borderRadius: '50%', display: 'block' }}></div>
                            <span style={{ marginLeft: '10px' }}> {item.STATUS} </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <TablePagination
            style={{ position: 'absolute', bottom: '0', right: '0' }}
            component="div"
            labelRowsPerPage=''
            rowsPerPageOptions={[]}
            count={items.length}
            rowsPerPage={5}
            page={page}
            onChangePage={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Card>
      </Modal>
    </div >
  );
}