import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PermissionService } from '../../services/permission.service';

const titleStyles = {
  color: '#000000',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  textAlign: 'start',
};

const toastConfig = {
  position: 'bottom-right',
  hideProgressBar: true,
};

const useStyles = makeStyles((theme) => ({
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
}));

export function ModalPermissions({
  openDialog = false,
  setOpenDialog,
  roleType,
  user = {},
}) {
  const classes = useStyles();
  const [permissions, setPermissions] = useState([]);

  async function getPermissions() {
    const all = await PermissionService.all(roleType);
    const userPermissions = await PermissionService.getByUser(user.email);
    const permissionsWithChecked = all.map((item) => ({
      ...item,
      checked: userPermissions.some((p) => p.PERMISSAO === item.ID),
    }));
    setPermissions(permissionsWithChecked);
  }

  async function handleChangeCheckbox(id) {
    const isChecked = permissions.find((p) => p.ID === id).checked;

    if (isChecked) {
      await removePermission(id);
    } else {
      await addPermission(id);
    }

    const mappedPermissions = permissions.map((permission) => ({
      ...permission,
      checked: permission.ID === id ? !permission.checked : permission.checked,
    }));
    setPermissions(mappedPermissions);
  }

  async function addPermission(permission) {
    try {
      await PermissionService.add(user.email, permission);
      toast('Permissão liberada!', toastConfig);
    } catch (e) {
      toast('Erro ao liberar permissão. Tente novamente!', toastConfig);
    }
  }

  async function removePermission(permission) {
    try {
      await PermissionService.remove(permission, user.email);
      toast('Permissão removida!', toastConfig);
    } catch (e) {
      toast('Erro ao remover permissão. Tente novamente!', toastConfig);
    }
  }

  useEffect(() => {
    if (user?.email?.length > 0) getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <Typography style={titleStyles} component="h1" variant="subtitle1">
          Permissões - {user?.name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              {permissions.map(({ ID, DESCRICAO, checked }) => (
                <TableRow key={ID}>
                  <TableCell>
                    <Checkbox
                      classes={{
                        root: classes.checkColor,
                        checked: classes.checkColor,
                      }}
                      value={checked}
                      checked={checked}
                      onChange={() => handleChangeCheckbox(ID)}
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleChangeCheckbox(ID)}
                    >
                      {DESCRICAO}
                    </span>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          <Typography component="h1" variant="subtitle1">
            Fechar
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
