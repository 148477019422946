import api from './api';


export const sessionSignIn = async (user) => {
    
    return await api.post('usuario/login', user,).then(response => {
        
        if(response.status === 200){
            window.localStorage.setItem('isLogged',true)
        }
        window.localStorage.removeItem('signing')
        return response
    }).catch(err =>{
        window.localStorage.removeItem('signing')
        return { status:400, error: err.response.data.message}
    });
} 

export const sessionSignOut = async (user,uid) => {
    return await api.post(`/session/${uid}/signOut`,user).then(async response => {
        if(response.status === 200){
            window.localStorage.setItem('isLogged',false)
           
        }
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
    
} 

export const rescuePassword = async (email) => {
    return await api.post(`usuario/recuperar?EMAIL=${email}`).then(async response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
    
} 