import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  finished: {
    border: '1px solid #FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  finishedText: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  selectedText: {
    textTransform: 'capitalize',
    color: theme.palette.default.main
  },
  title: {
    fontSize: 36,
    color: "#FFFFFF",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    alignItems: "baseline",
    color: "#FFFFFF"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    margin: '8px 0 8px',
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 500,
    height: 'fit-content',
    maxHeight: '3em',
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  textAdaptActive: {
    margin: '8px 0 8px',
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 500,
    height: 'fit-content',
    maxHeight: '3em',
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  textButton: {
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 500,

  },
  orangeSet: {
    backgroundColor: "#FE5D00",
    padding: '0rem 0rem 5rem 0rem'
  },
  whiteSet: {
    backgroundColor: "#FFFFFF",
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem'
  }
}));

export default useStyles;