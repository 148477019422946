
const TypographySystem = {
  fontFamily: [
    'Nunito',
    'Regular',
  ].join(','),
  h2: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#000000'
  },
  body1: {
    fontSize: 16,
    fontWeight: 600,//SemiBold
    color: '#FE5D00'
  },
  body2: {
    fontSize: 16,
    fontWeight: 600,
    color: '#000000'
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    color: '#FE5D00',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    color: '#000000',
  }
}

export default TypographySystem;