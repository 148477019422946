import React, { useState, useEffect } from "react";
import { DesafiosComponent, Title, ModalVideo, ModalContent, Video } from "./styles";

// import imagemUm from '../../assets/imagemUm.jpg';
// import imagemDois from '../../assets/imagemDois.jpeg';
// import imagemTres from '../../assets/imagemTres.jpeg';
// import imagemQuatro from '../../assets/imagemQuatro.jpg';
// import imagemCinco from '../../assets/imagemCinco.jpeg';

import { getDesafiosDestaque } from "../../../../services/desafios";
import { TitleToSmooth } from "../../../../styles";
import { raiseAlert } from "../../../../components/Alert";
import { mockPath } from "../../../../utils/utils";

export default function Desafios({onAddDesafio}) {
  
  const[url, setUrl] = useState('');
  const[desafios, setDesafios] = useState([]);

  useEffect(() => {
    async function loadDestaques(){
      await getDesafiosDestaque().then(async resp=>{
        if(resp?.status === 200){
          setDesafios(resp.data);
        }else{
          raiseAlert(resp.error)
        }
        }).catch(async err => {
          raiseAlert(err.error)
        });
    }
    loadDestaques();
  }, []);

  
  
  const baseUrl = 'https://www.youtube.com/embed/'

  function ButtonClick({URL_VIDEO, NOME}) {
    const modal = document.getElementById("myModalVideo");
    modal.style.display = "block";
    setUrl(baseUrl + URL_VIDEO);
    localStorage.setItem("nomeDesafio", NOME);
  }
  
  window.onclick = function (event) {
    const modal = document.getElementById("myModalVideo");
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };


  function handleButtonEmail(){
    window.location.replace(`${mockPath}cadastrar`)
  }

  return (
    <>
    <DesafiosComponent>
      <TitleToSmooth id="desafios">DESAFIOS</TitleToSmooth>
      <Title id="desafios">DESAFIOS</Title>

      <section id="timeline">
        {desafios.map((desafio) => (
          <div className="tl-item" key={desafio.ID}>
            <div
              className="tl-bg"
              style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${desafio.IMAGEM})` }}
            ></div>

            <div className="tl-year">
              <p className="f2 heading--sanSerif text-center">{desafio.NOME}</p>
            </div>

            <div className="tl-content">
              <h1 className="text-center">{desafio.NOME_CATEGORIA}</h1>
              <p className="text-center">{desafio.OBJETIVO}</p>
              <button className="tl-cta" onClick={() => ButtonClick(desafio)}>
                Saber mais
              </button>
            </div>
          </div>
        ))}
      </section>
    </DesafiosComponent>
    <ModalVideo id="myModalVideo">
      <ModalContent>
        <Video class="video-container">
          <iframe title="Bibi Institucional" src={url} frameborder="0" allow="accelerometer; encrypted-media; picture-in-picture" allowfullscreen></iframe>
        </Video>
        <button onClick={() => handleButtonEmail()}>Quero Participar</button>
      </ModalContent>
    </ModalVideo>
    </>
  );
}
