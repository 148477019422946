import React from "react";
import {
  Container,
  Video,
  LogoBibi,
  EmailSpan,
  Copyright
} from "./styles";
import logoBibi from "../../../../assets/logo.svg";
import { TitleToSmooth } from "../../../../styles";

import { mockPath } from "../../../../utils/utils";

export default function Faq() {

  function ButtonClick() {
    
    window.location.replace(`${mockPath}cadastrar`)
  }

  function toggleAccordion(id) {
    var divOne = document.getElementById(id);
    divOne.classList.toggle("active");
    divOne.nextElementSibling.classList.toggle("active");
  }

  return (
    <Container>
      <TitleToSmooth id="faq">FAQ</TitleToSmooth>
      <h1>FAQ</h1>
      <link
        rel="stylesheet"
        href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"
      />

      <div className="container">
        <h2>Perguntas Frequentes</h2>

        <div className="accordion">
          <div
            className="accordion-item"
            onClick={() => toggleAccordion("divOne")}
          >
            <a href id="divOne">Posso inscrever uma idéia?</a>
            <div className="content">
              <p>
                Não, a Bibi poderá contratar a empresa como forcenedora ou fazer
                uma parceria comercial para ofertar a solução para os seus
                clientes.
              </p>
            </div>
          </div>
          <div
            className="accordion-item"
            onClick={() => toggleAccordion("divTwo")}
          >
            <a href id="divTwo">Todos inscritos participarão de todas as etapas?</a>
            <div className="content">
              <p>Não, somente aquelas que forem selecionadas.</p>
            </div>
          </div>
          <div
            className="accordion-item"
            onClick={() => toggleAccordion("divThree")}
          >
            <a href id="divThree">É um programa de curta duração?</a>
            <div className="content">
              <p>
                É um programa contínuo e os desafios tem seus respectivos prazos
                definidos.
              </p>
            </div>
          </div>
          <div
            className="accordion-item"
            onClick={() => toggleAccordion("divFour")}
          >
            <a href id="divFour">É um programa de investimento em equity?</a>
            <div className="content">
              <p>
                O programa objetiva a conexão com empresas, com os fins de (I)
                Parceira (oferta de serviços ou produtos conjuntamente) ou (II)
                Fornrcedor (fornecimento de solução)..
              </p>
            </div>
          </div>
        </div>
      </div>

      <script src="https://code.jquery.com/jquery-3.2.1.min.js"></script>
      <script src="function.js"></script>

      <h1>Ainda há dúvidas?</h1>
      <h1>
        Nos envie um e-mail:{" "}
        <EmailSpan href="mailto:inovacao@bibi.com.br?subject=Ninho%20-%20Fornecedores%3A%20D%C3%BAvida%20via%20portal">
          inovacao@bibi.com.br
        </EmailSpan>
      </h1>
      <button onClick={() => ButtonClick()}>Quero Participar</button>
      <span id="sobre"></span>
      <br />
      <br />

      <Video class="video-container">
        <iframe title="Bibi Institucional" src="https://www.youtube.com/embed/KGp3ViK8-5Q" frameborder="0" allow="accelerometer; encrypted-media; picture-in-picture" allowfullscreen></iframe>
      </Video>
      <LogoBibi src={logoBibi} alt={"logoBibi"} id="sobre" />

      <Copyright>
        Copyright 2022. Todos os Direitos Reservados.
        Calçados Bibi LTDA | RS 239 Km 28,4 nº 3600 | CEP: 95630-000 | Parobé - RS Brasil
      </Copyright>
    </Container>
  );
}
