import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: rgba(240, 240, 240, 0.8);
  z-index: 1000000;
  cursor: pointer;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
`;