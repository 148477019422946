import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  label: {
    marginBottom: '2rem',
    display: 'block',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  labelButtonIcon: {
    height: '1.25rem',
    marginRight: theme.spacing(4),
    textTransform: 'none',
  },
  textInfo: {
    fontSize: 14,
    color: 'black',
  },
  zIndexFeatured: {
    zIndex: '100',
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  tittleContact: {
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    height: '7em',
    '@media(maxWidth: 780px)': {
      marginTop: '3.3rem',
      height: '7em',
    },
  },
  paddingRight: {
    paddingRight: '10px',
  },
  caractersSize: {
    height: 'fit-content',
    marginRight: 0,
    marginLeft: 'auto',
  },
  buttonSave: {
    width: '100%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
  iconButton: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: '#e0e0e0',
    padding: '16px',
  },
  iconCont: {
    position: 'absolute',
    top: 0,
    display: 'none',
  },
  iconDelete: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',
  },
  documentContainer: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90px ',
  },
  documentDescription: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
  },
  documentIcon: {
    marginTop: '14px',
    fontSize: '50px',
  }
}));