import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { useMediaQuery, Box } from '@material-ui/core';
import clsx from 'clsx';
import CardMain from '../CardMain';
import { textsLoginPage } from '../../utils/utils';

export default function CardMainGroup({load,requesting}) {
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow:1
        },
        title:{
          fontSize:'36px',
          color:"#000000",
          textTransform:'capitalize',
          letterSpacing: '-2.05px',
        },
        subtitle:{
          color:"#6C6C6C",
          letterSpacing: '-0.84px',
        },
        preloaderCard: {
          height:'15em',
          width:'18em',
          borderRadius:5,
          marginBottom:8,
          objectFit: 'cover',
          //backgroundColor:'#dad1d1b0!important'
        },
      preloaderMobile:{
        width:'unset',
      }
    }));

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');

    return (
      <Grid container spacing={4} alignItems="flex-start">
      { requesting ? (
        [1,2,3,4,5,6].map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={`preloader_${index}`}>
            <Skeleton animation="wave" className={clsx(classes.preloaderCard,{
              [classes.preloaderMobile] : matches,
              'preloaderCard': true
            })} />
          </Grid>
          ))
        ):
        (
          load?.length > 0 ? (
            load.map((item, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={`post-${index}`}>
                <CardMain item={item} />
              </Grid>
            ))
          ):(
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Box pt={'6em'} textAlign="center">
              <Typography className={classes.title} component="h1" variant="subtitle1">
                {textsLoginPage.fake1 || 'Oops..'}
              </Typography>
              <Box className='box-ops' >
                <Typography className={classes.subtitle} variant="subtitle2">
                  {textsLoginPage.fake1 || "Não encontramos nenhum desafio neste status"}
                </Typography>
              </Box>
             </Box>
          </Grid>
          )
        )  
      }
    </Grid> 
    );
}