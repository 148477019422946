/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logoBibi from "../../../../assets/logo.svg";
import { NavHeader } from "./styles";
import { Button, Typography } from "@material-ui/core";
import { mockPath, mockPathDesafios } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";

export default function Header(redirectBack = false) {
  const history = useHistory();

  async function goToAnchor(index) {
    let container = document.querySelector(index);

    if (!container) {
      history.push('/')
      await new Promise((resolve) => setTimeout(() => resolve(), 1000))
      container = document.querySelector(index);
    }

    container.scrollIntoView({
      behavior: "smooth",
      block: 'start'
    });
    toggleMenu();
  }

  function handlePath(anchorInput) {
    let anchor = anchorInput
    if (redirectBack) {
      window.localStorage.setItem('anchor', anchorInput)
    }

    goToAnchor(`${anchor}`)
  }


  function toggleMenu() {
    const menu = document.querySelector(".menu-toggle");
    const nav = document.querySelector(".site-nav");
    nav.classList.toggle("site-nav-open");
    menu.classList.toggle("open");
  }

  return (
    <NavHeader className="header-standand">
      <div className="container">
        <div className="logo-div">
          <img className="logo" src={logoBibi} alt="logo" height={40} width={70} />
        </div>

        <nav className="site-nav">
          <ul>
            <li>
              <a onClick={() => handlePath("#oportunidades")}>OPORTUNIDADES</a>
            </li>
            <li>
              <a onClick={() => handlePath("#premiacao")}>AVALIAÇÃO FORNECEDORES</a>
            </li>
            <li>
              <a onClick={() => window.location.replace(`${mockPathDesafios}`)}>DESAFIOS INOVAÇÃO</a>
            </li>
            <li>
              <a onClick={() => handlePath("#sustentabilidade")}>SUSTENTABILIDADE</a>
            </li>
            <li>
              <a onClick={() => handlePath("#sobre")}>SOBRE</a>
            </li>
            <li>
              <Button color="primary" onClick={() => {
                window.localStorage.removeItem('anchor');
                window.location.replace(`${mockPath}login`)
              }}
                className='text-adapt'
                disableTouchRipple >
                <Typography className={'fake-a-tag'} variant="subtitle1">
                  LOGIN
                </Typography>

              </Button>

            </li>
          </ul>
        </nav>

        <div className="menu-toggle" onClick={() => toggleMenu()}>
          <div className="hamburger"></div>
        </div>
      </div>
    </NavHeader>
  );
}
