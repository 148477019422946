import React from 'react';

import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import { FakeButton } from '../FakeButton/style';

export default function ListFakeButtons({modelsArray = []}) {

    return (
        <Box mt={3}>   
            <Grid container>
                { modelsArray?.map((model, index) => (
                <Box m={'auto 10px'} key={`areas_${index}`}>
                    { index === modelsArray.length ?
                    <FakeButton>
                        <label className={`lastFakeButton`}>{model?.NOME}</label>
                    </FakeButton> : 
                    <FakeButton>
                        <label>{model?.NOME}</label>
                    </FakeButton> }
                </Box>
            ))}
            </Grid>
        </Box>
    )


}