import api from "./api";

const route = '/fornecedores/pastas';

export const FolderService = {
  async getBySupplier(supplier) {
    const folders = await api.get(`${route}?supplier=${supplier}`)
    return folders.data
  },

  async create(PASTA, FORNECEDOR, NOME = 'Nova pasta') {
    let params = { FORNECEDOR, NOME }
    if (PASTA) {
      params = { PASTA, ...params }
    }

    const folders = await api.post(`${route}`, params)
    return folders.data
  },

  async edit(ID, NOME) {
    return await api.put(`${route}/${ID}`, { NOME })
  },

  async move(ID, PASTA) {
    return await api.put(`${route}/${ID}`, { PASTA })
  },

  async delete(ID) {
    return await api.delete(`${route}/${ID}?type=BA`)
  },

  async createFile(NOME, CAMINHO, PASTA, TIPO, FORNECEDOR) {
    const folders = await api.post(`${route}/documentos`, {
      NOME, CAMINHO, PASTA, TIPO, FORNECEDOR
    })
    return folders.data
  },

  async getFilesByFolder(folder) {
    const files = await api.get(`${route}/documentos?folder=${folder}`)
    return files.data;
  },

  async editFile(ID, NOME) {
    return await api.put(`${route}/documentos/${ID}`, { NOME })
  },

  async moveFile(ID, PASTA) {
    return await api.put(`${route}/documentos/${ID}`, { PASTA })
  },

  async deleteFile(ID) {
    return await api.delete(`${route}/documentos/${ID}?type=BA`)
  },

}