import api from './api';


export const getAllContatos = async (id) => {
    return await api.get(`fornecedores/contatos?FORNECEDOR=${id}`).then(response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
} 

export const addContato = async (obj) => {
    
    return await api.post(`fornecedores/contatos`,obj).then(response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
} 

export const deleteContato = async (id) => {
    
    return await api.delete(`fornecedores/contatos?ID=${id}`).then(response => {
        return response
    }).catch(err =>{
        return { status:400, error: err.response.data.message}
    });
} 
