import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from '../CustomButton';
import { Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { mockPathDesafios } from '../../utils/utils';

export default function CustomButtonGroup({ load, selected, setSelected, buttonOnly }) {

    const useStyles = makeStyles(theme => ({
        root: {
            //flexGrow: 1,
            width: '100%',
            display: 'flex'
        },
        finished: {
            border: '1px solid #FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        },
        finishedText: {
            textTransform: 'capitalize',
            color: theme.palette.secondary.main
        },
        selected: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            }
        },
        selectedText: {
            textTransform: 'capitalize',
            color: theme.palette.default.main
        },
        fitButton: {
            width: 'auto',
            maxWidth: 'fit-content',
            minWidth: 'fit-content'
        },

    }));

    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="center" alignItems="flex-start">
                {load.map((item, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3}
                        className={classes.fitButton}
                        key={`item-customize-button-${index}`}>
                        {(item === 'Novo') ? (
                            <Box /*id="new-button" mt={2} mb={2} */ display="flex" justifyContent="center"
                                onClick={() => { history.push(`${mockPathDesafios}novo-desafio`) }}>
                                <hr id="vr-main" />
                                <CustomButton
                                    first={true || index === 0}
                                    last={true || (load.length - 1) === index}
                                    text={item}
                                    active={true}
                                    buttonOnly={true}
                                />
                            </Box>
                        ) : (
                            <CustomButton key={`customize-button-${index}`}
                                first={true || index === 0}
                                last={true || (load.length - 1) === index}
                                text={item}
                                active={item === selected}
                                setSelected={setSelected}
                                buttonOnly={buttonOnly}
                            />

                        )}

                    </Grid>
                ))}
            </Grid>

        </div>
    );
}