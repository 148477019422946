import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import AssignmentIcon from '@material-ui/icons/Assignment';
import fazerBem from '../../../../assets/fazerBemImage.png';
import { Video } from './styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  title: {
    fontSize: 36,
    color: "#000000",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    margin: '3.5rem'
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),
  },
  purposeBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: 380,
    backgroundColor: '#C6C7C2',
    '@media(max-width: 938px)': {
      display: 'block',
      textAlign: 'center'
    },
  },
  imagePurpose: {
    maxWidth: '350px',
    width: '100%',
  },
  textAdapt2: {
    height: 70,
    width: 280,
    padding: '40px 10px 40px 24px',
    margin: '8px 0 8px',
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  textButton: {
    textTransform: 'none',
    backgroundColor: 'inherit',
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '33px',
  },
  textEtics: {
    fontWeight: 600
  },
  pos: {
    position: 'relative',
    bottom: '-120px',
    left: '8%',
    width: 'auto',
    '@media(max-width: 938px)': {
      display: 'flex',
      margin: 'auto',
      left: 'unset',
      bottom: 'unset'
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fe5d007a',
    }
  },
  boxImage: {
    //width:'45%',
    marginRight: '5%',
    '@media(max-width: 938px)': {
      width: '100%',
      marginRight: '0%',
    }
  },
  boxText: {
    //width:'50%',
    marginLeft: '5%',
    '@media(max-width: 938px)': {
      width: '100%',
      marginLeft: '0%',
      marginTop: '-50px',
      paddingBottom: '1rem'
    }
  },
  report: {
    marginLeft: 0,
    marginRight: 12,
    fontSize: '35px!important'
  },
  report2: {
    marginLeft: 0,
    marginRight: 12,
    fontSize: '45px!important'
  }

}));

export default function Sobre() {
  const classes = useStyles();

  return (
    <div id={'sobre'} className={classes.root}>
      <Container component="main" className={classes.container}>
        <Video className="video-container">
          <iframe title="Bibi Institucional" src="https://www.youtube.com/embed/Pl4YIObCVgc" frameBorder="0" allow="accelerometer; encrypted-media; picture-in-picture" allowFullScreen />
        </Video>
        <Box className={classes.purposeBackground} >
          <Box className={classes.boxImage}>
            <img className={classes.imagePurpose} src={fazerBem} alt="fazer o bem" />
          </Box>
          <Box className={classes.boxText}>
            <Button color="primary" className={`${classes.textAdapt2}`}
              endIcon={<AssignmentIcon className={classes.report2} />}
              onClick={() => {
                window.open(`${window.location.origin}/codigo-de-conduta.pdf`, '_blank')
              }}
            >
              <Box style={{ display: 'table' }}>
                <Typography className={classes.textButton} component='p'>
                  Consulte nosso código
                  <br />
                  <Typography className={`${classes.textButton} ${classes.textEtics}`} component='b'> de Conduta e Ética</Typography>
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

      </Container>
    </div>
  );
}