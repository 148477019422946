import styled from "styled-components";

export const ProgramaDiv = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

export const ProgramTitle = styled.div`
  h1 {
    font-family: Nunito;
    text-align: center;
    margin: 80px;
  }
`;

export const PartnersDiv = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 10%;
  margin: auto;
  @media (max-width: 1186px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DivTeste = styled.div`
  display: flex;
  @media (max-width: 1186px) {
    /* flex-direction: column; */
  }
`;

export const BodyProgram = styled.body`
  font-family: Nunito;
  font-size: 18px;
  line-height: 2em;
  min-height: auto!important;
  @media (max-width: 1186px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const PartnersImg = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const ImagePartners = styled.img`
  margin-left: 10px;
  height: 80px;
`;

export const Retangulo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (min-width: 1350px) {
  }
`;

export const ImgMenina = styled.img`
  max-width: 400px;
  max-height: 400px;
`;
