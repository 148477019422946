import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import Arrow from '../../assets/arrow.svg'


export default function CardOrderIndicators({ hasData = true, link }) {
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: "pointer",
        boxShadow: '0px 0px 5px #FE5D00'
      }
    },
    cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
    boxCenter: {
      padding: '0 1rem 1rem 1rem',
    },
    noHasData: {
      fontWeight: 600,
      fontSize: '14px',
      color: '#B7B7B7',
      textAlign: 'center'
    }
  }));

  const classes = useStyles();
  const cardRef = createRef();


  return (
    <a href={link} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
      <Card ref={cardRef} className={classes.root}>
        <CardContent className={classes.cardPadding} padding={0}>
          <Box textAlign="left" display={"flex"} alignItems={"center"} justifyContent="center" className={classes.boxCenter}>
            <Typography component="h1" variant="body2" className={classes.noHasData}>
              Acesso as informações de estoques e carteira de negócios
            </Typography>
          </Box>
          <img src={Arrow} alt="arrow right" style={{
            width: '50px'
          }} />
        </CardContent>
      </Card>
    </a>
  );
}