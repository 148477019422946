import Swal from "sweetalert2";
import { generalTexts, mockPath } from "../../utils/utils";
import { setAuthToken } from "../../services/token";
import Dexie from "dexie";


export const raiseAlert = async (errorMessage) => {
    //includes é case sensitive
    if (errorMessage && errorMessage.includes('acesso expirou')) {
        window.localStorage.setItem('isLogged', false)
        Swal.fire({
            icon: 'error',
            title: 'Ops...',
            text: errorMessage || generalTexts.errorMessage,
            allowOutsideClick: false,
            confirmButtonText: 'Ir para login',
        }).then((result) => {
            if (result.isConfirmed) {
                setAuthToken(null)
                Dexie.delete('desafiosDB').then(r => {
                    window.location.replace(`${mockPath}login`)
                }).catch(() => {
                    window.location.replace(`${mockPath}login`)
                })
            }
        })
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Ops...',
            text: errorMessage || generalTexts.errorMessage,
        })
    }
}
export const raiseSuccess = async (successMessage) => {
    //setSubmitting(false)
    Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: successMessage || generalTexts.successMessage,
    })
}



