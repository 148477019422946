import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* gap: 40px; */

  .break {
    flex-basis: 100%;
  }
`;