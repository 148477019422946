import {
  Card,
  CardContent,
  Modal,
} from '@material-ui/core';


export default function BaseModal({ open = false, handleClose, children, cardStyle, contentStyle }) {
  return (
    <div>
      <Modal
        open={open}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={{ width: '60%', padding: '15px 1rem 0 1rem', minHeight: '60%', position: 'relative', ...cardStyle }}>
          <CardContent style={{ ...contentStyle }}>
            {children}
          </CardContent>
        </Card>
      </Modal>
    </div >
  );
}