import styled from "styled-components";

export const Video = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  iframe {
    top: 0;
    left: 0;
    width: 50%;
    height: 500px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    height:500px;
  }
`;


export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #E8F2ED;
  justify-content: space-around;
  width: 100%;
  margin-top:64px;
`;

export const SustentabilidadeImage = styled.img`
  max-width: 380.38px;
  max-height: 350px;
  width: 100%;
  height: 100vh;
`;

export const DivOne = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`;

export const DivTwo = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  height: 150px;
  button {
    font-family: Nunito;
    font-size: 24px;
    color: #ffffff;
    height: 70px;
    width: 250px;
    background-color: #06C8A9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover {
      cursor: pointer;
      background-color: #06c8a982;
    }
  }
`;

