import React, { createContext, useState, useEffect, } from 'react';

import { setAuthToken } from '../../services/token';

//import { verifySession } from '../../services/session';
import Dexie from 'dexie'
//import { useHistory, useLocation } from 'react-router-dom';



export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  //const [auth,setAuth] = useState((window.localStorage.getItem('isLogged') ? (JSON.parse(window.localStorage.getItem('isLogged')) ) : (false) ))
  const [build, setBuild] = useState(true)
  const [userState, setUserState] = useState({ sessionToken: null });
  const [loading, setIsLoading] = useState(false);
  const [signing, setSigning] = useState(false);
  const [db] = useState(new Dexie('desafiosDB'));
  const value = { userState, setUserState, loading, setIsLoading, signing, setSigning, build };

  async function getIndexUserdata() {
    let dbUserData = await db.sessionData.get('user')

    return dbUserData
  }

  useEffect(() => {
    db.version(1).stores({ sessionData: 'id,value' })
    return () => db.close()
  },
    [db]// eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    async function runLoadUser() {
      if (build) await loadUser();
    }
    runLoadUser();
  }, [build])// eslint-disable-line react-hooks/exhaustive-deps

  const loadUser = async () => {
    let user = await getIndexUserdata()
    if (!user) { await db.sessionData.add({ id: 'user', value: '' }) }
    else {
      setBuild(false)
      setAuthToken(user.value?.sessionToken || null)
      setUserState({ ...userState, ...user.value })
      window.localStorage.setItem('loading', false)
    }
  }
  const indexUser = async (obj) => {

    let prev = await getIndexUserdata();

    if (prev.value !== obj) {
      db.sessionData.put({ id: 'user', value: obj })
      window.localStorage.setItem('loading', false)
    }


  }

  useEffect(() => {
    async function runIndexUser() {
      if (build) return;
      indexUser(userState);
      setAuthToken(userState?.sessionToken || null)
    }
    runIndexUser();
  }, [userState])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;

