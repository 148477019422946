import api from './api';


export const getAllDesafios = async () => {

  return await api.get('desafios/').then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });
}

export const getDetalhesDesafioWithParams = async (params) => {

  return await api.get(`desafios/detalhes?${params}`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const getDesafiosWithParams = async (params) => {

  return await api.get(`desafios?${params}`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const getMeusDesafiosWithParams = async (id, params) => {

  let final_params = `FORNECEDOR=${id}` + (params ? `&${params}` : '')
  return await api.get(`desafios/propostas?${final_params}`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const putDesafio = async (id, data) => {

  return await api.put(`desafios?ID=${id}`, data).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const postDesafio = async (data) => {

  return await api.post(`desafios`, data).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const putDesafioWithParamId = async (id, data) => {

  return await api.put(`desafios?ID=${id}`, data).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}
export const getDesafiosDestaque = async () => {

  return await api.get(`desafios/destaques`).then(async response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });

}

export const getRanking = async () => {
  const orders = await api.get(`fornecedores/ranking`)
  return orders.data;
}