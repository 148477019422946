import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import HeaderLogged from './components/HeaderLogged';
import Footer from './components/Footer/Inside';
import {
  Root,
  Content,
  presets,
} from 'mui-layout';
import routes from './routes';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { scrollToTop } from './utils/functions';
import { UserContext } from './providers/UserProvider';
import { getAuthToken } from './services/token';
import { raiseAlert } from './components/Alert';
import { mockPath } from './utils/utils';
import { isBibiUser, isSupplierUser } from './utils/roles';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    minHeight: '80vh',
    height: '100vh',
    maxHeight: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    position: "absolute",
    overflowX: 'unset',
  },
  nav: {
    overflowX: 'hidden',
    width: 'inherit'
  },
  ml_landPage: {
    overflowY: 'scroll',
    height: `calc(100vh - 0em)!important`,
    maxHeight: `calc(100vh - 0em)!important`,
    minHeight: `calc(100vh - 0em)!important`,
  },
  ml_0: {
    height: `calc(100vh - 0em)!important`,
    maxHeight: `calc(100vh - 0em)!important`,
    minHeight: `calc(100vh - 0em)!important`,
  },
  main: {
    overflow: 'auto!important',
    display: 'flex',
    height: `calc(100vh - 6em)`,
    maxHeight: `calc(100vh - 6em)`,
    minHeight: `calc(100vh - 6em)`,
    width: '100vw',
    maxWidth: '100% !important',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000
  },
  fabOrange: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  footer: {
    padding: theme.spacing(3, 1.9, 1.3),
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: theme.spacing(7),
    minHeight: theme.spacing(7),
    maxHeight: theme.spacing(7),
    //boxShadow: '0px 0px 15px -2px rgba(217,217,217,1)'
  }
}))

export default function Layout(props) {
  const { userState, setUserState, build } = useContext(UserContext);
  const init_routes = routes
    .filter(item => item.can !== 'admin' && item.can !== 'supplier')
    .map(({ path, component, exact, can }, key) =>
      <Route exact={exact || false} path={path} component={component} key={key} />
    )

  const config = presets.createContentBasedLayout();
  const classes = useStyles();
  const [reactRouter, setReactRouter] = useState(init_routes)
  const history = useHistory();
  const [showOnScroll, setShowOnScroll] = useState(false)
  const route_param = history.location.pathname//!=='/desafios' ? (history.location.pathname) : (history.location.pathname + '/')

  useEffect(() => {
    document.getElementById('middle').addEventListener("scroll", (event) => {
      let scrollY = event.target.scrollTop
      if (scrollY && scrollY > 300) setShowOnScroll(true)
      else setShowOnScroll(false)
    })
  }, [])

  useEffect(() => {
    if (build) {
      if (history.location.pathname === '/') {
        history.push('/');
      } else if (route_param === `${mockPath}`) {
        window.localStorage.setItem('isLogged', false)
      } else if (JSON.parse(window.localStorage.getItem('loading'))) {
        history.push(`${history.location.pathname}${history.location.search}`);
      } else {
        if (!JSON.parse(window.localStorage.getItem('isLogged'))) history.push(`${history.location.pathname}${history.location.search}`);
      }
    }
  }, [build, history, route_param])


  useEffect(() => {
    window.localStorage.setItem('loading', true)
    if (isBibiUser(userState?.tipo)) {
      setReactRouter(routes.filter(item => item.can !== 'supplier').map(({ path, component, exact }, key) =>
        <Route exact={exact || false} path={path} component={component} key={key} />

      ).concat(<Redirect from='*' to={`${mockPath}404`} key={`${Math.random(10)}-redirect`} />)
      )
    } else if (isSupplierUser(userState?.tipo)) {

      setReactRouter(
        routes.filter(item => item.can !== 'admin').map(({ path, component, exact, can }, key) =>
          <Route exact={exact || false} path={path} component={component} key={key} />

        ).concat(<Redirect from='*' to={`${mockPath}404`} key={`${Math.random(10)}-redirect`} />)
      )
    } else {
      setReactRouter(init_routes)
    }

  }, [userState])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((getAuthToken())) {

      window.localStorage.setItem('isLogged', true)

      if (route_param === `${mockPath}` || history.location.pathname === `${mockPath}login` || history.location.pathname === `${mockPath}cadastrar`) {

        if (isSupplierUser(userState?.tipo)) {
          history.push(`${mockPath}principal`);
        } else if (isBibiUser(userState?.tipo)) {
          history.push(`${mockPath}home`);
        }
      }
      else {
        history.push(`${history.location.pathname}${history.location.search}`);
      }

    } else {


      if (!build) {
        if (route_param !== `${mockPath}` && history.location.pathname !== `${mockPath}login` && history.location.pathname !== `${mockPath}cadastrar` && history.location.pathname !== `${mockPath}nova-senha`) {

          if (history.location.pathname === '/') {
            history.push('/');
          } else {
            window.localStorage.setItem('isLogged', false)
            setUserState({ sessionToken: null })
            //setAuthToken(null)
            window.localStorage.setItem('loading', false)
            raiseAlert('Seu acesso expirou. Será necessário fazer login novamente.')
            history.push(`${mockPath}login`);

          }
        }
      } else {

        if (history.location.pathname === '/') {
          history.push('/');
        }
        else if (route_param === `${mockPath}`) {
          window.localStorage.setItem('isLogged', false)
          //setAuthToken(null)
          //setUserState( {sessionToken:null} )
        } else if (JSON.parse(window.localStorage.getItem('loading'))) {
          if (JSON.parse(window.localStorage.getItem('isLogged'))) history.push(`${history.location.pathname}${history.location.search}`);
          else {
            const publicRoutes = [
              `/desafios`, '/desafios/', `${mockPath}login`, `${mockPath}cadastrar`, `${mockPath}nova-senha`
            ]
            if (route_param !== `${mockPath}` && (!publicRoutes.includes(history.location.pathname)) && !history.location.pathname.includes('/cadastrar/usuario')) {
              raiseAlert('Sessão precisa ser iniciada! Será necessário fazer login antes.')
              history.push(`${mockPath}login`);
            }
          }
        } else {
          if (!JSON.parse(window.localStorage.getItem('isLogged'))) history.push(`${mockPath}`);
        }
      }
    }

  }, [(getAuthToken())]);// eslint-disable-line react-hooks/exhaustive-deps 


  return (
    <Root config={config}>
      {(getAuthToken() && (history.location.pathname !== '/')) ? (
        <HeaderLogged />
      ) : (null)}

      <Content id="middle" className={`${(history.location.pathname === '/') ? classes.ml_landPage : classes.main} ${!getAuthToken() ? classes.ml_0 : ""} `}>

        <Switch>
          {reactRouter}
        </Switch>

      </Content>

      <Fab style={{ visibility: (showOnScroll ? 'visible' : 'hidden') }} aria-label={'top'} className={clsx(classes.fab, classes.fabOrange)}
        onClick={() => { scrollToTop('middle') }}
        color="inherit">
        <UpIcon />
      </Fab>

      {(history.location.pathname !== '/') ? (<Footer />) : ('')}
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
