import React, { useContext, useEffect, useState } from 'react';

import ChallengeStatus from '../../../../components/ChallengeStatus';
import { Box, Checkbox, Container, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { SubmitButton } from '../../../../components/SubmitButton/style';
import { textsSubmitButtons, textsFooterDetail, mockPathDesafios, textsCadastroPage } from '../../../../utils/utils';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { raiseAlert } from '../../../../components/Alert';
import { UserContext } from '../../../../providers/UserProvider';
import { subcriberDesafiosWithParams } from '../../../../services/propostas';
import { getDetalhesDesafioWithParams } from '../../../../services/desafios';
import { isSupplierUser } from '../../../../utils/roles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    container: {
        height: 'calc(100vh - 5em)',
        maxHeight: 'calc(100vh - 5em)',
        minHeight: 'calc(100vh - 5em)',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: 'white'
    },
    textCenter: {
        textAlign: 'center'
    },
    fakeBox: {
        height: '100%',
        width: '100%'
    },
    mainCards: {
        width: '70vw!important',
        margin: 'auto!important',
        position: 'relative',
        top: '3rem',
    },
    orangeSet: {
        backgroundColor: "#FE5D00",
        //height:'25vh',
        height: '7em',
        '@media(max-width: 938px)': {
            //height:'60vh'
            height: '18.6em',
        },
    },
    footer: {
        color: '#C6C6C6',
        fontWeight: '400',
        fontSize: ' 14px'
    },
    checkColor: {
        color: `${theme.palette.primary.main}!important`,
    },
}));

export default function MyChallengesDetails() {
    const classes = useStyles();
    const history = useHistory();
    const navigateTo = () => history.push(`${mockPathDesafios}meus-desafios`);
    const navigateToEdit = () => history.push(`${mockPathDesafios}editar-desafio`);
    const location = useLocation();
    const [disable, setDisable] = useState(true)

    const [challengeDetails, setChallengeDetails] = useState({});
    const { userState } = useContext(UserContext);

    useEffect(() => {
        if (userState?.DESAFIO_ID) loadChallengeDetails();
    }, [location])// eslint-disable-line react-hooks/exhaustive-deps

    const loadChallengeDetails = async () => {
        await getDetalhesDesafioWithParams(`ID=` + userState?.DESAFIO_ID).then((response) => {
            setChallengeDetails(response?.data);
        }).catch(err => {
            raiseAlert(err.error)
            history.goBack();
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await subcriberDesafiosWithParams(userState?.ID, `DESAFIO=${challengeDetails?.ID}`).then((response) => {
            if (response.status === 200) {
                navigateTo()
            } else {
                raiseAlert(response.error)
            }
        }).catch(err => {
            raiseAlert(err.error)
        })
    }

    function goToRegulation() {
        window.open(process.env.REACT_APP_REGULATION_ADDRESS_2)

    }

    return (
        <div className={classes.root}>
            <Container component="main" className={classes.container}>
                <Box className={classes.orangeSet} textAlign="center">
                    <Box className={`${classes.mainCards}`}>
                        <Box className={`box`}>
                            {challengeDetails ? <ChallengeStatus
                                challenge={challengeDetails}
                                accordionOpen={true}
                            /> : ''}

                            {isSupplierUser(userState?.tipo) ? (
                                <Box mt={6} mb={6}>

                                    <Typography className={classes.textCenter} component="p" variant="subtitle2">
                                        {textsFooterDetail.textChallengeDetail}
                                    </Typography>
                                </Box>
                            ) : (<Box mt={3} mb={3} />)}


                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox value="agree"
                                            classes={{ root: classes.checkColor, checked: classes.checkColor }}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    setDisable(false)
                                                } else {
                                                    setDisable(true)
                                                }
                                            }} name="accord" />}
                                    label={
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="subtitle2">
                                                    {textsCadastroPage.agreeWith}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2" color="primary" onClick={goToRegulation}
                                                    className={`${classes.textAdaptCheckBox}`}>
                                                    <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                                                        &nbsp;{textsCadastroPage.regulation}&nbsp;
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2">
                                                    {textsCadastroPage.ofProgram}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                                {isSupplierUser(userState?.tipo) ?
                                    <SubmitButton onClick={(e) => handleSubmit(e)} disabled={disable}>
                                        {textsSubmitButtons.textChallengeDetail}
                                    </SubmitButton> :
                                    <SubmitButton onClick={() => navigateToEdit()}>
                                        {textsSubmitButtons.textFormChallengeEdit}
                                    </SubmitButton>}
                            </Box>

                            {/*   <Footer /> */}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}