import styled from "styled-components";

export const NavHeader = styled.header`
  padding: .4em 0;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 20px 20px rgba(50, 50, 50, 0.16);
  z-index: 100;
  max-height:5rem;
  .logo {
    height:2.5rem
  }

  .header-div {
    width: 90% !important;

    /* @media (max-width: 1600px) {
       width: 70% !important;
    } */
  }

  &::after {
    content: "";
    clear: both;
    display: block;
  }

  .container {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .site-nav {
    position: absolute;
    top: 100%;
    right: 0%;
    background: #a6a6a6;
    height: 0px;
    overflow: hidden;
  }

  .site-nav-open {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
  }

  .site-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .site-nav a {
    font-family: Nunito;
    font-size: 15px;
    color: #000000;
    text-decoration: none;

    display: block;
    padding: 2em 2em 2em 1.5em; /* padding for links */
    text-transform: uppercase;
    text-decoration: none;
  }

  .site-nav a:hover,
  .site-nav a:focus {
    color: #fe5d00;
    cursor: pointer;
  }

  .menu-toggle {
    cursor: pointer;
    padding: 1em;
    margin-right: 0;
    margin-left: auto;
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    content: "";
    display: block;
    background: #fe5d00;
    height: 3px;
    width: 1.75em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
  }

  .hamburger::before {
    transform: translateY(-7px);
  }

  .hamburger::after {
    transform: translateY(4px);
  }

  .open .hamburger {
    transform: rotate(45deg);
    background: #fe5d00;
  }

  .open .hamburger::before {
    opacity: 0;
  }

  .open .hamburger::after {
    transform: translateY(-3px) rotate(-90deg);
    background: #fe5d00;
  }

  @media (min-width: 950px) {
    .container {
      justify-content: center;
    }

    .menu-toggle {
      display: none;
    }

    .site-nav {
      height: auto;
      position: relative;
      background: transparent;
      float: right;
      font-weight: 300;
    }

    .site-nav li {
      display: inline-block;
      border: none;
    }

    .site-nav a {
      padding: 0;
      margin-left: 3em;
    }

    .site-nav a:hover,
    .site-nav a:focus {
      background: transparent;
      color: #fe5d00;
      cursor: pointer;
    }
  }
  @media (max-width: 600px) {
    .logo {
     height:2.5rem
    }

  }
`;
