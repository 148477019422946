import React from 'react'

import Model from '../../../components/Model/index'
import { textsDescriptions, textsTitles } from '../../../utils/utils';

export default function AdminGroup() {
  
  return (
    <React.Fragment>
      <Model urlModel="grupos" isGroup nomeModel={textsTitles.textTitleGroup} descricaoModel={textsDescriptions.textDescriptionGroup} />
    </React.Fragment>
  );
}
