import React,  { useContext, useEffect, useState } from 'react';

import ChallengeStatus from '../../../../components/ChallengeStatus';
import { Box, Container, makeStyles } from '@material-ui/core';
import { textsFakeButtonStatus, textsTitles, textsTextAreaPlaceholder, textsSubmitButtons, textsFooterDetail, generalTexts, myChallengesStatus, mockPathDesafios } from '../../../../utils/utils';
import FormTextAttch from '../../../../components/FormTextAttch';
import { UserContext } from '../../../../providers/UserProvider';
import { useHistory } from 'react-router';
import { raiseAlert, raiseSuccess } from '../../../../components/Alert';
import { propostaUpdateDesafiosWithParams } from '../../../../services/propostas';
import { getDetalhesDesafioWithParams } from '../../../../services/desafios';
import { uploadFile } from '../../../../services/file';

const useStyles = makeStyles((theme) => ({
    root:{
      flexGrow:1
    },
    container:{
      height: 'calc(100vh - 5em)',
      maxHeight: 'calc(100vh - 5em)',
      minHeight: 'calc(100vh - 5em)',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: 'white'
    },
    fakeBox: {
        height:'100%',
        width: '100%'
    },
    mainCards: {
        width: '70vw!important',
        margin: 'auto!important',
        position: 'relative',
        top: '3em',
        '@media(max-width: 938px)' : {
            top: '1em',
            width: '70vw!important',
        },
    },
    title: {
        fontSize:28,
        fontWeight: 600,
        color:"#FFFFFF",
        textTransform:'none',
        margin: '0 auto 0 25px'
    },
    subtitle: {
        color:"#000000",
        margin: '2em auto auto 25px',
        '@media(max-width: 938px)' : {
            margin: '6em auto auto 25px',
        },
    },
    orangeSet: {
        backgroundColor:"#FE5D00",
        //height:'25vh',
        height:'7em',
        '@media(max-width: 938px)' : {
            //height:'60vh'
            height:'16em',
      }
    },
    textCenter: {
        textAlign: 'center'
    },
    right: {
        margin: 'auto auto auto 0'
    },
    caractersSize: {
        height: 'fit-content',
        margin: 'auto 0'
    },
    textAreaPlaceholder: {
        height: 'fit-content',
        letterSpacing: '-1.06px',
        
    }
}));

export default function MyChallengesSubscriber() {
    const classes = useStyles();
    const history = useHistory();
    const navigateTo = () => history.push(`${mockPathDesafios}meus-desafios`);
    const {userState} = useContext(UserContext);
    const [challengeDetails, setChallengeDetails] = useState({});

    useEffect(() => {
        if(userState?.DESAFIO_ID)  loadChallengeDetails();
    }, [userState]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadChallengeDetails = async () => {
        await getDetalhesDesafioWithParams(`ID=` + userState?.DESAFIO_ID).then((response) => {
            setChallengeDetails(response?.data);
        }).catch(err=>{
            raiseAlert(err.error)
            history.goBack();
        })
    }

    const handleSubmit = async (e, text, files) => {
        e.preventDefault();
        if(text.length < 1){raiseAlert(generalTexts.notEmpty);return; }
        if(!userState?.PROP_ID) { raiseAlert(generalTexts.errorMessage); return;}
        else
        
        await uploadFile(userState?.PROP_ID,files).then(response=>{
            if(response?.status === 200){
                
            }else{
                
            }
           
        }).catch(err=>{
           raiseAlert(err.error)
        })
        
        let data = {
            STATUS: myChallengesStatus[1],
            PROPOSTA: text
        }
        await propostaUpdateDesafiosWithParams(userState?.PROP_ID,data).then((response) => {
                if(response?.status === 200){
                    raiseSuccess();
                    navigateTo()
                  }
                else{
                  raiseAlert(response.error)
                }
        }).catch(err=>{
            raiseAlert(err.error)
        }) 
         
    }

    return (
        <div className={classes.root}>
            <Container component="main" className={classes.container}>
                <Box className={classes.orangeSet} textAlign="center">
                        <Box className={`whiteSet ${classes.mainCards}`}>
                            <Box className={`box`}>

                                <ChallengeStatus
                                    challenge={challengeDetails}
                                    haveStatus={true}
                                    textStatus={textsFakeButtonStatus.textChallengeSubscriber}
                                />

                                <Box mt={4} mb={4}>
                                    <hr/>
                                 </Box>

                                <FormTextAttch
                                    handleSubmit={handleSubmit}
                                    textTitle = {textsTitles.textTitleChallengeSubscriber}
                                    textAreaPlaceholder = {textsTextAreaPlaceholder.textChallengeSubscriber}
                                    textFooterDetail = {textsFooterDetail.textChallengeSubscriber}
                                    textsSubmitButtons = {textsSubmitButtons.textChallengeSubscriber}
                                />

                               {/*  <Footer /> */}

                            </Box>
                        </Box>
                    </Box>
            </Container>
        </div>
    )
}