import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { filterKey } from '../../utils/utils';
import {
  challengesMainStatus,
  challengesMainStatusAdmin,
  textsMainPage,
} from '../../utils/utils';
import CustomButtonGroup from '../../components/CustomButtonGroup';
import CardMainGroup from '../../components/CardMainGroup';
import { getDesafiosWithParams } from '../../services/desafios';
import { raiseAlert } from '../../components/Alert';
import { UserContext } from '../../providers/UserProvider';
import clsx from 'clsx';
import { isBibiUser, isSupplierUser } from '../../utils/roles';
import { Grid } from '@material-ui/core';
import CardGraph from '../../components/CardGraph';
import { getDesafiosDashData } from '../../services/home';
import AccordionChallenges from '../../components/AccordionChallenges';
import { getSuppliersProposals } from '../../services/propostas';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  finished: {
    border: '1px solid #FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  finishedText: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  selectedText: {
    textTransform: 'capitalize',
    color: theme.palette.default.main,
  },
  title: {
    fontSize: 36,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    alignItems: 'baseline',
    color: '#FFFFFF',
  },
  subtitleAdmin: {
    alignItems: 'baseline',
    textAlign: 'center',
    color: '#010101',
    marginTop: '2rem'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    },
  },
  textAdapt: {
    color: '#FE5D00',
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    //minHeight: '30vh',
    // maxHeight: '30vh',
    padding: '0rem 0rem 5rem 0rem',
  },
  whiteSet: {
    backgroundColor: '#FFFFFF',
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem',
  },
}));

export default function SupplierMain() {
  const classes = useStyles();
  const [selected, setSelected] = useState('Abertos');
  const [load, setLoad] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [filter, setFilter] = useState(`STATUS=${filterKey[selected]}`);
  const [graphData, setGraphData] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const { userState } = useContext(UserContext);
  useEffect(() => {
    setRequesting(true);
    setFilter(`STATUS=${filterKey[selected]}`);
  }, [selected]);

  useEffect(() => {
    getDesafiosDashData().then((resp) => {
      setGraphData(resp.data);
    });
    getSuppliersProposals().then((resp) => {
      setChallenges(resp.data);
    });
    async function loadChallenges() {
      await getDesafiosWithParams(filter)
        .then((resp) => {
          if (resp?.status === 200) {
            setRequesting(false);
            setLoad(resp?.data);
          }
        })
        .catch(async (err) => {
          raiseAlert(err.error);
          setRequesting(false);
        });
    }
    loadChallenges();
  }, [filter]);

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box
          className={clsx(`orangeDiv ${classes.orangeSet} `, {
            'fit-div': isBibiUser(userState?.tipo),
          })}
          textAlign="center"
        >
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              {textsMainPage.title}
            </Typography>

            {isSupplierUser(userState?.tipo) && (
              <>
                <Typography
                  className={classes.subtitle}
                  component="h1"
                  variant="subtitle2"
                >
                  {textsMainPage.subtitle}
                </Typography>
                <Box
                  id="div-main-buttons"
                  mt={2}
                  mb={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box className="inner-div-main-buttons" mr={4}>
                    <CustomButtonGroup
                      load={
                        isSupplierUser(userState?.tipo)
                          ? challengesMainStatus
                          : isBibiUser(userState?.tipo)
                            ? challengesMainStatusAdmin.filter((button) => {
                              if (button === 'Novo') {
                                if (userState.tipo === 'BA') {
                                  return true;
                                }

                                return (
                                  userState.tipo === 'B' &&
                                  userState?.permissoes?.includes('DE')
                                );
                              }

                              return true;
                            })
                            : challengesMainStatus
                      }
                      selected={
                        selected === '' ? challengesMainStatus[0] : selected
                      }
                      setSelected={setSelected}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box className={classes.whiteSet}>
          <Box className={`header-div mob ${classes.mainCards}`}>
            {isBibiUser(userState?.tipo) && (
              <>
                <Grid
                  container
                  spacing={4}
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={6} md={5} lg={5}>
                    <CardGraph respData={graphData} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AccordionChallenges challenges={challenges} />
                  </Grid>
                </Grid>


                <Typography
                  className={classes.subtitleAdmin}
                  component="h1"
                  variant="subtitle2"

                >
                  {textsMainPage.subtitle}
                </Typography>
                <Box
                  id="div-main-buttons"
                  mt={2}
                  mb={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box className="inner-div-main-buttons" mr={4}>
                    <CustomButtonGroup
                      load={
                        isSupplierUser(userState?.tipo)
                          ? challengesMainStatus
                          : isBibiUser(userState?.tipo)
                            ? challengesMainStatusAdmin.filter((button) => {
                              if (button === 'Novo') {
                                if (userState.tipo === 'BA') {
                                  return true;
                                }

                                return (
                                  userState.tipo === 'B' &&
                                  userState?.permissoes?.includes('DE')
                                );
                              }

                              return true;
                            })
                            : challengesMainStatus
                      }
                      selected={
                        selected === '' ? challengesMainStatus[0] : selected
                      }
                      setSelected={setSelected}
                    />
                  </Box>
                </Box>
              </>
            )}

            <CardMainGroup requesting={requesting} load={load} />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export function AdminGraphs() {
  return <div></div>;
}
