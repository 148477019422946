import React, { useState, createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { generalTexts } from '../../utils/utils';
import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';


export default function CardGraph({ respData }) {
  const useStyles = makeStyles(theme => ({
    root: {

      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: "pointer",
        boxShadow: '0px 0px 5px #FE5D00'
      }
    },
    image: {
      height: 150,
      width: 150,
      borderRadius: 5,
      marginBottom: 8,
      objectFit: 'cover'
    },
    title: {
      color: '#000000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start'
    },
    media: {
      height: 140,
      display: 'flex'
    },
    expand: {
      margin: '0 0 0 auto'
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      //lineHeight: '1.8em',
      textAlign: 'start'
    },
    textLegend: { color: "#000000", textDecoration: 'none' },
    cardPadding: { padding: 0, "&:last-child": { padding: 0 } },
    crossed: {
      textDecoration: 'line-through'
    }
  }));

  const classes = useStyles();
  const cardRef = createRef();
  const [obj, setObj] = useState({});
  const [aux, setAux] = useState([]);
  const [crossed, setCrossed] = useState([]);
  const labels = ["Inscrito", "Proposta enviada", "Proposta aceita", "Testes", "Implementado", "Negado"]
  const chartColors = [
    "#FE5D00",//Inscrito
    "#22A7F0",//Proposta enviada
    "#FFCC02",//Proposta aceita
    "#9B59B6", //Teste
    "#06C8A9", //Implementado
    "#F03D4B", //Negado
  ]

  const data = {
    maintainAspectRatio: true,
    responsive: false,
    labels: labels,
    datasets: [
      {
        data: aux,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ]
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      labels: {
        font: {
          size: 18,
          family: 'Nunito'
        }
      },
      title: {
        display: false,
        text: generalTexts.homeInfoStatus
      }
    },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  }

  const [dataState, setDataState] = useState(data);
  const [optionState, setOptionState] = useState(options);

  var delayed;

  useEffect(() => {
    setOptionState(options)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setObj({
      ...labels.map(item => {
        return {
          name: item,
          show: true,
          value: (respData.filter(value => value.STATUS === item))[0]?.QUANTIDADE || 0
        }
      })
    })
  }, [respData])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataState({
      ...dataState, datasets: [
        {
          data: aux,
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    })
  }, [aux])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCrossed(Object.values(obj)?.filter(item => (item.show === false)).map(item => item.name))
    setAux(Object.values(obj)?.map(item => { if (item.show === true) { return item.value } else { return 0 } }))
  }, [obj])// eslint-disable-line react-hooks/exhaustive-deps

  async function formatArrayData(item) {
    let auxObj = obj
    auxObj[item].show = !(auxObj[item].show)
    setObj({ ...auxObj })
  }



  return (
    <Card ref={cardRef} className={classes.root}>
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left">
          <Typography className={classes.title} component="h1" variant="subtitle1">
            {generalTexts.homeInfoStatus}
          </Typography>
        </Box>
        {aux.length > 0 ? (
          <Box display="flex" textAlign="left" padding={0}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={6} lg={6} >
                <Box><Doughnut data={dataState} options={optionState} height={150} width={150} /></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} >
                <Box ml={1} flexGrow={1}>
                  {data.labels.map((item, index) => (
                    <Box display="flex" alignItems="center" key={index} >
                      <div style={{ height: 10, width: 10, backgroundColor: chartColors[index], borderRadius: 50, marginRight: 8 }} />
                      <Typography onClick={() => {
                        formatArrayData(index)
                      }}
                        className={clsx(classes.textLegend, {
                          [classes.crossed]: crossed.includes(item)
                        })}
                        component="h1" variant="subtitle1"
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : ('Carregando...')}

      </CardContent>
    </Card>
  );
}