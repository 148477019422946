import api from './api';

export const setAuthToken = token => {
    if (token) {
        //applying token
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        //deleting the token from header
        delete api.defaults.headers.common['Authorization'];
    }
}

export const getAuthToken = () => {
    return api.defaults.headers.common['Authorization']
   
}


