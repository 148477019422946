import { RankingCard } from "../RankingCard";
import { Container } from "./styles";

export function PodiumContainer({ title, data = [] }) {
  return (
    <>
      <h1 style={{ fontSize: '25px' }}>{title}</h1>
      <Container>
        {data.map((item, index) => {
          return (
            <RankingCard key={index} item={item} />
          )
        })}
      </Container>
    </>
  )
}