import React, { useCallback, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { getAllFornecedor, updateSupplier } from '../../services/fornecedores';
import { raiseAlert } from '../../components/Alert';
import { format, parseISO } from 'date-fns';
import { Input, Select } from './styles';
import { RemoveRedEye } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';

const update = async (value, field, id) => {
  await updateSupplier(id, {
    [field]: value
  })
  toast(`Dado atualizado!`, {
    position: 'bottom-right',
    hideProgressBar: true,
  });
}

const columns = [
  {
    field: 'ver', headerName: 'Ver', width: 70, renderCell: ({ row }) => {
      const { ID, COD_PESSOA_SFT } = row;
      let url = `/visualizar-fornecedor/home/${ID}`;
      if (COD_PESSOA_SFT) {
        url += `/${COD_PESSOA_SFT.replace(' ', '')}`
      }
      return (
        <a href={url} target='_blank' style={{ textDecoration: 'none', color: 'black', display: 'flex' }} rel="noreferrer">
          <RemoveRedEye />
        </a>
      )
    }
  },
  { field: 'NOME', headerName: 'Nome', width: 220 },
  { field: 'EMAIL', headerName: 'Email', width: 240 },
  {
    field: 'CADASTRO', headerName: 'Cadastro', width: 120, renderCell: ({ row }) => {
      return row.CADASTRO.substr(0, 10)
    }
  },
  { field: 'NOME_SUB_GRUPO', headerName: 'Subgrupo', width: 150 },
  {
    field: 'ID', headerName: 'ID', width: 80, renderCell: ({ row }) => {
      return <Input type="text" defaultValue={row.COD_PESSOA_SFT} onBlur={(e) => update(e.target.value, 'COD_PESSOA_SFT', row.id)} />
    }
  },
  {
    field: 'POSICAO', headerName: 'Posição', width: 120, renderCell: ({ row }) => {
      return <Input type="text" defaultValue={row.POS_RANKING} onBlur={(e) => update(e.target.value, 'POS_RANKING', row.id)} />
    }
  },
  {
    field: 'PARCEIRO_DESDE', headerName: 'Parceiro Desde', width: 170, renderCell: ({ row }) => {
      let date = ''
      if (row?.PARCEIRO_DESDE) {
        date = row?.PARCEIRO_DESDE.slice(0, 10)
      }
      return <Input style={{ width: '100%' }} type="date" defaultValue={date} onBlur={(e) => update(e.target.value, 'PARCEIRO_DESDE', row.id)} />
    }
  },
  {
    field: 'DIAS_NOTIFICAR_OC', headerName: 'Notificar em', width: 170, renderCell: ({ row }) => {
      return (
        <Select defaultValue={row.DIAS_NOTIFICAR_OC} onChange={(e) => update(e.target.value, 'DIAS_NOTIFICAR_OC', row.id)}>
          <option value={null}>Selecione</option>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={3}>3</option>
          <option value={5}>5</option>
        </Select>
      )
    }
  },
  {
    field: 'DOCUMENTOS', headerName: 'Documentos', width: 130, renderCell: ({ row }) => {
      const colors = {
        'Incompleto': '#FE5D00',
        'Tudo certo': '#06C8A9'
      }

      if (row?.STATUS) {
        const { STATUS } = row;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: colors[STATUS] }}></div>
            <span>{STATUS}</span>
          </div>
        )
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
          <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'red' }}></div>
          <span>Incompleto</span>
        </div>
      )
    }
  },

];

export default function FornecedoresTable({ searchName = '', searchGroup, searchSubGroup, searchDoc }) {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const filteredRows = useCallback(() => rows.filter(({ NOME, ID_GRUPO, SUB_GRUPO, STATUS }) => {
    let filter = true;

    if (searchName) {
      filter = filter && NOME.toLowerCase().includes(searchName.toLowerCase())
    }

    if (searchGroup) {
      filter = filter && +ID_GRUPO === +searchGroup
    }

    if (searchSubGroup) {
      filter = filter && +SUB_GRUPO === +searchSubGroup
    }

    if (searchDoc) {
      filter = filter && STATUS === searchDoc
    }

    return filter;
  }), [rows, searchName, searchGroup, searchSubGroup, searchDoc])();

  useEffect(() => {
    async function loadFornecedores() {
      setLoading(true)
      await getAllFornecedor().then(async resp => {
        setLoading(false)
        if (resp?.status === 200) {

          setRows(resp?.data.map(item => ({ ...item, id: item.ID, CADASTRO: item?.CADASTRO ? (format(parseISO(item?.CADASTRO.substring(0, item?.CADASTRO.length)), 'dd/MM/yyyy HH:mm:ss')) : '-' })))
        } else {
          raiseAlert(resp.error)
        }
      }).catch(async err => {
        setLoading(false)
        raiseAlert(err.error)
      });
    }
    loadFornecedores();
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <ToastContainer />
      <DataGrid
        loading={loading}
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        resizable
        componentsProps={{
          pagination: {
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`
          }
        }}
      />
    </div>
  );
}