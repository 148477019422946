/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import clsx from 'clsx';

import { textsSupplierInvoiceOrderPage } from '../../../utils/utils';

import { UserContext } from '../../../providers/UserProvider';

import useStyles from './styles';
import CardInvoiceHomeSupplierGraph from '../../../components/CardInvoiceHomeSupplierGraph';
import { Grid } from '@material-ui/core';
import CardINegotiationEvolutionSupplierGraph from '../../../components/CardINegotiationEvolutionSupplierGraph';
import CardOrderList from '../../../components/CardOrderList';
import CardOrderValue from '../../../components/CardOrderValue';
import CardOrderIndicators from '../../../components/CardOrderIndicators';
import { getFornecedor, getNegotiations } from '../../../services/fornecedores';
import { getOrderPizzaGraph, getOrders, getValueTransitioned } from '../../../services/orders';
import { useLoading } from '../../../providers/LoadingProvider';
import { useHistory, useParams } from 'react-router-dom';
import { isBibiUser } from '../../../utils/roles';



export default function SupplierInvoiceOrder() {
  const classes = useStyles();
  const [negotiations, setNegotiations] = useState([])
  const [orders, setOrders] = useState([])
  const [supplier, setSupplier] = useState(null)
  const history = useHistory();
  const [value, setValue] = useState({
    VLR_ORDENS_ABERTAS: 0,
    VLR_ORDENS_ARECEBER: 0
  })
  const [orderPizza, setOrderPizza] = useState(null)
  const { userState } = useContext(UserContext);
  const { setLoading } = useLoading();
  const { id, pessoa } = useParams();

  let permissions = [];
  if (Array.isArray(userState?.permissoes)) {
    permissions = userState?.permissoes
  }


  useEffect(() => {
    async function initalRequest() {
      setLoading(true)
      const supplierData = await getFornecedor(userState?.ID);
      if (supplierData?.data[0]?.COD_PESSOA_SFT) {
        const negotiationData = await getNegotiations(supplierData.data[0].COD_PESSOA_SFT)
        const ordersData = await getOrders(supplierData.data[0].COD_PESSOA_SFT);
        const valueTransitioned = await getValueTransitioned(supplierData.data[0].COD_PESSOA_SFT);
        const pizza = await getOrderPizzaGraph(supplierData.data[0].COD_PESSOA_SFT);

        setValue(valueTransitioned)
        setNegotiations(negotiationData)
        setOrders(ordersData)
        setOrderPizza(pizza);
      }

      setSupplier(supplierData.data[0])
      setLoading(false)
    }

    async function initalRequestByAdmin() {
      setLoading(true)
      const supplierData = await getFornecedor(id);
      if (pessoa) {
        const negotiationData = await getNegotiations(pessoa)
        const ordersData = await getOrders(pessoa);
        const valueTransitioned = await getValueTransitioned(pessoa);
        const pizza = await getOrderPizzaGraph(pessoa);

        setValue(valueTransitioned)
        setNegotiations(negotiationData)
        setOrders(ordersData)
        setOrderPizza(pizza);
      }
      setSupplier(supplierData.data[0])
      setLoading(false)
    }

    if (!location.pathname.includes('visualizar-fornecedor')) {
      initalRequest();
    } else {
      initalRequestByAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  if (userState.tipo === 'F' && !permissions.includes('OS')) {

    history.push('/principal')

    return <></>;
  }

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box className={clsx(`orangeDiv ${classes.orangeSet} `, {
          'fit-div': isBibiUser(userState?.tipo)
        })} textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsSupplierInvoiceOrderPage.title}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.whiteSet}>
          <Box className={`header-div mob ${classes.mainCards}`}>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={'auto'} md={4} lg={4}>
                <CardInvoiceHomeSupplierGraph respData={orderPizza} userState={userState} />
              </Grid>
              <Grid item xs={12} sm={'auto'} md={5} lg={5}>
                <CardOrderValue value={value} />
              </Grid>
              <Grid item xs={12} sm={'auto'} md={3} lg={3}>
                <CardOrderIndicators link={supplier?.LINK_APEX} />
              </Grid>
            </Grid>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <CardINegotiationEvolutionSupplierGraph graphData={negotiations} width={12} />
              </Grid>
            </Grid>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <CardOrderList
                  orders={orders}
                  userId={userState?.ID}
                  codPessoaSft={supplier?.COD_PESSOA_SFT}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

    </div>
  );
}