import api from './api';

// Materiais

export const uploadMaterials = async (id, files) => {

    return await Promise.all(
        files.length > 0 && files.map(async (item, index) => {

            var materialFile = item.file
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('file', materialFile);
            //console.log(fd.get('file'))

            return await api.post(`desafios/materiais?ID=${id}`, fd, config).then(response => {
                return response
                //resp.push({ success: response.data.url })
            })
                .catch((err) => {

                    //resp.push({ error: err.response.data.message })
                    return { status: 400, error: err.response.data.message }

                });

        })

    );

}

export const downloadMaterial = async (id, NOME) => {

    return await api.get(`desafios/materiais?ID=${id}`, { responseType: 'blob' }).then(async response => {
        const type = response.headers['content-type']
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: 'UTF-8' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', NOME); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response
    }).catch(err => {
        return { status: 400, error: err.response.data.message }
    });

}
export const deleteMaterial = async (id) => {

    return await api.delete(`desafios/materiais?ID=${id}`).then(async response => {
        return response
    }).catch(err => {
        return { status: 400, error: err.response.data.message }
    });

}

//Anexos


export const uploadFile = async (id, files) => {

    return await Promise.all(
        files.length > 0 && files.map(async (item, index) => {

            var imageFile = item.file
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('file', imageFile);
            //console.log(fd.get('file'))

            await api.post(`desafios/propostas/anexos?ID=${id}`, fd, config).then(response => {
                return response
                //resp.push({ success: response.data.url })
            })
                .catch((err) => {

                    //resp.push({ error: err.response.data.message })
                    return { status: 400, error: err.response.data.message }

                });

        })


    );


}

export const downloadAttachment = async (id, NOME) => {

    return await api.get(`desafios/propostas/anexos?ID=${id}`, { responseType: 'blob' }).then(async response => {
        const type = response.headers['content-type']
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: 'UTF-8' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', NOME); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response
    }).catch(err => {
        return { status: 400, error: err.response.data.message }
    });

}
export const deleteAttachment = async (id) => {

    return await api.delete(`desafios/propostas/anexos?ID=${id}`).then(async response => {
        return response
    }).catch(err => {
        return { status: 400, error: err.response.data.message }
    });

} 