import bibiLogo from '../../../../assets/logo.svg';
import { ContactModal } from './ContactModal';
import * as S from './styles';
import { useSupplierDay } from './hooks';
export function SupplierDay() {
  const { day, contactOpen, setOpenContact } = useSupplierDay();

  return (
    <S.Container>
      <img src={bibiLogo} alt="Logo da bibi" />
      <p className="title">Dia do Fornecedor</p>
      <S.SupplierDayHighlight>{day}</S.SupplierDayHighlight>

      <S.Button type="button" onClick={() => setOpenContact(true)}>
        Agende sua visita
      </S.Button>

      <ContactModal open={contactOpen} onClose={() => setOpenContact(false)} />
    </S.Container>
  );
}
