import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { generalTexts, mockPathDesafios } from '../../utils/utils';
import { UserContext } from '../../providers/UserProvider';
import DescriptionIcon from '@material-ui/icons/AssignmentOutlined';
import clsx from 'clsx';
import { ChipFormChallenge } from '../SelectMultipleModel/style';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#000000",
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start'
  },
  subtitle: {
    color: "#000000",
    //margin: '2em auto auto 25px',
    '@media(max-width: 425px)': {
      //margin: '35px auto',
      textAlign: 'justify'
    }
  },
  header: {
    display: 'flex',
    width: '100%',
    '@media(max-width: 425px)': {
      display: 'grid',
    }
  },
  descriptionDiv: {
    marginBottom: '0px',
    marginLeft: '25px',
    marginTop: 'auto',
    maxWidth: '70%',
    '@media(max-width: 425px)': {
      maxWidth: '100%'
    }
  },
  titleStatusDiv: {
    display: 'flex',
    '@media(max-width: 425px)': {
      display: 'grid',
    }
  },
  gridContainer: {
    display: 'flex'
  },
  conntentAcordion: {
    width: '100%',
    boxShadow: '0px 0px 0px #6C6C6C',
    border: '1px solid #00000029',
    borderRadius: '5px'
  },
  accordion: {
    padding: '6px',
    margin: '0 !important',
    border: '1px solid #00000029',
    borderLeft: '0px',
    borderRight: '0px',
    borderRadius: '0 !important'
  },
  accordionTop: {
    padding: '6px',
    margin: '0 !important',
    borderRadius: '5px 5px 0 0 !important'
  },
  accordionBottom: {
    padding: '6px',
    margin: '0 !important',
    borderRadius: '0 0 5px 5px !important'
  },
  fileItem: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '5px'
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000000'
  },
  chip: {
    //margin: 2,
    height: '2em!important',
    margin: '8px!important',
    padding: '4px 4px!important',
    '&:hover': {
      backgroundColor: "#FE5D00"
    }
  },
  media: {
    height: 140,
    maxHeight: 140,
    minHeight: 140,
    width: '100%',
    maxWidth: 160,
    minWidth: 160,
    margin: '0 auto'
  },
  right: {
    margin: 'auto auto auto 0'
  },
  liFile: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '0 15px'
  },
  iconFile: {
    fontSize: 70,
    color: '#C6C6C6'
  },
  align: {
    alignItems: 'center'
  },
  iconColor: { color: "#FFFFFF!important" }
}));

export default function AccordionChallenges({
  challenges = [],


}) {

  const history = useHistory();
  const classes = useStyles();
  const { userState, setUserState } = useContext(UserContext);

  const handleRedirect = (input, challengeId) => {
    setUserState({ ...userState, DESAFIO_ID: challengeId, PROP_ID: input?.PROP_ID, PROP_NOME_F: input?.NOME })
    history.push(`${mockPathDesafios}proposta`, { DESAFIO_ID: challengeId, PROP_ID: input?.PROP_ID, PROP_NOME_F: input?.NOME })
  }

  return (
    <Grid container>
      <Box className={classes.header}>
        <Box width={"100%"} display="grid">
          <Box className={classes.titleStatusDiv} >
            <Typography className={classes.title} component="h2" variant="subtitle1">
              {generalTexts.openChallenges}
            </Typography>

          </Box>
        </Box>
      </Box>

      <Box className={classes.conntentAcordion} mt={3}>
        {challenges.length > 0 ? (
          challenges.map((challenge, index) => (
            <Accordion key={`accordion_challenge_${index}`} square={true} className={clsx(classes.accordion, {
              [classes.accordionTop]: index === 0,
              [classes.accordionBottom]: index === (challenges.length - 1)
            })}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading} component="h2">{challenge?.DESAFIO}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="h2" variant="subtitle2">
                  {!challenge?.FORNECEDORES || challenge?.FORNECEDORES.length < 1 ? (
                    <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                      {generalTexts.noInfo}
                    </Typography>
                  ) : (
                    challenge?.FORNECEDORES?.map((item, index) => (
                      <ChipFormChallenge onClick={() => { handleRedirect(item, challenge?.ID) }}
                        onDelete={() => { handleRedirect(item, challenge?.ID) }}
                        deleteIcon={<DescriptionIcon className={classes.iconColor} />} key={index} label={item?.NOME} className={classes.chip} />
                    ))
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>))
        ) : ('')}
      </Box>
    </Grid>
  )
}