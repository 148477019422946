import React, { useState, useRef, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import initPic from '../../assets/backgroundOne.png';
import { useHistory } from 'react-router-dom';

import { raiseAlert, raiseSuccess } from '../../components/Alert';
import { generalTexts, textsCadastroPage, textsProfilePage, mockPath } from '../../utils/utils';
import { cadastrarFornecedor } from '../../services/fornecedores';
import { LineScalePulseOut } from 'react-pure-loaders';
import { validMail } from '../../utils/functions';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    backgroundImage: `url(${initPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    //marginTop: theme.spacing(8),
    //marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  title: {
    fontSize: 36
  },
  subtitle: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: 'center'
  },
  subtitle2: {
    color: '#6C6C6C',
    textAlign: 'justify',
    letterSpacing: '-0.68px',
    fontSize: 13
  },
  form: {

    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2, 0),
    marginLeft: theme.spacing(-1),
  },
  textAdaptCheckBox: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2, 1 / 2),
    marginLeft: theme.spacing(0),
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  input: {
    fontSize: '16px!important',
    fontWeight: '300!important'
  }
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const registerForm = useRef(null);
  const [disable, setDisable] = useState(true);
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    async function validateEmail() {
      if (email.length > 0) {
        let check = await validMail(email)
        if (check === false) setError(textsProfilePage.validEmail)
        else setError("")
      }
      else setError("")
    }
    validateEmail();
  }, [email]);

  const goToRegulation = async () => {
    window.open(process.env.REACT_APP_REGULATION_ADDRESS)
  };

  const handleAlert = async (event) => {
    event.preventDefault();
    raiseAlert(generalTexts.formDisable);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (error.length > 0) { raiseAlert(textsProfilePage.validEmail); return; }
    else if (email.length < 1 || company.length < 1) { raiseAlert(generalTexts.notEmpty); return; }
    else
      setRequesting(true)
    let data = {
      NOME: company,
      EMAIL: email
    }
    await cadastrarFornecedor(data).then(async (resp) => {
      if (resp.status === 200) {
        setCompany("");
        setEmail("");
        registerForm.current.reset();
        raiseSuccess(resp.data.message || textsCadastroPage.registerSuccess)
        history.push(`${mockPath}login`)
      } else {
        raiseAlert(resp.error)
      }
      setRequesting(false)
    }).catch(async err => {
      raiseAlert(err.error)
      setRequesting(false)
    });
  }

  return (
    <div className={`centralize ${classes.root}`}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} textAlign="center">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsCadastroPage.title}
            </Typography>
            <Box className={`xs-screen ${classes.subtitle}`}>
              <Typography variant="subtitle2">
                {textsCadastroPage.subtitle}
              </Typography>

              <Button color="primary" onClick={() => { history.push(`${mockPath}login`) }}
                className={`${classes.textAdapt}`}>
                <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                  {textsCadastroPage.link}
                </Typography>
                <span style={{ color: '#000000', textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}>.</span>
              </Button>

            </Box>
            <Box>
              <Typography className={classes.subtitle2} variant="subtitle2">
                <p>
                  {textsCadastroPage.subtitle2}
                </p>
                <p>
                  {textsCadastroPage.subtitle3}
                </p>
                {textsCadastroPage.subtitle4}
              </Typography>
            </Box>
          </Box>

          <form ref={registerForm} className={classes.form} noValidate
            onSubmit={event => { disable ? (handleAlert(event)) : (handleSignUp(event)) }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsCadastroPage.companyName}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="company"
                  type="company"
                  id="company"
                  className={classes.input}
                  onChange={event => setCompany(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsCadastroPage.email}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  className={classes.input}
                  onChange={event => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                {error.length > 0 ? (<Alert severity="error">{error}</Alert>) : ('')}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="agree"
                      style={{ marginTop: '-.8rem' }}
                      classes={{ root: classes.checkColor, checked: classes.checkColor }}
                      onChange={(event, value) => {
                        if (value) {
                          setDisable(false)
                        } else {
                          setDisable(true)
                        }
                      }} name="accord" />}
                  label={
                    <Grid container alignItems="center" spacing={-1}>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {textsCadastroPage.agreeWith}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button color="primary" onClick={goToRegulation}
                          className={`${classes.textAdaptCheckBox}`}>
                          <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                            código de conduta e ética
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" style={{ marginTop: '-.5rem' }}>
                          {textsCadastroPage.ofProgram}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={disable}
            >
              {requesting ? (
                <LineScalePulseOut
                  color={'#FFFFFF'}
                  className="loading-center"
                  loading={requesting}
                />
              ) : (
                textsCadastroPage.participate
              )}

            </Button>
            <Box mb={2} />
          </form>
        </Paper>

      </Container>
    </div>
  );
}