import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { TextareaAutosizeCustom } from '../TextareaAutosize/style'
import { SubmitButton } from '../SubmitButton/style';
import { FakeButton } from '../FakeButton/style';
import { UserContext } from '../../providers/UserProvider';
import { isBibiUser } from '../../utils/roles';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.35em',
        fontWeight: 600,
        color: "#FFFFFF",
        textTransform: 'none',
        margin: '0 auto 0 25px'
    },
    heading: {
        fontSize: 20,
        fontWeight: 600,
        color: '#000000'
    },
    subtitle: {
        color: "#000000",
        margin: '2em auto auto 25px',
    },
    textCenter: {
        textAlign: 'center'
    },
    right: {
        margin: 'auto auto auto 0'
    },
    caractersSize: {
        height: 'fit-content',
        margin: 'auto 0'
    },
    textAreaPlaceholder: {
        height: 'fit-content',
        letterSpacing: '-1.06px',

    }
}));

export default function FormTextAttch({
    handleSubmit,
    textTitle = '',
    textTitleSupplie = '',
    textAreaPlaceholder = '',
    textFooterDetail = '',
    textsSubmitButtons = '',
    haveTextArea = true,
    haveAttachment = true,
    partialAttacth = true,
    textInput = '',
    reset = false,
    disabled = false
}) {
    const classes = useStyles();
    let limitChars = 4000
    const [files, setFiles] = useState([]);
    const [text, setText] = useState(textInput ? textInput : '');
    const [remainChars, setRemainChars] = useState(limitChars);
    const { userState } = useContext(UserContext);
    const textForm = useRef(null);

    useEffect(() => {
        setRemainChars(limitChars - text.length)
    }, [text, limitChars])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (reset) {
            setText('')
            textForm.current.reset();
        }
    }, [reset])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isBibiUser(userState?.tipo)) {
            setText('')
            textForm.current.reset();
        }
    }, [reset])// eslint-disable-line react-hooks/exhaustive-deps

    const handleAdd = newFiles => {
        newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
        setFiles([...files, ...newFiles]);
    };

    const handleDelete = deleted => {
        setFiles(files.filter(f => f !== deleted));
    };

    return (
        <Box width={'100%'}>
            <form ref={textForm} onSubmit={(e) => handleSubmit(e, text, files)}>
                {haveTextArea ? <Box>
                    {textTitle !== '' ? <Box display="flex" mb={3}>
                        <Typography className={`${classes.heading} ${classes.right}`} component="h2" variant="h2">
                            {textTitle}
                        </Typography>
                        {textTitleSupplie !== '' ? <FakeButton>
                            {textTitleSupplie}
                        </FakeButton> : <Typography className={classes.caractersSize} component="p" variant="subtitle2">
                            {remainChars}
                        </Typography>}
                    </Box> : ''}

                    <TextareaAutosizeCustom
                        className={textTitle !== '' ? classes.textAreaPlaceholder : classes.textAreaPlaceholder}
                        maxLength={limitChars}
                        rowsMin={5}
                        value={text}
                        placeholder={textAreaPlaceholder}
                        onChange={(e) => setText(e.target.value)} />
                    {/*<Box mb={1} textAlign="initial">
                    <Typography className={classes.right} component="h2" variant="h2">
                            Proposta
                    </Typography>
                    </Box>
                     <Box className='div-area-text'>
                        <RichTextExample value="text" onChange={(e) => setText(e.target.value)} />
                    </Box> */}
                </Box> : ''}

                {haveAttachment ? <Box>
                    <Box mt={4} mb={4} display="flex">
                        <Typography className={classes.right} component="h2" variant="h2">
                            {partialAttacth ? '' : 'Anexos'}
                        </Typography>
                    </Box>

                    <Box>
                        <DropzoneAreaBase
                            dropzoneClass="dropzone"
                            dropzoneText="Clique para adicionar ou solte os arquivos aqui. Máximo até 10 mb"
                            filesLimit={5}
                            maxFileSize={1000000000}
                            showFileNames={true}
                            fileObjects={files}
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            showAlerts={false}
                        />
                    </Box>
                </Box> : ''}

                {textFooterDetail !== '' ? <Box mt={4} textAlign={'center'}>
                    <Typography component="p" className={classes.textCenter} variant="subtitle2">
                        {textFooterDetail}
                    </Typography>
                </Box> : ''}

                <Box mt={4}>
                    <SubmitButton type="submit" disabled={disabled}>
                        {textsSubmitButtons}
                    </SubmitButton>
                </Box>
            </form>
        </Box>
    )

}