import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export function ProfileHeader({ email, classes }) {
  return (
    <Box className={classes.orangeSet} textAlign="center">
      <Box paddingTop={2} height="100%" display="flex" className="header-div">
        <Typography
          className={classes.title}
          component="h1"
          variant="subtitle1"
        >
          {email}
        </Typography>
      </Box>
    </Box>
  );
}
