/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Grid } from '@material-ui/core';
// import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

export default function SupplierByGroup({ respData }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 5px #FE5D00',
      },
    },
    image: {
      height: 150,
      width: 150,
      borderRadius: 5,
      marginBottom: 8,
      objectFit: 'cover',
    },
    title: {
      color: '#000000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start',
    },
    media: {
      height: 140,
      display: 'flex',
    },
    expand: {
      margin: '0 0 0 auto',
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      //lineHeight: '1.8em',
      textAlign: 'start',
    },
    textLegend: { color: '#000000', textDecoration: 'none' },
    cardPadding: { padding: 0, '&:last-child': { padding: 0 } },
    crossed: {
      textDecoration: 'line-through',
    },
  }));

  const classes = useStyles();
  const cardRef = createRef();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const byGroup = respData.sort((a, b) => b.FORNECEDORES - a.FORNECEDORES);
  const data = {
    labels: byGroup.map(({ CATEGORIA }) => CATEGORIA),
    datasets: [
      {
        label: '',
        data: byGroup.map(({ FORNECEDORES }) => FORNECEDORES),
        backgroundColor: '#FE5D00',
      },
    ],
  };

  return (
    <Card ref={cardRef} className={classes.root}>
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left">
          <Typography
            className={classes.title}
            component="h1"
            variant="subtitle1"
          >
            Fornecedores por grupo
          </Typography>
        </Box>

        <Box display="flex" textAlign="left" padding={0}>
          <Grid container spacing={2} justify="center" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" justifyContent="center">
                <div style={{ width: '90%', position: 'relative' }}>
                  <Bar data={data} options={options} height={300} width={100} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
