import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import initPic from '../../assets/backgroundOne.png';
import { useHistory, useLocation } from 'react-router-dom';
import { textsLoginPage, mockPath } from '../../utils/utils';
import { raiseAlert, raiseSuccess } from '../../components/Alert';
import { LineScalePulseOut } from 'react-pure-loaders';
import { updatePassword } from '../../services/fornecedores';
import queryString from 'query-string'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${initPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    //marginTop: theme.spacing(8),
    //marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  title: {
    fontSize: 36
  },
  subtitle: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: 'center'
  },
  form: {

    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),


  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  input: {
    fontSize: '16px!important',
    fontWeight: '300!important'
  }
}));

export default function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [token, setToken] = useState(null);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {

    if (location?.pathname) {
      const value = queryString.parse(location?.search);
      const token = value.token;
      setToken(token)
    }

  }, [location])

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (passwordConfirmation !== password) {
      raiseAlert('Campos devem ser iguais!'); return;
    } else {
      setRequesting(true)
      let body = {
        SENHA: password,
        CONFIRMARSENHA: passwordConfirmation,
        TOKEN: token
      }
      await updatePassword(body).then(async (resp) => {
        if (resp.status === 200) {
          raiseSuccess(resp.data?.message)
          history.push(`${mockPath}login`)
        } else {
          raiseAlert(resp.error)
        }
        setRequesting(false)
      }).catch(async err => {
        raiseAlert(err.error)
        setRequesting(false)
      });
    }
  }


  return (
    <div className={`centralize ${classes.root}`}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} textAlign="center">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsLoginPage.newPassword}
            </Typography>
          </Box>

          <form className={classes.form} noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (passwordConfirmation.length < 1 || password.length < 1) {
                raiseAlert('Campos não podem ser vazios!'); return;
              } else {
                handleChangePassword(e)
              }
            }}
          >

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsLoginPage.password}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  className={classes.input}
                  onChange={event => setPassword(event.target.value)}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsLoginPage.confirmPassword}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirm-password"
                  type="password"
                  id="confirm-password"
                  className={classes.input}
                  onChange={event => setPasswordConfirmation(event.target.value)}
                  autoComplete="off"
                />
              </Grid>



            </Grid>
            <Box mt={2} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}

            >
              {requesting ? (
                <LineScalePulseOut
                  color={'#FFFFFF'}
                  className="loading-center"
                  loading={requesting}
                />
              ) : (
                textsLoginPage.send
              )}

            </Button>
            <Box mt={1} mb={2}>
              <Button color="primary" onClick={() => { history.push(`${mockPath}login`) }}
                className={`${classes.textAdapt}`}>
                <Typography color="inherit" className={`${classes.fakeLink}`} variant="subtitle1">
                  {textsLoginPage.returnLogin}
                </Typography>

              </Button>
            </Box>
          </form>
        </Paper>

      </Container>
    </div>
  );
}