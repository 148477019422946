import axios from 'axios';


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})


export default api;
// Add a response interceptor
api.interceptors.response.use(function (response) {

    if (response.data && response.data.statusCode) response.status = response.data.statusCode
    return response;
}, function (error) {
    return Promise.reject(error);
});