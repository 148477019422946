import React, { useState, useEffect } from "react";
import logoPortal from "../../../../assets/logoPortal.svg";
import TypeWriterEffect from "react-typewriter-effect";

import { Container, DivOne, DivTwo, Inovacao } from "./styles";
import { mockPath, textsSlideTop } from "../../../../utils/utils";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (windowSize.width <= 360) {
    return "30px";
  }
  if (windowSize.width <= 500) {
    return "45px";
  }
  if (windowSize.width <= 900) {
    return "53px";
  }
  return "70px";
}

export default function InovacaoComponent() {

  const size = useWindowSize();

  function ButtonClick() {
    window.location.replace(`${mockPath}cadastrar`)
  }

  return (
    <Container>
      <DivOne>
        <Inovacao src={logoPortal} alt="inovacao" />
        <ul>
          <li>
            <span>{textsSlideTop.partialText1}</span>
          </li>
          <li>
            <TypeWriterEffect
              textStyle={{
                fontFamily: "Nunito",
                color: "#FE5D00",
                fontWeight: 500,
                fontSize: size,
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
              startDelay={1000}
              cursorColor="#FE5D00"
              multiText={textsSlideTop.multiwords}
              loop={true}
              nextTextDelay={0}
              typeSpeed={50}
            />
          </li>
          <li>
            <span className="negocios">{textsSlideTop.partialText2}</span>
          </li>
        </ul>
      </DivOne>
      <DivTwo>
        <button onClick={ButtonClick}>{textsSlideTop.buttonText}</button>
      </DivTwo>
    </Container>
  );
}
