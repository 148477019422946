import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Box,
  Dialog,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import DialogFornecedores from '../ModalFornecedores';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { TableTitle } from '../BudgetTable/DetailModal';
import styled from 'styled-components';

export default function ApprovedSuppliers({
  percent,
  homologados,
  naoHomologados,
  suppliers,
}) {
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      // width: 'auto',
      // minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 5px #FE5D00',
      },
    },
    countUp: {
      fontSize: '48px',
    },
    title: {
      color: '#000000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start',
    },
    media: {
      height: 140,
      display: 'flex',
    },
    expand: {
      margin: '0 0 0 auto',
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'start',
    },
    cardPadding: { padding: 0, '&:last-child': { padding: 0 } },
  }));

  const classes = useStyles();
  const chartRef = useRef();
  const [selecteds, setSelecteds] = useState([]);
  const [tipo, setTipo] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: ['Homologados', 'Não homoloagados'],
    datasets: [
      {
        label: '',
        data: [homologados, naoHomologados],
        borderColor: ['#FF5E00', '#22A7F0'],
        backgroundColor: ['#FF5E00', '#22A7F0'],
      },
    ],
  };

  function handleClick(event) {
    const graph = getElementAtEvent(chartRef.current, event)[0];
    if (!graph) return;

    const index = graph?.index;
    let filtereds = [];
    if (index === 0) {
      filtereds = suppliers.filter(
        ({ HOMOLOGADO }) => HOMOLOGADO === '1' || HOMOLOGADO === 1
      );
      setTipo('Fornecedores Homologados')
    } else {
      filtereds = suppliers.filter(
        ({ HOMOLOGADO }) => HOMOLOGADO === '0' || HOMOLOGADO === 0
      );
      setTipo('Fornecedores Não Homologados')
    }

    const rows = [];
    let cols = [];
    for (let i = 0; i < filtereds.length; i++) {
      cols.push(filtereds[i].NOME);
      if ((i + 1) % 5 === 0) {
        rows.push(cols);
        cols = [];
      }
    }
    if (cols && cols.length > 0) {
      rows.push(cols);
    }

    setSelecteds(rows);
    setOpen(true)
  }
  return (
    <Card className={classes.root} id="tamanho">
      <DialogFornecedores
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left">
          <Typography
            className={classes.title}
            component="h1"
            variant="subtitle1"
          >
            Fornecedores homologados
          </Typography>
        </Box>
        <Box>
          <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
            <span style={{ fontSize: '48px' }} className={classes.countUp}>
              {percent}%
            </span>
            <div style={{ width: "80%", position: 'relative' }}>
              <Bar
                ref={chartRef}
                options={options}
                data={data}
                height={100}
                width={100}
                onClick={handleClick}
              />
            </div>
          </div>
        </Box>
      </CardContent>

      <Dialog
        fullWidth
        maxWidth="lg"
        scroll="paper"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="max-onClose-dialog-title"
        style={{ padding: 0 }}
      >
        <div style={{ padding: '1rem' }}>
          <TableTitle>{tipo}</TableTitle>
          <TableContainerCustom >
            <Table style={{ tableLayout: 'fixed' }}>
              {selecteds.map((row) => (
                <TableRow key={row}>
                  <TableCell style={{ fontWeight: 'normal' }} width={20}>{row[0]}</TableCell>
                  <TableCell style={{ fontWeight: 'normal' }} width={20}>{row[1]}</TableCell>
                  <TableCell style={{ fontWeight: 'normal' }} width={20}>{row[2]}</TableCell>
                  <TableCell style={{ fontWeight: 'normal' }} width={20}>{row[3]}</TableCell>
                  <TableCell style={{ fontWeight: 'normal' }} width={20}>{row[4]}</TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainerCustom>
        </div>
      </Dialog>
    </Card>
  );
}

export const TableContainerCustom = styled(TableContainer)`
  margin-top: 2rem;

  > table {
    display: table;
  }

  td {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
`;