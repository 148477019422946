import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  h1 {
    margin: 80px;
    text-align: center;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Nunito", Helvetica Neue, Helvetica, Arial, sans-serif;
    background: #fff;
    color: #4d5974;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* min-height: 100vh; */
  }
  .container {
    margin: 0 auto;
    max-width: 48rem;
    width: 80%;
    @media(max-width: 850px) {
      margin: auto;
      width: 80%;
    } 
  }
  h3 {
    font-size: 1.75rem;
    color: #373d51;
    padding: 1.3rem;
    margin: 0;
  }
  .accordion a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    color: #7288a2;
    font-size: 1.15rem;
    font-weight: 400;
    border-bottom: 1px solid #e5e5e5;
    @media(max-width: 850px) {
      font-size: 1rem;
    } 
  }
  .accordion a:hover,
  .accordion a:hover::after {
    cursor: pointer;
    color: #ff5353;
  }
  .accordion a.active {
    color: #ff5353;
    border-bottom: 1px solid #ff5353;
  }
  .accordion a::after {
    font-family: "Ionicons";
    content: "\f123";
    position: absolute;
    float: right;
    right: 0.5rem;
    font-size: 1rem;
    color: #7288a2;
    padding: 5px;
    align: "center";
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
  }
  .accordion a.active::after {
    font-family: "Ionicons";
    content: "\f126";
    color: #ff5353;
  }
  .accordion .content {
    opacity: 0;
    padding: 0 1rem;
    max-height: 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    clear: both;
    -webkit-transition: all 0.2s ease 0.15s;
    -o-transition: all 0.2s ease 0.15s;
    transition: all 0.2s ease 0.15s;
  }
  .accordion .content p {
    font-size: 1rem;
    font-weight: 300;
  }
  .accordion .content.active {
    opacity: 1;
    padding: 1rem;
    max-height: 100%;
    -webkit-transition: all 0.35s ease 0.15s;
    -o-transition: all 0.35s ease 0.15s;
    transition: all 0.35s ease 0.15s;
  }

  button {
    font-family: Nunito;
    font-size: 25px;
    color: #ffffff;
    height: 70px;
    width: 270px;
    background-color: #fe5d00;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Video = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  iframe {
    top: 0;
    left: 0;
    width: 50%;
    height: 500px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    height:500px;
  }
`;

export const LogoBibi = styled.img`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 300px;
  height: 100px;
`;

export const SpanBibi = styled.p`
  display: flex;
  justify-content: center;
  width: 94%;
  font-size: 15px;
  line-height: 3em;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

export const Copyright = styled.p`
  display: flex;
  justify-content: center;
  width: 94%;
  color: gray;
  font-size: 14px;
  line-height: 3em;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const Sustentabilidade = styled.img`
  width: 94%;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
`;

export const EmailSpan = styled.a`
  color: #fe5d00;
  text-decoration: none;
  @media (max-width: 490px) {
    font-size: 20px;
  }
`;
