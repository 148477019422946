import api from "./api"

export const getGroups = async () => {
  return await api.get('grupos');
}

export const addSubGroup = async (subgroup) => {
  return await api.post('subgrupos', subgroup);
}

export const updateSubgroup = async (id, subgroup) => {
  return await api.put(`subgrupos?ID=${id}`, subgroup);
}

export const removeSubGroup = async (id) => {
  return await api.delete(`subgrupos?ID=${id}`);
}

export const getSubgroupDocuments = async (id) => {
  const response = await api.get(`subgrupos/documentos?SUB_GRUPO=${id}`);
  return response.data;
}

export const addDocument = async (SUB_GRUPO, TIPO_DOCUMENTO) => {
  return await api.post(`subgrupos/documentos`, {
    SUB_GRUPO, TIPO_DOCUMENTO
  });
}

export const removeDocument = async (id) => {
  return await api.delete(`subgrupos/documentos?ID=${id}`);
}

export const getDocuments = async (id) => {
  return await api.get(`documentos`);
}

export const getDocumentsSupplier = async (supplier) => {
  return await api.get(`fornecedores/documentos?FORNECEDOR=${supplier}`);
}

export const addDocumentSupplier = async (FORNECEDOR, ID_TIPO_DOCUMENTO, CAMINHO, TIPO) => {
  return api.post('/fornecedores/documentos', {
    FORNECEDOR,
    ID_TIPO_DOCUMENTO,
    CAMINHO,
    TIPO,
  });
}

export const deleteDocSupplier = async (ID, f) => {
  return api.delete(`/fornecedores/documentos?ID_TIPO_DOCUMENTO=${ID}&FORNECEDOR=${f}`);
}


export const uploadFile = async (file) => {
  let formData = new FormData();
  formData.append('file', file);
  return await api.post(`utils/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}
