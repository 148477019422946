import styled from "styled-components";

export const ContainerMaior = styled.div``;

export const Container = styled.div`
  .wrapper {
    min-height: 100vh;
    font-family: "Nunito", Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #fff;
    position: relative;
  }
  section {
    height: 90vh;
    font-size: 40px;
    font-weight: 100;
    background-color: #22a7f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 850px) {
    .section {
      font-size: 30px;
      height: 70vh;
    }
  }

  section:nth-child(1) {
    background: white;
  }
  section:nth-child(2) {
    background: #f9690e;
  }
  section:nth-child(3) {
    background: #22a7f0;
  }
  section:nth-child(4) {
    background: #fc0;
  }
  section:nth-child(5) {
    background: #9b59b6;
  }
  section:nth-child(6) {
    background: #03c9a9;
  }
  section:nth-child(7) {
    background: #fc0;
  }
  .sec-text {
    padding-left: 100px;
    width: 60%;
    @media(max-width: 1000px) {
      margin-right: auto;
      width: 90%;
    } 
  }
  .nav__wrapper {
    position: fixed;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
  .nav {
    margin: 0 0 1400px 30px;
  }
  .nav__counter {
    font-size: 24px;
    transition: all 0.15s ease-out;
  }
  .nav__title {
    font-size: 32px;
    font-weight: 300;
    margin: 0 0 0.25em;
    width: 300px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease-out, opacity 0.2s ease-out;
  }
  .nav__body {
    font-weight: 100;
    font-size: 18px;
    font-size: 18px;
    width: 300px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease-out, opacity 0.2s ease-out;
  }
  .nav li {
    position: relative;
    transition: all 0.3s ease-out;
    margin-bottom: 1em;
  }
  .nav li:after {
    content: "";
    display: block;
    border-left: 2px solid white;
    border-top: 2px solid white;
    height: 250px;
    width: 20px;
    position: absolute;
    left: -30px;
    top: 15px;
  }
  .nav li a {
    display: block;
    padding: 0;
    color: #fff;
    transition: all 0.15s ease-out;
  }
  .nav li a:hover {
    background-color: transparent;
    padding-left: 1em;
  }
  .nav li a:focus {
    background-color: transparent;
  }
  .nav li.active {
    pointer-events: none;
    padding-left: 1em;
  }
  .nav li.active:after {
    width: 35px;
    height: 400px;
    top: 35px;
  }
  .nav li.active .nav__counter {
    font-size: 48px;
  }
  .nav li.active .nav__title {
    height: 40px;
    opacity: 1;
    overflow: visible;
  }
  .nav li.active .nav__body {
    height: 100px;
    opacity: 1;
    overflow: visible;
  }
`;
