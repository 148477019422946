/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { ButtonBase, Grid, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  InsertDriveFile,
  AssignmentTurnedInOutlined,
  AssignmentLateOutlined,
  ErrorOutline,
  RemoveRedEye,
  Delete,
} from '@material-ui/icons';

import clsx from 'clsx';

import {
  textsProfilePage,
  generalTexts,
  textsTextAreaPlaceholder,
  textsDropzone,
} from '../../../utils/utils';
import { validMail } from '../../../utils/functions';

import {
  InputFormChallenge,
  SelectFormChallenge,
} from '../../../components/FormChallenge/style';
import { InputNewModel, ButtonAddModel } from '../../../components/Model/style';
import { raiseAlert, raiseSuccess } from '../../../components/Alert';
import { TextareaAutosizeCustom } from '../../../components/TextareaAutosize/style';

import { useLoading } from '../../../providers/LoadingProvider';
import { UserContext } from '../../../providers/UserProvider';

import {
  getAllContatos,
  addContato,
  deleteContato,
} from '../../../services/contatos';
import {
  getFornecedor,
  getUsers,
  updateSupplier,
} from '../../../services/fornecedores';
import {
  addDocumentSupplier,
  deleteDocSupplier,
  getDocuments,
  getDocumentsSupplier,
  getGroups,
  getSubgroupDocuments,
  uploadFile,
} from '../../../services/subgroup';
import api from '../../../services/api';
import { addDays, formatDistanceStrict } from 'date-fns';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROLE_TYPE } from '../../../services/permission.service';
import { TableUsers } from '../../../components/Permission/TableUsers';
import { useStyles } from './styles';
import { ProfileHeader } from './components/ProfileHeader';
// import { ProfileForm } from './components/ProfileForm';
import { ProfileAdditionalData } from './components/ProfileAdditionalData';
import { Certificates } from '../../../components/Certificate/Certificates';
import { isBibiUser } from '../../../utils/roles';

export default function Profile() {
  const [profileName, setProfileName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const matches = useMediaQuery('(max-width:960px)');

  const [group, setGroup] = useState('');
  const [subGroup, setSubGroup] = useState('');
  const [bio, setBio] = useState('');

  // Novas variaveis (ou antigos revisadas)
  const limitCharsName = 50;
  const limitCharsBio = 600;

  const classes = useStyles();

  const [alreadyInitialRequest, setAlreadyInitialRequest] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState('');
  const [groups, setGroups] = useState([]);
  const [remainCharsBio, setRemainCharsBio] = useState(limitCharsBio);
  const [remainCharsName, setRemainCharsName] = useState(limitCharsName);
  const [subgroups, setSubgroups] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [users, setUsers] = useState([]);

  const { userState } = useContext(UserContext);
  const { loading, setLoading } = useLoading();

  const [state, setState] = useState();

  const { id } = useParams();

  useEffect(() => {
    const initialRequest = async () => {
      setLoading(true);
      try {
        const responseSupplier = await getFornecedor(id ? id : userState?.ID);
        const responseGroups = await getGroups();
        const responseContacts = await getAllContatos(id ? id : userState?.ID);

        const users = await getUsers(id ? id : userState?.ID);
        setUsers(users);

        const supplier = responseSupplier.data[0];
        setState(supplier);
        const idGroup = supplier.ID_GRUPO;

        setProfileName(supplier.NOME);
        setEmail(supplier.EMAIL);
        setBio(supplier.BIO);
        setGroup(idGroup);
        setGroups(responseGroups.data);
        setContacts(responseContacts.data);

        setSubgroups(
          responseGroups.data.find(({ ID }) => ID == idGroup)?.SUBGRUPOS
        );
        setSubGroup(supplier.SUB_GRUPO);

        setAlreadyInitialRequest(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAlreadyInitialRequest(true);
      }
    };

    if (!alreadyInitialRequest) {
      initialRequest();
    }
  }, [id, setLoading, alreadyInitialRequest, userState]);

  useEffect(() => {
    async function req() {
      if (!subGroup) {
        setDocuments([]);
        return;
      }
      const responseDocuments = await getDocumentsSupplier(
        id ? id : userState?.ID
      );
      const documentSubgroup = await getSubgroupDocuments(subGroup);
      const docs = await getDocuments();
      setDocuments(
        documentSubgroup.map((item) => {
          const { DESCRICAO } = docs.data.find(
            ({ ID }) => ID == item.TIPO_DOCUMENTO
          );
          let docSupplier;
          let distance;
          if (Array.isArray(responseDocuments.data)) {
            docSupplier = responseDocuments.data.find(
              ({ ID_TIPO_DOCUMENTO }) =>
                ID_TIPO_DOCUMENTO == item.TIPO_DOCUMENTO
            );
            if (docSupplier?.DATA_ADICAO) {
              distance = formatDistanceStrict(
                new Date(),
                new Date(docSupplier?.DATA_ADICAO),
                {
                  unit: 'day',
                }
              ).replace(' days', '');
            }
          }

          let status = 'faltando';
          if (docSupplier?.DATA_ADICAO) {
            if (distance && distance > 365) {
              status = 'vencido';
            } else {
              status = 'ok';
            }
          }

          return {
            ...item,
            DESCRICAO,
            STATUS: status,
            VALIDADE: docSupplier?.DATA_ADICAO
              ? new Date(
                  addDays(new Date(docSupplier?.DATA_ADICAO), 365)
                ).toLocaleDateString()
              : null,
            CAMINHO: docSupplier?.CAMINHO ? docSupplier?.CAMINHO : '',
            ID: docSupplier?.ID ? docSupplier?.ID : '',
          };
        })
      );
    }

    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subGroup]);

  useEffect(() => {
    setSubGroup(null);
  }, [group]);

  useEffect(() => {
    if (profileName) setRemainCharsName(limitCharsName - profileName.length);
  }, [profileName, limitCharsName]);

  useEffect(() => {
    if (bio?.length) {
      setRemainCharsBio(limitCharsBio - bio.length);
    }
  }, [bio, limitCharsBio]);

  useEffect(() => {
    async function validateEmail() {
      if (email.length > 0) {
        let check = await validMail(email);
        if (check === false) setError(textsProfilePage.validEmail);
        else setError('');
      } else setError('');
    }
    validateEmail();
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onSave() {
    setLoading(true);
    try {
      await updateSupplier(id ? id : userState?.ID, {
        NOME: profileName,
        SUB_GRUPO: subGroup,
        BIO: bio,
      });
      setLoading(false);
      setTimeout(() => raiseSuccess('Dados atualizados'), 600);
    } catch (e) {
      setLoading(false);
      setTimeout(() => raiseAlert('Erro ao atualizar seus dados'), 600);
    }
  }

  async function addContact(e) {
    e.preventDefault();

    if (error.length > 0) {
      raiseAlert(textsProfilePage.validEmail);
      return;
    }

    if (email.length < 1 || name.length < 1) {
      raiseAlert(generalTexts.notEmpty);
      return;
    }

    try {
      const EMAIL = email.toLowerCase();
      const NOME = name
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
      await addContato({ FORNECEDOR: id ? id : userState?.ID, EMAIL, NOME });

      setName('');
      setEmail('');
      setContacts((contacts) => [...contacts, { EMAIL, NOME }]);
    } catch ({ error }) {
      raiseAlert(error);
    }
  }

  async function removeContact(id) {
    if (!id) {
      raiseAlert(
        'Ocorreu um erro ao exluir esse contato. Por favor, recarrege a tela e tente novamente'
      );
      return;
    }

    try {
      await deleteContato(id);
      setContacts((contacts) => [...contacts.filter(({ ID }) => ID != id)]);
      raiseSuccess();
    } catch ({ error }) {
      raiseAlert(error);
    }
  }

  async function handleDeleteImage() {
    setLoading(true);
    setState((s) => ({ ...s, CAMINHO_ARQUIVO: '' }));
    await updateSupplier(userState.fornecedor, {
      CAMINHO_ARQUIVO: '',
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleAddDocument = async (event, typeDocument) => {
    const file = event[0].file;
    const type = event[0].file.type;
    setLoading(true);

    try {
      await deleteDocSupplier(typeDocument, id ? id : userState?.ID);
      const response = await uploadFile(file);
      await addDocumentSupplier(
        id ? id : userState?.ID,
        typeDocument,
        response.data.url,
        type
      );
      setDocuments((documents) =>
        documents.map((doc) => {
          if (doc.TIPO_DOCUMENTO == typeDocument) {
            const today = new Date();
            today.setFullYear(today.getFullYear() + 1);

            return {
              ...doc,
              STATUS: 'ok',
              VALIDADE: today.toLocaleDateString(),
              CAMINHO: response.data.url,
            };
          }

          return doc;
        })
      );
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  const handleAddImage = async (newImage) => {
    setLoading(true);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let img = new FormData();
    img.append('file', newImage[0].file);
    await api
      .post('utils/upload', img, config)
      .then(async (response) => {
        setState((s) => ({ ...s, CAMINHO_ARQUIVO: response.data.url }));
        await updateSupplier(userState.fornecedor, {
          CAMINHO_ARQUIVO: response.data.url,
        });
        return response.data.url || null;
      })
      .catch((e) => raiseAlert('Erro ao fazer upload. Tente Novamente'))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={classes.root}
      style={matches ? { marginBottom: '12rem' } : {}}
    >
      <ToastContainer />
      <Container component="main" className={classes.container}>
        {/* TODO: PASSAR OBJETO supplier */}
        <ProfileHeader classes={classes} email={email} />

        <Box mt={2}>
          {/* {state?.NOME &&
            <ProfileForm
              classes={classes}
              supplier={state}
              groups={groups}
            />
          } */}

          <Box className={`header-div mob ${classes.filterDiv}`}>
            <Grid container spacing={matches ? 0 : 3} item xs={12}>
              <Grid item xs={12} md={5}>
                <Box display="flex">
                  <Typography
                    className={`${classes.label} ${classes.marginH2Init}`}
                    component="h2"
                    variant="h2"
                  >
                    {textsProfilePage.titleName}
                  </Typography>
                  <Typography
                    className={`${classes.label} ${classes.caractersSize}`}
                    component="p"
                    variant="subtitle2"
                  >
                    {remainCharsName}
                  </Typography>
                </Box>
                <InputFormChallenge
                  value={profileName}
                  maxLength={limitCharsName}
                  onChange={(e) => setProfileName(e.target.value)}
                />
              </Grid>
              <Grid
                item
                className={`${classes.boxFildsMobile} ${classes.paddingLeft} ${classes.marginField}`}
                xs={12}
                md={4}
                style={matches ? { marginTop: '1rem' } : {}}
              >
                <Typography
                  className={`${classes.label} ${classes.marginH2Init}`}
                  style={{ width: 'fit-content' }}
                  component="h2"
                  variant="h2"
                >
                  {textsProfilePage.titleGroup}
                </Typography>

                <SelectFormChallenge
                  value={group}
                  onChange={(e) => {
                    setSubgroups(
                      groups.find(({ ID }) => ID == e.target.value).SUBGRUPOS
                    );
                    setGroup(e.target.value);
                  }}
                  displayEmpty
                >
                  <option value="vazio">Selecione</option>
                  {groups?.map((model) => (
                    <option key={model?.ID} value={model?.ID}>
                      {model?.NOME}
                    </option>
                  ))}
                </SelectFormChallenge>
              </Grid>

              <Grid
                item
                className={`${classes.boxFildsMobile} ${classes.paddingLeft} ${classes.marginField}`}
                xs={12}
                md={3}
                style={matches ? { marginTop: '1rem' } : {}}
              >
                <Typography
                  className={`${classes.label} ${classes.marginH2Init}`}
                  style={{ width: 'fit-content' }}
                  component="h2"
                  variant="h2"
                >
                  Subgrupo
                </Typography>

                <SelectFormChallenge
                  value={subGroup}
                  onChange={(e) => setSubGroup(e.target.value)}
                  displayEmpty
                >
                  <option value="vazio">Selecione</option>
                  {subgroups?.map(({ ID, SUB_GRUPO }) => (
                    <option key={ID} value={ID}>
                      {SUB_GRUPO}
                    </option>
                  ))}
                </SelectFormChallenge>
              </Grid>

              <Grid
                container
                item
                className={classes.boxTextImage}
                xs={12}
                md={9}
              >
                <Box
                  mt={2}
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Typography
                    className={`${classes.label} ${classes.right}`}
                    component="h2"
                    variant="h2"
                  >
                    {textsProfilePage.titleBio}
                  </Typography>
                  <Typography
                    className={`${classes.label} ${classes.caractersSize}`}
                    component="p"
                    variant="subtitle2"
                  >
                    {remainCharsBio}
                  </Typography>
                </Box>
                <TextareaAutosizeCustom
                  className={classes.textAreaPlaceholder}
                  maxLength={limitCharsBio}
                  placeholder={textsTextAreaPlaceholder.textBioFormProfille}
                  rowsMin={5}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </Grid>

              <Grid
                item
                className={clsx(
                  `gridDropzoneImage ${classes.boxTextImage} ${classes.paddingLeft}`
                )}
                xs={12}
                md={3}
                style={matches ? { marginTop: '1rem' } : {}}
              >
                <Box
                  mt={2}
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Typography
                    className={`${classes.label} ${classes.right}`}
                    component="h2"
                    variant="h2"
                  >
                    Apresentação
                  </Typography>
                </Box>
                <div className="dropzone-document-container">
                  <DropzoneAreaBase
                    acceptedFiles={['.pdf', 'application/pdf']}
                    showFileNames
                    dropzoneClass={`dropzone-doc dropzoneImage-doc dropzoneImage-doc-ap`}
                    dropzoneText={
                      !state?.CAMINHO_ARQUIVO ? (
                        <span
                          style={{
                            fontSize: '.8rem',
                            marginTop: '27px',
                            padding: '0 2rem',
                            textAlign: 'center',
                          }}
                        >
                          {textsDropzone.textBioProfileImage}
                        </span>
                      ) : (
                        <InsertDriveFile className={classes.documentIcon} />
                      )
                    }
                    showAlerts={false}
                    filesLimit={1}
                    maxFileSize={1000000000}
                    onAdd={handleAddImage}
                  />

                  {state?.CAMINHO_ARQUIVO && (
                    <PreviewFile path={state?.CAMINHO_ARQUIVO} />
                  )}

                  {state?.CAMINHO_ARQUIVO && (
                    <RemoveFile onClick={handleDeleteImage} />
                  )}
                </div>
              </Grid>
              <Grid
                container
                item
                className={classes.boxTextImage}
                xs={12}
                style={matches ? { marginTop: '1rem' } : {}}
              >
                <button className={classes.buttonSave} onClick={() => onSave()}>
                  Salvar
                </button>
              </Grid>
            </Grid>
          </Box>

          <ProfileAdditionalData additionalData={state?.DADOS_SFT ?? []} />

          <Box display="flex" style={{ marginTop: '25px' }}>
            <Box className={`header-div mob ${classes.filterDiv}`}>
              <Typography
                className={classes.tittleContact}
                component="h2"
                variant="subtitle1"
              >
                Documentos
              </Typography>
              <p
                style={{
                  color: '#6c6c6c',
                  padding: 0,
                  margin: 0,
                  fontWeight: 'normal',
                }}
              >
                Você deve manter esses documentos atualizados para que possamos
                continuar fazendo negócios.
              </p>
              <p
                style={{
                  color: '#6c6c6c',
                  padding: 0,
                  margin: 0,
                  fontWeight: 'normal',
                }}
              >
                {documents.length > 0
                  ? 'Clique no documento para fazer o upload.'
                  : 'Selecione um subgrupo para poder adicionar os DOCUMENTOS'}
              </p>

              <Grid
                container
                className={classes.boxGridFildsMobile}
                spacing={3}
                style={
                  matches
                    ? {
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }
                    : { marginTop: '10px' }
                }
                item
                xs={12}
              >
                {documents &&
                  documents.map((doc, index) => {
                    return (
                      <Grid key={index} item xs={5} sm={4} md={3} lg={2}>
                        <div className={classes.documentContainer}>
                          <label className={classes.documentDescription}>
                            {doc.DESCRICAO}
                          </label>
                        </div>
                        <DropzoneDocument
                          doc={doc}
                          handleAddDocument={handleAddDocument}
                          classes={classes}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>

          {userState.tipo == 'FA' && (
            <TableUsers
              type={userState.tipo}
              users={users}
              supplier={userState?.ID}
              roleType={ROLE_TYPE.SUPPLIER}
            />
          )}

          <Certificates supplier={id ? id : userState?.ID} />

          <Box display="flex" style={{ marginTop: '55px' }}>
            <Box className={`header-div mob ${classes.filterDiv}`}>
              <Typography
                className={classes.tittleContact}
                component="h2"
                variant="subtitle1"
              >
                {textsProfilePage.contacts}
              </Typography>
              {loading ? (
                ''
              ) : contacts.length > 0 ? (
                contacts?.map((item, index) => (
                  <Button
                    key={`contact-button${index}`}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <label className={classes.labelButtonIcon}>
                      {item?.EMAIL || 'teste@teste.com'}
                    </label>
                    <CloseIcon
                      fontSize={'small'}
                      onClick={(e) => removeContact(item?.ID)}
                      className={classes.zIndexFeatured}
                    />
                  </Button>
                ))
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box pt={'1em'} textAlign="start">
                    <Box>
                      <Typography
                        className={classes.textInfo}
                        variant="subtitle2"
                      >
                        {textsProfilePage.noContacts}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Box>
          </Box>
          <Box className={`header-div mob ${classes.filterDiv}`} paddingTop={2}>
            <Typography
              className={classes.textInfo}
              component="p"
              variant="subtitle1"
            >
              {textsProfilePage.emailNotify}
            </Typography>
            <form noValidate onSubmit={addContact}>
              <InputNewModel
                value={name}
                placeholder="nome"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <InputNewModel
                value={email}
                placeholder="e-mail"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <ButtonAddModel type="submit" className={classes.button}>
                <Typography style={{ textTransform: 'none' }}>
                  {textsProfilePage.add}
                </Typography>
              </ButtonAddModel>
              {error.length > 0 ? <Alert severity="error">{error}</Alert> : ''}
            </form>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

function DropzoneDocument({ doc, handleAddDocument, classes }) {
  const { STATUS, CAMINHO, TIPO_DOCUMENTO, VALIDADE } = doc;
  const { userState } = useContext(UserContext);

  const titles = {
    faltando: 'Faltando',
    ok: (
      <>
        <p
          style={{
            margin: '0',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#07C8A9',
            textAlign: 'center',
          }}
        >
          Valido até:
        </p>
        <p
          style={{
            margin: '5px 0 0 0',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#07C8A9',
            textAlign: 'center',
          }}
        >
          {VALIDADE}
        </p>
      </>
    ),
    vencido: 'Vencido',
  };

  const title = titles[STATUS];

  const icons = {
    faltando: (
      <AssignmentLateOutlined
        className={classes.documentIcon}
        htmlColor="#6C6C6C"
      />
    ),
    ok: (
      <AssignmentTurnedInOutlined
        className={classes.documentIcon}
        htmlColor="#07C8A9"
      />
    ),
    vencido: (
      <ErrorOutline className={classes.documentIcon} htmlColor="#FFCC2F" />
    ),
  };

  const icon = icons[STATUS];

  return (
    <div className="dropzone-document-container">
      <DropzoneAreaBase
        acceptedFiles={['.pdf', 'application/pdf', '.png', '.jpg', '.jpeg']}
        showFileNames
        dropzoneClass={`dropzone-doc dropzoneImage-doc ${STATUS}`}
        dropzoneText={
          <>
            {icon}
            <span
              style={{
                margin: '5px 0 0 0',
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#6C6C6C',
              }}
            >
              {title}
            </span>
          </>
        }
        showAlerts={false}
        filesLimit={1}
        maxFileSize={1000000000}
        onAdd={(event) => {
          handleAddDocument(event, TIPO_DOCUMENTO, doc);
        }}
      />

      {STATUS == 'vencido' && isBibiUser(userState.tipo) && (
        <PreviewFile path={CAMINHO} />
      )}
      {STATUS == 'ok' && <PreviewFile path={CAMINHO} />}
    </div>
  );
}

function PreviewFile({ path }) {
  const classes = useStyles();
  return (
    <Box className={`${classes.iconCont} dp-icon`}>
      <ButtonBase
        className={classes.iconButton}
        onClick={() => window.open(`https://cxd.bibi.com.br:3636${path}`)}
      >
        <RemoveRedEye />
      </ButtonBase>
    </Box>
  );
}

function RemoveFile({ onClick }) {
  const classes = useStyles();
  return (
    <Box className={`${classes.iconDelete} dp-icon`}>
      <ButtonBase className={classes.iconButton} onClick={onClick}>
        <Delete htmlColor="#f01212" />
      </ButtonBase>
    </Box>
  );
}
