import { TextareaAutosize } from "@material-ui/core";
import styled from "styled-components";

export const TextareaAutosizeCustom = styled(TextareaAutosize)`
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 15px;
    font-family: inherit;
    font-size: 16px
    box-shadow: 0px 0px 0px #6C6C6C;
    border: 1px solid #00000029;
    border-radius: 5px;
    outline: none;
    resize: none;
    &:focus {
        outline: none;
    }
`