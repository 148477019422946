import api from './api';

const route = 'fornecedores/certificados/';


export const CertificateService = {
  async all() {
    const response = await api.get(route)
    return response.data
  },

  async getBySupplier(supplier) {
    const response = await api.get(`${route}${supplier}`)
    return response.data
  },

  async add(supplierId, certificateId) {
    return await api.post(route, {
      supplierId, certificateId
    })
  },

  async remove(supplier, id) {
    return await api.delete(`${route}${supplier}?certificateId=${id}`)
  }
}