import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import FakeButtonStatus from '../FakeButtonStatus'
import { FakeButton } from '../FakeButton/style';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import YouTube from 'react-youtube';
import { Grid, FormControlLabel } from '@material-ui/core';
import ListFakeButtons from '../ListFakeButtons';
import ListFiles from '../ListFiles';
import { generalTexts, textsTextAreaPlaceholder, textsSubmitButtons } from '../../utils/utils';
import logoBibi from "../../assets/logo.svg";
import FormTextAttch from '../FormTextAttch';
import { propostaUpdateDesafiosWithParams } from '../../services/propostas';
import { uploadFile } from '../../services/file';
import { raiseSuccess, raiseAlert } from '../Alert';
import { UserContext } from '../../providers/UserProvider';
import { SelectFormChallenge } from '../FormChallenge/style';
import ListOptionsArray from '../ListOptionsArray';
import { isBibiUser, isSupplierUser } from '../../utils/roles';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '28px'/* 28 */,
        fontWeight: 600,
        color: "#FFFFFF",
        textTransform: 'none',
        margin: '0 auto 0 25px',
        '@media(max-width: 938px)': {
            minHeight: 85,
            maxHeight: 85,
            fontSize: 25,
            margin: '15px auto 10px auto',
            textAlign: 'center'
        },
        '@media(min-width: 938px) and (max-width: 1200px)': {
            minHeight: 68,
        },
    },
    subtitle: {
        color: "#000000",
        marginTop: '15px',
        '@media(max-width: 938px)': {
            textAlign: 'justify'
        }
    },
    header: {
        display: 'flex',
        width: '100%',
        '@media(max-width: 938px)': {
            display: 'grid',
        }
    },
    descriptionDiv: {
        marginBottom: '0px',
        marginLeft: '25px',
        marginTop: '0',
        maxWidth: '70%',
        '@media(max-width: 938px)': {
            maxWidth: '100%',
            marginLeft: '10px',
        }
    },
    titleStatusDiv: {
        display: 'flex',
        '@media(max-width: 1200px)': {
            display: 'grid',
        }
    },
    gridContainer: {
        display: 'flex'
    },
    conntentAcordion: {
        width: '100%',
        boxShadow: '0px 0px 0px #6C6C6C',
        border: '1px solid #00000029',
        borderRadius: '5px'
    },
    accordion: {
        padding: '6px',
        margin: '0 !important',
        border: '1px solid #00000029',
        borderLeft: '0px',
        borderRight: '0px',
        borderRadius: '0 !important'
    },
    accordionTop: {
        padding: '6px',
        margin: '0 !important',
        borderRadius: '5px 5px 0 0 !important'
    },
    accordionBottom: {
        padding: '6px',
        margin: '0 !important',
        borderRadius: '0 0 5px 5px !important'
    },
    fileItem: {
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '5px'
    },
    heading: {
        fontSize: 20,
        fontWeight: 600,
        color: '#000000'
    },
    videoPlayer: {
        margin: 'auto',
        width: '100%'
    },
    media: {
        height: 140,
        maxHeight: 140,
        minHeight: 140,
        width: '100%',
        maxWidth: 160,
        minWidth: 160,
        margin: '0 auto'
    },
    right: {
        margin: 'auto auto auto 0'
    },
    liFile: {
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '0 15px'
    },
    iconFile: {
        fontSize: 70,
        color: '#C6C6C6'
    },
    align: {
        alignItems: 'center'
    }
}));

export default function ChallengeStatus({
    challenge = {},
    //permission
    canEdit = false,
    canEditAttachments = false,
    accordionOpen = false,
    // Status
    haveStatus = false,
    textStatus = '',
    // Config accordion
    haveAccordionDescription = true,
    haveAccordionVideo = true,
    haveAccordionMaterial = true,
    haveAccordionProposal = false,
    haveAccordionAttachment = false,
    //callback function
    setUpdateChallenge
}) {
    const classes = useStyles();

    const STATUS_PROPOSTA = ['Inscrito', 'Proposta enviada', 'Proposta aceita', 'Testes', 'Implementado', 'Negado']
    const [status, setStatus] = useState(challenge?.STATUS_PROPOSTA || textStatus)
    const [edit, setEdit] = useState(false);
    const [editAttach, setEditAttach] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cardImage, setCardImage] = useState('');
    const { userState } = useContext(UserContext);

    useEffect(() => {
        setStatus(challenge?.STATUS_PROPOSTA || textStatus)
    }, [challenge])// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const opts = {
        height: '390',
        width: '100%',
    };

    function _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const handleEdit = () => {
        if (edit) setEdit(false)
        else setEdit(true)
    }
    const handleEditAttach = () => {
        if (editAttach) setEditAttach(false)
        else setEditAttach(true)
    }

    const handleSubmitStatus = async (e) => {
        e.preventDefault();
        //debugger
        //setStatus(e.target.value)

        let data = {
            STATUS: e.target.value
        }
        await propostaUpdateDesafiosWithParams(userState?.PROP_ID, data).then((response) => {
            if (response?.status === 200) {
                raiseSuccess(response.data.message);
                setUpdateChallenge(true)
                setEdit(false)
            }
            else {
                raiseAlert(response.error)
            }
        }).catch(err => {
            raiseAlert(err.error)
        })

    }

    const handleUpdateProposal = async (e, text) => {
        e.preventDefault();

        let data = {
            PROPOSTA: text
        }
        await propostaUpdateDesafiosWithParams(userState?.PROP_ID, data).then((response) => {
            if (response?.status === 200) {
                raiseSuccess(response.data.message);
                setUpdateChallenge(true)
                setEdit(false)
            }
            else {
                raiseAlert(response.error)
            }
        }).catch(err => {
            raiseAlert(err.error)
        })
    }
    const handleUpdateAttachments = async (e, text, files) => {
        e.preventDefault();
        await uploadFile(userState?.PROP_ID, files).then(async response => {


            response.some(item => {
                if (item?.error) {
                    return raiseAlert(item?.error)
                }
                return '';
            })
            setUpdateChallenge(true)
            setEditAttach(false)

        }).catch(err => {
            raiseAlert(err.error)
        })
    }

    useEffect(() => {
        if (challenge?.IMAGEM) {
            setCardImage((process.env.REACT_APP_BASE_URL + challenge?.IMAGEM))
        } else {
            setCardImage(logoBibi)
        }
    }, [challenge])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container>
            <Box className={classes.header}>
                <Card className={classes.media}>
                    <CardMedia
                        className={classes.media}
                        image={cardImage || logoBibi}
                        title="Challenge Image"
                    />
                    {/* </CardMedia> */}
                </Card>
                <Box width={"100%"} display="grid">
                    <Box className={classes.titleStatusDiv} >
                        <Typography className={classes.title} component="h2" variant="subtitle1">
                            {challenge.NOME}
                        </Typography>

                        {haveStatus && (isSupplierUser(userState?.tipo)) ? <FakeButtonStatus className={classes.right} text={textStatus} /> :
                            haveStatus && (isBibiUser(userState?.tipo)) ?
                                <SelectFormChallenge
                                    width={'180px'}
                                    value={status}
                                    onChange={(e) => handleSubmitStatus(e)}
                                    displayEmpty
                                >
                                    <ListOptionsArray createOptions={STATUS_PROPOSTA} />
                                </SelectFormChallenge> : ''}
                    </Box>
                    <Box className={classes.descriptionDiv} textAlign="justify">
                        <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                            {challenge.OBJETIVO}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {challenge?.AREAS ? <ListFakeButtons modelsArray={challenge.AREAS} /> : ''}

            <Box className={classes.conntentAcordion} mt={3}>
                {haveAccordionDescription ? <Accordion defaultExpanded={accordionOpen ? true : false} square={true} className={classes.accordionTop} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading} component="h2">Detalhes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="h2" variant="subtitle2">
                            {!challenge?.DETALHES || challenge?.DETALHES.length < 1 ? (
                                <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                    {generalTexts.noInfo}
                                </Typography>
                            ) : (
                                <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                    {challenge.DETALHES}
                                </Typography>
                            )}
                        </Typography>
                    </AccordionDetails>
                </Accordion> : ''}

                {haveAccordionVideo ? <Accordion defaultExpanded={accordionOpen ? true : false} square={true} className={classes.accordion} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} component="h2">Vídeo</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex' }}>
                        <YouTube videoId={challenge.URL_VIDEO} onReady={(e) => _onReady(e)} opts={opts} containerClassName="videoPlayer" />
                    </AccordionDetails>
                </Accordion> : ''}

                {haveAccordionMaterial ? <Accordion defaultExpanded={accordionOpen ? true : false} square={true} className={haveAccordionProposal ? classes.accordion : classes.accordionBottom} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading} component="h2">Materiais complementares</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {challenge?.MATERIAIS?.length < 1 ? (
                            <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                {generalTexts.noInfo}
                            </Typography>
                        ) : (
                            <ListFiles files={challenge?.MATERIAIS} isMaterials canEdit={canEdit} />
                        )}

                    </AccordionDetails>
                </Accordion> : ''}

                {haveAccordionProposal ? <Accordion expanded={edit || expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    square={true}
                    className={classes.accordion} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"

                    >
                        <Box mr={1} display="flex" className={classes.align} >
                            <Typography className={classes.heading} component="h2">Proposta</Typography>
                            {!(isBibiUser(userState?.tipo)) ? (<React.Fragment>
                                <Box ml={2} mr={1} />
                                <FormControlLabel
                                    aria-label="control_add"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={<FakeButton margin="auto auto auto 15px" onClick={handleEdit}>
                                        {edit ? 'Cancelar' : 'Editar'}
                                    </FakeButton>}
                                    label=""
                                />
                            </React.Fragment>
                            ) : ''}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex' }}>

                        {edit ? (
                            <FormTextAttch
                                handleSubmit={handleUpdateProposal}
                                textAreaPlaceholder={textsTextAreaPlaceholder.textChallengeProposal}
                                textsSubmitButtons={textsSubmitButtons.textChallengeEditProposal}
                                haveAttachment={false}
                                textInput={challenge?.PROPOSTA}
                            //reset={reset}
                            //disabled={disabled}
                            />
                        ) : (!challenge?.PROPOSTA || challenge?.PROPOSTA.length < 1 ? (
                            <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                {generalTexts.noInfo}
                            </Typography>
                        ) : (
                            <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                {challenge?.PROPOSTA}
                            </Typography>
                        ))}

                    </AccordionDetails>
                </Accordion> : ''}

                {haveAccordionAttachment ? <Accordion expanded={editAttach || expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                    square={true}
                    className={classes.accordionBottom} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                        aria-label="Expand"
                    >
                        <Box mr={1} display="flex" className={classes.align}>
                            <Typography className={classes.heading} component="h2">Anexos</Typography>
                            {!(isBibiUser(userState?.tipo)) ? (<React.Fragment>
                                <Box ml={2} mr={1} />
                                <FormControlLabel
                                    aria-label="control_add"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={<FakeButton margin="auto auto auto 15px" onClick={handleEditAttach}>
                                        {editAttach ? 'Cancelar' : 'Adicionar'}
                                    </FakeButton>}
                                    label=""
                                />
                            </React.Fragment>) : ''}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex' }}>
                        {editAttach ? (
                            <FormTextAttch
                                handleSubmit={handleUpdateAttachments}
                                haveTextArea={false}
                                haveAttachment={true}
                                textsSubmitButtons={textsSubmitButtons.textChallengeAttachment}
                                partialAttacth={true}
                            //reset={reset}
                            //disabled={disabled}
                            />
                        ) : (challenge?.ANEXOS?.length < 1 ? (
                            <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                                {generalTexts.noInfo}
                            </Typography>
                        ) : (
                            <ListFiles files={challenge?.ANEXOS}
                                canEdit={canEditAttachments}
                                isAttachment
                                setUpdateChallenge={setUpdateChallenge} />
                        ))}

                    </AccordionDetails>
                </Accordion> : ''}
            </Box>
        </Grid>
    )
}