import React, { createRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';

import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoModal from '../InfoModal';

export default function StockGraph({ stock }) {
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 5px #FE5D00',
      },
    },
    countUp: {
      fontSize: '48px',
    },
    title: {
      color: '#000000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start',
      marginRight: '1rem',
    },
    media: {
      height: 140,
      display: 'flex',
    },
    expand: {
      margin: '0 0 0 auto',
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'start',
    },
    cardPadding: { padding: 0, '&:last-child': { padding: 0 } },
  }));

  const classes = useStyles();
  const cardRef = createRef();

  function handleSelect() {}

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        // position: 'top',
      },
    },
  };

  const labels = [
    'Jan/2024',
    'Fev/2024',
    'Mar/2024',
    'Abr/2024',
    'Mai/2024',
    'Jun/2024',
    'Jul/2024',
    'Ago/2024',
    'Set/2024',
    'Out/2024',
    'Nov/2024',
    'Dez/2024',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Estoque',
        data: stock
          .map((item) => item.VALOR_ESTOQUE)
          .filter((item) => item > 0),
        borderColor: '#22A7F0',
        backgroundColor: '#22A7F0',
      },
      {
        label: 'Meta Estoque',
        data: stock.map((item) => item.META_ESTOQUE).filter((item) => item > 0),
        borderColor: '#FE5D00',
        backgroundColor: '#FE5D00',
      },
      {
        label: 'Dormente',
        data: stock
          .map((item) => item.VALOR_DORMENTE)
          .filter((item) => item > 0),
        borderColor: '#FFCC2F',
        backgroundColor: '#FFCC2F',
      },
      {
        label: 'Meta Dormente',
        data: stock
          .map((item) => item.META_DORMENTE)
          .filter((item) => item > 0),
        borderColor: '#06C8A9',
        backgroundColor: '#06C8A9',
      },
    ],
  };

  return (
    <Card ref={cardRef} className={classes.root}>
      <CardContent className={classes.cardPadding} padding={0}>
        <Box textAlign="left" display="flex" alignItems="center">
          <Typography
            className={classes.title}
            component="h1"
            variant="subtitle1"
          >
            Estoque de matéria prima
          </Typography>
          <InfoOutlinedIcon
            htmlColor="#FE5D00"
            fontSize={'small'}
            onClick={() => setOpen(true)}
          />
        </Box>
        <Box textAlign="left" mt={2}>
          <div style={{ width: '98%', position: 'relative' }}>
            <Line options={options} data={data} height={300} width={100} />
          </div>
        </Box>
        <LegendContainer item xs={12} sm={12} md={12} lg={12}>
          <LegendItem color="#22A7F0" onClick={() => handleSelect()}>
            <div className="square">
              <div />
            </div>
            <span>Estoque</span>
          </LegendItem>
          <LegendItem color="#FE5D00" onClick={() => handleSelect()}>
            <div className="square">
              <div />
            </div>
            <span>Meta Estoque</span>
          </LegendItem>
          <LegendItem color="#FFCC2F" onClick={() => handleSelect()}>
            <div className="square">
              <div />
            </div>
            <span>Dormente</span>
          </LegendItem>
          <LegendItem color="#06C8A9" onClick={() => handleSelect()}>
            <div className="square">
              <div />
            </div>
            <span>Meta Dormente</span>
          </LegendItem>
        </LegendContainer>
      </CardContent>
      <InfoModal open={open} handleClose={() => setOpen(false)} type="stock" />
    </Card>
  );
}

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 2rem;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .square {
    height: 3px;
    width: 70px;
    background: ${(props) => props.color};
    position: relative;

    > div {
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: ${(props) => props.color};
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
  }
`;
