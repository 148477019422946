import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root:{
    flexGrow:1,
    maxWidth:'inherit'
  },
  container:{
    paddingLeft: 0,
    paddingRight: 0,
    
  },
  finished:{
    border: '1px solid #FFFFFF',
    backgroundColor:theme.palette.primary.main,
    '&:hover':{
        backgroundColor:theme.palette.primary.main
    }
  },
  finishedText:{
    textTransform:'capitalize',
    color:theme.palette.secondary.main
  },
   selected:{
    backgroundColor:theme.palette.secondary.main,
    '&:hover':{
        backgroundColor:theme.palette.secondary.main
    }
  },
  selectedText:{
    textTransform:'capitalize',
    color:theme.palette.default.main
  },
  title: {
    fontSize:36,
    color:"#000000",
    textTransform:'none',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    alignItems:"baseline",
    color:"#000000",
    fontSize:22,
  },
  form: {
    
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink:{
    backgroundColor:'unset',  
    '&:hover':{
        backgroundColor:'unset',
        textDecoration: 'underline',
    }
  },
  textAdapt:{
    color:"#FE5D00",
    textTransform:'none',
    backgroundColor: 'transparent!important',
    padding:theme.spacing(1/2),
   

  },
  orangeSet: {
    backgroundColor:"#FE5D00",
    //minHeight: '30vh',
    // maxHeight: '30vh',
    padding: '0rem 0rem 5rem 0rem'
   
  },
  whiteSet: {
    backgroundColor:"#FFFFFF",
    height: 0,
    
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem'
  },
  notFound: {
    width:'100%'
  }
 
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Container component="main" className={classes.container}>
            <Box className={`orangeDiv ${classes.orangeSet}`} textAlign="center">
                <Box paddingTop={2} textAlign="center" className="header-div"/>
            </Box>
            <Box className={classes.whiteSet}>
              <Box className={`header-div ${classes.mainCards}`}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Box pt={'6em'} textAlign="center">
                    <Typography className={classes.title} component="h1" variant="subtitle1">
                      Oops..
                    </Typography>
                    <Box className={`box-ops ${classes.notFound}`} >
                      <Typography className={classes.subtitle} variant="subtitle2">
                        Página não encontrada.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
            {/* <Box>
              <Faq/>
            </Box> */}
        
        </Container> 
    </div>
  );
}
