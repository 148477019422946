import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { SettingsService } from '../../services/settings';

const useStyles = makeStyles((theme) => ({
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  tittleContact: {
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  copyButton: {
    width: '20%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export function TableSettings({ settings }) {
  const classes = useStyles();

  async function update({ NOME, DESCRICAO, ID }, value) {
    try {
      await SettingsService.update({
        ID,
        NOME,
        VALOR: value,
        DESCRICAO,
      });
      toast(`Configuração ${NOME} atualizada.`, {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    } catch (e) {
      toast(`Erro ao atualizar configuração ${NOME}.`, {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    }
  }

  return (
    <>
      <Box display="flex" style={{ marginTop: '25px' }}>
        <Box className={`header-div mob ${classes.filterDiv}`}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Descrição
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Valor</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.map((setting) => {
                    return (
                      <TableRow key={setting.ID}>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {setting.NOME}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {setting.DESCRICAO}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }} width="18%">
                          <Input
                            style={{ width: '100%' }}
                            type="text"
                            defaultValue={setting.VALOR}
                            onBlur={(e) => update(setting, e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export const Input = styled.input`
  width: 50px;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  padding: 5px 0.8rem;
  text-align: left;
  &:focus {
    outline: none;
  }
`;
