import React,  { useContext, useEffect, useState } from 'react';
import ChallengeStatus from '../../../components/ChallengeStatus';
import { Box, Container, makeStyles } from '@material-ui/core';
import { textsSubmitButtons, textsTextAreaPlaceholder, generalTexts } from '../../../utils/utils';
import TimelineComponent from '../../../components/Timeline';
import FormTextAttch from '../../../components/FormTextAttch';
import { UserContext } from '../../../providers/UserProvider';
import { raiseAlert, raiseSuccess } from '../../../components/Alert';
import { getPropostaDetails } from '../../../services/propostas';
import { bibi } from '../../../utils/utils';
import { createMensagem } from '../../../services/mensagens';


const useStyles = makeStyles((theme) => ({
    root:{
      flexGrow:1
    },
    container:{
      height: 'calc(100vh - 5em)',
      maxHeight: 'calc(100vh - 5em)',
      minHeight: 'calc(100vh - 5em)',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: 'white'
    },
    fakeBox: {
        height:'100%',
        width: '100%'
    },
    mainCards: {
        width: '70vw!important',
        margin: 'auto!important',
        position: 'relative',
        top: '3em',
        '@media(max-width: 938px)' : {
            top: '1em',
            width: '70vw!important',
        },
    },
    title: {
        fontSize:28,
        fontWeight: 600,
        color:"#FFFFFF",
        textTransform:'none',
        margin: '0 auto 0 25px'
    },
    subtitle: {
        color:"#000000",
        margin: '2em auto auto 25px',
        '@media(max-width: 938px)' : {
            margin: '6em auto auto 25px',
        },
    },
    orangeSet: {
        backgroundColor:"#FE5D00",
        //height:'25vh',
        height:'7em',
        '@media(max-width: 938px)' : {
            //height:'60vh'
            height:'16em',
      }
    },
    right: {
        margin: 'auto auto auto 0'
    },
    caractersSize: {
        height: 'fit-content',
        margin: 'auto 0'
    },
    textAreaPlaceholder: {
        height: 'fit-content',
        letterSpacing: '-1.06px',
    },
    timelineContent: {
        display: 'flex',
        padding: '0 !important',
        marginLeft: '15px',
        width: '100%',
        minWidth: '100%'
    }
}));

export default function MyAdminChallengeProposal() {
    const classes = useStyles();
    const { userState } = useContext(UserContext);
    const [challenge, setChallenge] = useState({});
    const [reset, setReset] = useState(false)
    const [updateChallenge, setUpdateChallenge] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        getChallenge();
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
       if(updateChallenge) {getChallenge();}
    },[updateChallenge])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       if(!disabled){
           setReset(false);
           getChallenge();
       } 
    },[disabled])// eslint-disable-line react-hooks/exhaustive-deps


    async function getChallenge() {
        await getPropostaDetails(userState?.PROP_ID).then((response) => {
            if(response?.status === 200){
                setChallenge(response.data);
            }else{
                raiseAlert(response.error)
            }
            setUpdateChallenge(false)
        }).catch(err=>{
            setUpdateChallenge(false)
            raiseAlert(err.error)
        })
    }

    const handleSubmit = async (e, text) => {
        e.preventDefault();
        if(text.length < 1){raiseAlert(generalTexts.notEmpty);return; }
        if(!userState?.PROP_ID) { raiseAlert(generalTexts.errorMessage); return;}
        else
        setDisabled(true)
        var data = {
            AUTOR: bibi[0],
            DESCRICAO: text
        }
        await createMensagem(userState?.PROP_ID,data).then((response) => {
                if(response?.status === 200){
                    raiseSuccess(response.data?.message);
                }
                else{
                  raiseAlert(response.error)
                }
                setReset(true)
                setDisabled(false)
        }).catch(err=>{
            setReset(true)
            raiseAlert(err.error)
            setDisabled(false)
        }) 
         
    }

    return (
        <div className={classes.root}>
            <Container component="main" className={classes.container}>
                <Box className={classes.orangeSet} textAlign="center">
                    <Box className={`whiteSet ${classes.mainCards}`}>
                        <Box className={`box`}>
                            <ChallengeStatus
                                challenge={challenge}
                                haveStatus={true}
                                textStatus={challenge.STATUS_PROPOSTA}
                                haveAccordionDescription = {true}
                                haveAccordionVideo       = {true}
                                haveAccordionMaterial    = {true}
                                haveAccordionProposal    = {true}
                                haveAccordionAttachment  = {true}
                                setUpdateChallenge={setUpdateChallenge}
                                
                            />
                            <Box mt={4} mb={4}>
                                <hr/>
                                </Box>
                            <FormTextAttch 
                                handleSubmit={handleSubmit}
                                textTitle = 'Mensagem'
                                textTitleSupplie={userState?.PROP_NOME_F}
                                textAreaPlaceholder = {textsTextAreaPlaceholder.textChallengeProposal}
                                textsSubmitButtons = {textsSubmitButtons.textChallengeSubscriber}
                                haveAttachment = {false}
                                reset={reset}
                                disabled={disabled}
                            />

                            <TimelineComponent
                                textTitle="Histórico"
                                timelineItemArray = {challenge?.HISTORICOS}
                                orientationContent= 'left'
                                orientationItem='timelineRight'
                                marginDate={['auto auto auto 15px', 'auto auto auto 15px']}
                            />

                        {/*      <Footer /> */}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}