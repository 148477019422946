import styled from "styled-components";

export const InputFormChallenge = styled.input`
    height: 2.5em;
    padding: 0.5em 1em;
    color: #000000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #6C6C6C;
    border-radius: 5px;
    opacity: 1;
    border: 1px solid #C6C6C6;
    font-size: 14px;
    font: normal 300 1rem Nunito !important;
    letter-spacing: -1.06px;
    width: 100%;
    &:focus {
        outline: none;
    }
`
export const SelectFormChallenge = styled.select`
    height: 2.5em;
    width: ${props => props.width ? props.width : '100%'};
    padding: 0 1.5em 0 0.5em;
    border: 1px solid #C6C6C6;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #6C6C6C;
    border-radius: 5px;
    text-align: left;
    letter-spacing: -1.06px;
    opacity: 1;
    font: normal 300 1rem Nunito !important;
    font-size: 14px;
    color: #000000;
    &:focus {
        outline: none;
    }
`
