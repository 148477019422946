/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Grid, Typography, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CertificateService } from '../../services/certificates';

const toastConfig = {
  position: 'bottom-right',
  hideProgressBar: true,
};

function useCertificates(supplier) {
  const [certificates, setCertificates] = useState([]);

  async function getCertificates() {
    const all = await CertificateService.all();
    const supplierCertificates = await CertificateService.getBySupplier(
      supplier
    );

    const permissionsWithChecked = all.map((item) => ({
      ...item,
      checked: supplierCertificates.some(
        ({ CERTIFICADO }) => CERTIFICADO === item.ID
      ),
    }));

    setCertificates(permissionsWithChecked);
  }

  useEffect(() => {
    getCertificates();
  }, []);

  async function handleChangeCheckbox(id) {
    const isChecked = certificates.find((p) => p.ID === id).checked;

    if (isChecked) {
      await removeCertificate(id);
    } else {
      await addCertificate(id);
    }

    const mappedCertificates = certificates.map((certificate) => ({
      ...certificate,
      checked:
        certificate.ID === id ? !certificate.checked : certificate.checked,
    }));
    setCertificates(mappedCertificates);
  }

  async function addCertificate(certificate) {
    try {
      await CertificateService.add(supplier, certificate);
      toast('Certificado adicionado!', toastConfig);
    } catch (e) {
      toast('Erro ao adicionar certificado. Tente novamente!', toastConfig);
    }
  }

  async function removeCertificate(permission) {
    try {
      await CertificateService.remove(supplier, permission);
      toast('Certificado removido!', toastConfig);
    } catch (e) {
      toast('Erro ao remover certificado. Tente novamente!', toastConfig);
    }
  }

  return {
    certificates,
    handleChangeCheckbox,
  };
}

const useStyles = makeStyles((theme) => ({
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  tittleContact: {
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  copyButton: {
    width: '20%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
}));

export function Certificates({ supplier, isAdminScreen = false }) {
  const classes = useStyles();
  const { certificates, handleChangeCheckbox } = useCertificates(supplier);

  return (
    <>
      <Box display="flex" style={{ margin: '25px 0' }}>
        <Box
          className={isAdminScreen ? '' : `header-div mob ${classes.filterDiv}`}
        >
          {!isAdminScreen && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                className={classes.tittleContact}
                component="h2"
                variant="subtitle1"
              >
                Certificados
              </Typography>
            </div>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              {certificates.map(({ ID, DESCRICAO, checked }) => {
                return (
                  <Grid item xs={12} sm={3} md={3} lg={3} key={ID}>
                    <Checkbox
                      classes={{
                        root: classes.checkColor,
                        checked: classes.checkColor,
                      }}
                      value={checked}
                      id={DESCRICAO}
                      checked={checked}
                      onChange={() => handleChangeCheckbox(ID)}
                    />
                    <label htmlFor={DESCRICAO} style={{ cursor: 'pointer' }}>
                      {DESCRICAO}
                    </label>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
