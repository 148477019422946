import styled from "styled-components";

export const Video = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: #000000;
  margin-left: auto;
  margin-right: auto;
  iframe {
    top: 0;
    left: 0;
    width: 50%;
    height: 500px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    height:500px;
  }
`;
