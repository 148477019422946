import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 15px;
  min-width: 50%;
  margin-top: 35px;
  /* max-width: 40%; */

  > .circle {
    border: solid 4px #FE5D00;
    height: 100px;
    width: 100px;
    min-width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 580px) {
      height: 80px;
      width: 80px;
      min-width:80px;
    }

    > img {
      width: 45px;
      height: 45px;

      @media (max-width: 580px) {
        height: 35px;
        width: 35px;
      }
    }
  }

  > .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100px;
    padding: 10px 0;
    
    > h2 {
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-size: 22px;

      @media (max-width: 580px) {
        font-size: 18px;
      }
    }

    > span {
      font-weight: 400;
      color: #B7B7B7;
    }
  }
`;