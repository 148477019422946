import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { textsMainPage } from '../../../utils/utils';
import CardCountUp from '../../../components/CardCountUp';
import { raiseAlert } from '../../../components/Alert';
import { UserContext } from '../../../providers/UserProvider';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { getSupplierData } from '../../../services/home';
import { setAuthToken } from '../../../services/token';
import { isBibiUser } from '../../../utils/roles';
import SupplierByGroup from '../../../components/SupplierByGroup';
import StockGraph from '../../../components/StockGraph';
import api from '../../../services/api';
import BudgetTable from '../../../components/BudgetTable';
import ApprovedSuppliers from '../../../components/ApprovedSuppliers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  finished: {
    border: '1px solid #FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  finishedText: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  selectedText: {
    textTransform: 'capitalize',
    color: theme.palette.default.main
  },
  title: {
    fontSize: 36,
    color: "#FFFFFF",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    alignItems: "baseline",
    color: "#FFFFFF"
  },
  form: {

    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),


  },
  orangeSet: {
    backgroundColor: "#FE5D00",
    padding: '0rem 0rem 5rem 0rem'

  },
  whiteSet: {
    backgroundColor: "#FFFFFF",
    height: 0,

  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem'
  }

}));

export default function Home() {
  const classes = useStyles();
  const [loadSuppliers, setLoadSuppliers] = useState(0)
  const [byGroups, setByGroups] = useState([])
  const { userState, setUserState } = useContext(UserContext);
  const [stock, setStock] = useState([])
  const [budget, setBudget] = useState([])
  const [supplierInfo, setSupplierInfo] = useState({})


  useEffect(() => {
    api.get('fornecedores/estoque').then(e => {
      setStock(e.data)
    })
    const date = new Date()
    const month = date.getMonth() + 1;
    const year = date.getFullYear()
    api.get(`fornecedores/orcamento?month=${month}&year=${year}`).then(e => {
      setBudget(e.data)
    })
    api.get(`fornecedores`).then(e => {
      let suppliers = e.data.map(({ HOMOLOGADO }) => HOMOLOGADO);
      const homologados = suppliers.filter(item => item === "1" || item === 1).length
      const naoHomologados = suppliers.filter(item => item === "0" || item === 0).length
      const percent = Math.round((homologados * 100) / suppliers.length)
      setSupplierInfo({
        suppliers: e.data,
        percent, homologados,
        naoHomologados
      })
    })
    async function loadSupplier() {
      await getSupplierData().then(async resp => {
        if (resp?.status === 200) {
          setLoadSuppliers(resp?.data?.FORNECEDORES)
          setByGroups(resp?.data?.CATEGORIAS)
        } else {
          if (resp.error && resp.error.includes('acesso expirou')) {
            setAuthToken(null)
            setUserState({ sessionToken: null })

          }
          raiseAlert(resp.error)
        }
      }).catch(async err => {
        raiseAlert(err.error)
      });
    }
    loadSupplier();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box className={clsx(`${classes.orangeSet} `, {
          'fit': isBibiUser(userState?.tipo)
        })}
          textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsMainPage.homeTitle}
            </Typography>
          </Box>
        </Box>
        <Box className={`header-div ${classes.whiteSet}`}>
          <Box className={`${classes.mainCards}`}>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={3} md={3} lg={3} >
                <CardCountUp suppliersNumber={loadSuppliers} />
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} >
                <ApprovedSuppliers {...supplierInfo} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                {byGroups.length > 0 && <SupplierByGroup respData={byGroups} />}
              </Grid>
            </Grid>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <StockGraph stock={stock} />
              </Grid>
            </Grid>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <BudgetTable budget={budget} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}