import React from "react";
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    color: '#C6C6C6',
    fontWeight: '400',
    fontSize: ' 14px'
  },
  textCenter: {
    textAlign: 'center'
  }
}))

export default function Footer() {
  const classes = useStyles();

  return (
    <Box component="footer" >
      <Typography className={`${classes.textCenter} ${classes.footer}`}>
        Copyright 2021. Todos os Direitos Reservados. Calçados Bibi LTDA | RS 239 Km 28,4 nº 3600 | CEP: 95630-000 | Parobé - RS Brasil. <Version />
      </Typography>
    </Box>
  );
}

function Version() {
  return <>v1.7.0</>
}