import styled from 'styled-components';

const Container = styled.button`
  background: #ec660c;
  font-weight: 600;
  font-family: 'Nunito';
  line-height: 21.82px;
  font-size: 16px;
  padding: 6px 16px;
  border-radius: 5px;
  border: none;
  color: #fff;
`;

export function Button({ title, children, ...props }) {
  return <Container {...props}>{title ? title : children}</Container>;
}
