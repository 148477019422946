import { makeStyles } from '@material-ui/core/styles';
import initPic from '../../assets/backgroundOne.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${initPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  title: {
    fontSize: 36,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  subtitle2: {
    color: '#6C6C6C',
    textAlign: 'justify',
    letterSpacing: '-0.68px',
    fontSize: 13,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    },
  },
  textAdapt: {
    color: '#FE5D00',
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2, 0),
    marginLeft: theme.spacing(-1),
  },
  textAdaptCheckBox: {
    color: '#FE5D00',
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2, 1 / 2),
    marginLeft: theme.spacing(0),
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FE5D00',
    },
  },
  input: {
    fontSize: '16px!important',
    fontWeight: '300!important',
  },
}));
