import styled from "styled-components";

export const Title = styled.h1`
  text-align: center;
  margin: 80px;
`;

export const DesafiosComponent = styled.div`
  height: 50px; 
  background-color: #ffffff;
  width: 100%;
  @media (max-width: 800px) {
    margin-bottom: 3150px;
  }

  #timeline {
    display: flex;
    background-color: #f36f2a;
    @media (max-width: 800px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tl-item{
        width: 100%;
      }
      .tl-content{
        margin-top: 350px;
      }
    }

    &:hover {
      .tl-item {
        width: 23.3333%;
        @media (max-width: 800px) {
          width: 100%;
        }
      }
    }
  }

  .tl-item {
    transform: translate3d(0, 0, 0);
    width: 25%;
    /* height: 100vh; */
    min-height: 600px;
    color: #fff;
    overflow: hidden;
    transition: width 0.5s ease;

    &:before,
    &:after {
      transform: translate3d(0, 0, 0);
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      // background: transparentize(#421c06, 0.15);
      background: transparentize(#ffbe99, 0.15);
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    &:before {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 75%
      );
      z-index: 1;
      opacity: 0;
      transform: translate3d(0, 0, 0) translateY(50%);
      transition: opacity 0.5s ease, transform 0.5s ease;
    }

    &:hover {
      width: 30% !important;
      @media (max-width: 800px) {
        width: 100% !important;
      }

      &:after {
        opacity: 0;
      }

      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) translateY(0);
        transition: opacity 1s ease, transform 1s ease 0.25s;
      }

      .tl-content {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.75s ease 0.5s;
      }

      .tl-bg {
        filter: grayscale(0);
      }
    }
  }

  .tl-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin: 0 1.618em;
    top: 55%;
    opacity: 0;

    h1 {
      font-family: "Nunito", Helvetica Neue, Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      padding-top: 20px;
      color: #f36f2a;
      font-size: 1.44rem;
      font-weight: normal;
    }
  }

  .tl-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
    border-bottom: 1px solid #fff;
    // border-bottom: 1px solid #fff;

    p {
      font-family: "Nunito", Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 1.728rem;
      line-height: 1;
    }
  }

  .tl-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }

  .tl-cta {
    background-color: #f3702a;
    font-family: "Nunito", Helvetica Neue, Helvetica, Arial, sans-serif;
    border-radius: 10px;
    // display:inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    padding: 8px 20px;
    text-decoration: none;
  }
`;

export const ModalVideo = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; 
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
  /* @media(max-width: 1200px) {
    width:30%;
  } */
  @media(max-width: 1114px) {
    width:50%;
  }
  @media(max-width: 1000px) {
    width: 75%;
  } 
  button {
    margin-top: 20px;
    font-family: Nunito;
    font-size: 22px;
    height: 50px;
    color: #ffffff;
    width: 30%;
    background-color: #FE5D00;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover{
        cursor: pointer;
    }
    @media(max-width: 1114px){
      font-size: 17px;
    }
    @media(max-width: 486px){
      font-size: 12px;
    }
  }
`;

export const Video = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: #000000;
  margin-left: auto;
  margin-right: auto;
  iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    height:500px;
  }
`;