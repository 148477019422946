/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Inovacao from './components/Inovacao';
import { Container } from '../../styles';
import Oportunidades from './components/Oportunidades';
import Sustentabilidade from './components/Sustentabilidade';
import Sobre from './components/Sobre';
import Modal from './components/Modal';
import { Box } from '@material-ui/core';
import Premiacao from './components/Premiacao';
import Footer from './components/Footer';
import { useLocation } from 'react-router-dom';
import { SupplierDay } from './components/SupplierDay';

function LandPage() {
  const location = useLocation();
  const [nomeDesafio] = useState('');
  const [anchor, setAnchor] = useState('');



  useEffect(() => {
    let anchor = window.localStorage.getItem('anchor');

    if (
      (location?.hash && location?.hash !== '') ||
      (anchor && anchor !== '')
    ) {
      if (location.hash) setAnchor(location?.hash);
      else if (anchor) setAnchor(anchor);
    }
  }, [location]);

  useEffect(() => {
    goToAnchor();
  }, [anchor]);

  function goToAnchor() {
    if (anchor && anchor !== '') {
      document.querySelector(anchor).scrollIntoView({
        behavior: 'smooth',
      });
      toggleMenu();
    }
  }

  function toggleMenu() {
    const menu = document.querySelector('.menu-toggle');
    const nav = document.querySelector('.site-nav');
    nav.classList.toggle('site-nav-open');
    menu.classList.toggle('open');
  }

  return (
    <Box display="block">
      <Header />
      <Container>
        <Inovacao />
        <Oportunidades />
        <SupplierDay />
        <Premiacao />
        <Sustentabilidade />
        <Sobre />
        <Modal nomeDesafio={nomeDesafio} />
      </Container>
      <Footer />
    </Box>
  );
}

export default LandPage;



