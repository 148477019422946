import React, { useState } from "react";
import Header from "../LandPage2.0/components/Header";
import { Container } from "../../styles";
import Programa from "./components/Programa";
import Desafios from "./components/Desafios";
import ComoFunciona from "./components/ComoFunciona";
import StyledMenu from "./components/StyledMenu";
import Faq from "./components/Faq";
// import Footer from "./components/Footer";
import Modal from "./components/Modal";
import { Box } from "@material-ui/core";


function LandPage() {

  const[nomeDesafio, setNomeDesafio] = useState('');

  function addNomeDesafio(nome){
    setNomeDesafio(nome);
  }

  return (
    <Box display="block">
      <Header redirectBack={false}/>
      <Container>
        <Programa />
        <Desafios onAddDesafio={addNomeDesafio}/>
        <ComoFunciona />
        <StyledMenu />
        <Faq />
        <Modal nomeDesafio={nomeDesafio}/>
      </Container>
    </Box>
  );
}

export default LandPage;
