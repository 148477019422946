/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import { isBibiUser } from '../../../utils/roles';
import { ToastContainer } from 'react-toastify';
import { SettingsService } from '../../../services/settings';
import { TableSettings } from '../../../components/TableSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  title: {
    fontSize: 36,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    padding: '0rem 0rem 5rem 0rem',
  },
  whiteSet: {
    backgroundColor: '#FFFFFF',
    height: 0,
  },
  mainCards: {
    marginTop: '40px',
  },
}));

export function Settings() {
  const { userState } = useContext(UserContext);
  const classes = useStyles();

  const [settings, setSettings] = useState([]);

  async function fetchUsers() {
    const data = await SettingsService.getAll();
    setSettings(data);
    // setUsers(users);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={classes.root}>
      <ToastContainer />
      <Container component="main" className={classes.container}>
        <Box
          className={clsx(`${classes.orangeSet} `, {
            fit: isBibiUser(userState?.tipo),
          })}
          textAlign="center"
        >
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              Configurações
            </Typography>
          </Box>
        </Box>
      </Container>
      <TableSettings settings={settings} />
    </div>
  );
}
