import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: '0px 24px',
    '@media(max-width: 938px)': {
      maxWidth: '90%',
      margin: 24,
    },
  },
  mediaChallenge: {
    height: 115,
    width: 245,
    margin: 'auto'
  },
  mediaPartner: {
    height: 115,
    width: 115,
    margin: 'auto'
  },
  mediaBoard: {
    height: 113,
    width: 113,
    margin: 'auto'
  },
  textLoc: {
    color: '#6C6C6C',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '27.28px',
    textAlign: 'center'
  },
  textAdapt: {
    margin: '8px 0 8px',
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#FE5D00',
    }
  },
  textButton: {
    textTransform: 'none',
    backgroundColor: '#FE5D00',
    color: '#FFFFFF',
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '33px',
  }
});

export default function InitialOpportunityCard({ item, index }) {
  const classes = useStyles();


  function handleRedirect(path) {

    window.location.replace(`${path}`)
  }
  return (
    <Card className={classes.root} elevation={0}>

      <CardMedia
        className={clsx('', {
          [classes.mediaBoard]: index === 0,
          [classes.mediaPartner]: index === 1,
          [classes.mediaChallenge]: index === 2,
        })}
        image={item.image}
        title={item.text}
      />
      <CardContent>
        <Typography className={classes.textLoc} component="p">
          {item.text}
        </Typography>
      </CardContent>

      <Box display="flex" justifyContent="center">

        <Button color="primary" className={`${classes.textAdapt}`} onClick={() => { handleRedirect(item?.path) }} fullWidth>
          <Typography className={classes.textButton}>
            {item.buttonText}
          </Typography>
        </Button>

      </Box>
    </Card>
  );
}