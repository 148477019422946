import React from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import { FakeButton } from '../FakeButton/style';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { generalTexts } from '../../utils/utils';
//import { ptBR } from 'date-fns/locale';
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize:24,
        fontWeight: 600,
        color:"#000000",
        textTransform:'none',
        textAlign: 'left'
    },
    title2: {
        fontSize:18,
        fontWeight: 600,
        color:"#000000",
        textTransform:'none',
        textAlign: 'left',
        margin: '15px auto 15px 15px'
    },
    subtitle: {
        fontSize:14,
        fontWeight: 500,
        color:"#6C6C6C",
        textAlign: 'start',
        margin: 'auto auto auto 15px',
    },
    timelineContainer: {
        flex: '0 !important',
        padding: '0 !important',
    },
    timelineContent: {
        padding: '0 !important',
        minHeight: '106px!important'
    },
    dotConfig: {
        borderWidth: '3px',
        padding: '5px'
    },
    orangeBorderColor: {
        borderColor: '#FE5D00'
    },
    orangeBackgroundColor: {
        backgroundColor: '#FE5D00'
    },
    greenBorderColor: {
        borderColor: '#06C8A9'
    },
    greenBackgroundColor: {
        backgroundColor: '#06C8A9'
    }
}));

export default function TimelineComponent({
textTitle = '',
orientationContent= 'alternate',
orientationItem='timelineMiddle',
timelineItemArray=[
    {
        textTitle2: 'Sem remetente',
        subtitle: 'Sem mensagem',
        orangeColor: false
    }   
],
// Left
marginDate=['auto auto auto 15px', 'auto 15px auto auto'] 
// Right
//marginDate='auto 15px auto auto' 
// Middle
// marginDate='auto'
// ---------- //
// orientationContent [left, alternate, right]
// ---------- //
// orientationItem [timelineLeft, timelineMiddle, timelineRight]
// ---------- //

}) {
    const classes = useStyles();
    
    return (
        <Grid>
            { textTitle !== '' ? <Box mt={3} mb={2} textAlign={'left'}>
                <Typography className={classes.title} component="h2">
                    {textTitle}
                </Typography>
            </Box> : ''}
            <Timeline align={orientationContent} className={classes.timelineContainer}>
                { timelineItemArray?.length > 0 ? (
                    timelineItemArray.map((item,index) => {
                    return (
                        <React.Fragment key={`timeline_${index}`}>
                            <TimelineItem className={orientationItem}>
                            <TimelineSeparator>
                               { item?.AUTOR === 'Bibi' 
                                    ? <TimelineDot className={clsx(classes.orangeBorderColor, classes.dotConfig)} variant="outlined" /> 
                                    : (item?.AUTOR === 'Sistema' ? <TimelineDot className={clsx(classes.greenBorderColor, classes.dotConfig)} variant="outlined" /> 
                                        : <TimelineDot  className={classes.dotConfig} variant="outlined" /> )}
                               { item?.AUTOR === 'Bibi' 
                                    ? <TimelineConnector className={classes.orangeBackgroundColor} /> 
                                    : ( item?.AUTOR === 'Sistema' 
                                        ? <TimelineConnector className={classes.greenBackgroundColor} />
                                        : <TimelineConnector />)}
                            </TimelineSeparator>
                            <TimelineContent className={classes.timelineContent}>
                                <Box>
                                    <Box display="flex" >
                                        <FakeButton margin={marginDate[0]} >
                                            {item?.CRIACAO ? (format(parseISO(item?.CRIACAO.substring(0,item?.CRIACAO.length)), 'dd/MM/yyyy HH:mm:ss')) : 'Sem informação' }
                                        </FakeButton>
                                    </Box>

                                    <Box display="grid">
                                        <Typography className={classes.title2} component="h2">
                                            {item?.AUTOR || item.textTitle2}
                                        </Typography>
                                        <Typography className={classes.subtitle} component="h2">
                                            {item?.DESCRICAO || item.subtitle}
                                        </Typography>
                                    </Box>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                        </React.Fragment>
                    )
                }) ):(
                    <Typography className={classes.subtitle} component="p" variant="subtitle2" >
                        {generalTexts.noInfo}
                    </Typography>
                )}
            </Timeline>
        </Grid>
    )
}