/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { InputNewModel, ButtonAddModel, TagInput } from './style';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getAllModels, createModel, updateModel, deleteModel } from '../../services/models';
import ListModels from '../ListModels';
import { generalTexts } from '../../utils/utils';
import { raiseAlert, raiseSuccess } from '../Alert';
import { Grid, Input, MenuItem } from '@material-ui/core';
import { UserContext } from '../../providers/UserProvider';
import { setAuthToken } from '../../services/token';
import { addDocument, addSubGroup, getDocuments, getSubgroupDocuments, removeDocument, removeSubGroup, updateSubgroup } from '../../services/subgroup';
import { ChipFormChallenge, SelectChipFormChallenge } from '../SelectMultipleModel/style';
import { ToastContainer, toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: '100%',
    backgroundColor: 'white'
  },
  closeIcon: {
    color: '#FE5D00',
    margin: 'auto'
  },
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  textInfo: {
    fontSize: 14,
    color: 'black'
  },
  buttonTransparent: {
    border: 'none',
    backgroundColor: 'transparent',

    '$:focus': {
      outline: 'none'
    }
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  textTransformNone: {
    textTransform: 'none'
  },
  titleFornecedor: {
    //textAlign:'center',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start'
  },
  title: {
    fontSize: '1.75em',
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: 'uppercase',
    letterSpacing: '-1.05px',
    margin: 'auto 30px 0 0'
  },
  subtitle: {
    color: "#FFFFFF",
    fontSize: 16,
    margin: 'auto auto 6px 0'
  },
  mainCards: {
    width: '70vw!important',
    margin: 'auto!important',
    position: 'relative',
  },
  orangeSet: {
    backgroundColor: "#FE5D00",
    //height:'20vh',
    height: '7em',
    '@media(maxWidth: 780px)': {
      marginTop: '3.3rem'
    }
  },
  closeFornecedor: {
    padding: '0px 16px'
  }
}));



export default function Model({ urlModel = '', nomeModel = '', descricaoModel = '', isGroup = false }) {
  const classes = useStyles();
  const { setUserState } = useContext(UserContext);
  const [nome, setNome] = useState('');
  const [models, setModels] = useState([]);
  const [edit, setEdit] = useState(false);
  const [modalEdit, setModalEdit] = useState({});
  const [requesting, setRequesting] = useState(false)
  const [subGroupEdit, setSubGroupEdit] = useState([]);
  const [subGroupResponsavel, setSubGroupResponsavel] = useState('');
  const [grupoid, setGrupoId] = useState(null);
  const [selectedSubGroup, setSelectedSubGroup] = useState(null)
  const [subg, setSubg] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [docsSelecteds, setDocsSelecteds] = useState([]);
  const [docsSelectedsComplete, setDocsSelectedsComplete] = useState([]);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (urlModel !== "grupos") return;
    let group = (models.find(item => item?.NOME === modalEdit?.name))

    if (group) {
      const subgrupos = group.SUBGRUPOS.map(({ SUB_GRUPO }) => SUB_GRUPO);
      setSubGroupEdit(subgrupos)
      setSubg(group.SUBGRUPOS)
      setGrupoId(group.ID)
      setSelectedSubGroup(null)
      setSubGroupResponsavel('')
    }
  }, [modalEdit])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setTimeout(() => {
      [...document.querySelectorAll('.rti--tag')].forEach(node => {
        if (node.getAttribute('data-has-click') != 'true') {
          node.setAttribute('data-has-click', true)
          node.addEventListener('click', async (e) => {
            if (e.target.tagName == 'SPAN') {
              let name = ''
              if (e.target.childNodes[0].innerText) {
                name = e.target.childNodes[0].innerText
              } else {
                name = e.target.innerText
              }
              const sub = subg.find(({ SUB_GRUPO }) => SUB_GRUPO == name)
              setSelectedSubGroup(sub)
              setSubGroupResponsavel(sub.EMAIL_RESPONSAVEL)
              const data = await getSubgroupDocuments(sub.ID);

              if (!Array.isArray(data)) return;

              setDocsSelectedsComplete(data.map((item) => {
                if (documents.find(({ ID }) => ID == item.TIPO_DOCUMENTO)) {
                  const doc = documents.find(({ ID }) => ID == item.TIPO_DOCUMENTO);
                  return {
                    ...item,
                    DESCRICAO: doc.DESCRICAO
                  }
                }
                return null;
              }))
              setDocsSelecteds(data.map(({ TIPO_DOCUMENTO }) => documents.find(({ ID }) => ID == TIPO_DOCUMENTO).DESCRICAO))
            }
          })
        }
      })
    }, 500)
  }, [subGroupEdit, subg, documents])

  useEffect(() => {
    if (!edit) return;
    setModalEdit({ ...modalEdit, name: nome })
  }, [nome])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setRequesting(true)
    async function getList() {
      await getListModels();
      const data = await getDocuments()
      setDocuments(data.data)
    }
    getList()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const editChange = () => {
    setEdit(!edit)
  }

  const modalEditChange = (id, name) => {
    const modalEditing = {
      id,
      name,

    }

    setModalEdit(modalEditing)
  }

  async function getListModels() {
    await getAllModels(urlModel).then((response) => {
      if (response.status === 200) {
        setModels(response.data);
        setRequesting(false)
        setNome('')
      } else {

        if (response.error && response.error.includes('acesso expirou')) {
          setAuthToken(null)
          setUserState({ sessionToken: null })

        }
        raiseAlert(response.error)
        setRequesting(false)
      }
    }).catch(async err => {
      raiseAlert(err.error)
      setRequesting(false)
    });
  }

  const handleSubmitDelete = async (e, id) => {
    e.preventDefault();

    const params = {
      params: {
        ID: id
      }
    }

    await deleteModel(urlModel, params).then(async resp => {
      if (resp.status === 200) {
        await getListModels();
        raiseSuccess();
      } else {
        raiseAlert(resp.error)
      }

    })
  }

  const handleSubmitUpdate = async (e, id) => {
    e.preventDefault();
    if (modalEdit?.name.length < 1 || !modalEdit?.id) { raiseAlert(generalTexts.notEmpty); return; }
    else {
      let data = {}
      if (urlModel === "grupos") {
        data = {
          NOME: modalEdit?.name
        }
      } else {
        data = {
          NOME: modalEdit?.name
        }
      }
      const params = {
        params: {
          ID: modalEdit?.id || id
        }
      }

      await updateModel(urlModel, params, data).then(async (resp) => {
        if (resp.status === 200) {
          await getListModels();
          editChange();
          raiseSuccess();
        } else {
          raiseAlert(resp.error)
        }

      }).catch(err => {
        raiseAlert(err.error)
      })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (nome.length < 1) { raiseAlert(generalTexts.notEmpty); return; }
    else {
      const data = {
        NOME: nome
      }

      await createModel(urlModel, data).then(async (resp) => {
        if (resp.status === 200) {
          await getListModels();
          raiseSuccess();
        } else {
          raiseAlert(resp.error)
        }
      }).catch(err => {
        raiseAlert(err.error)

      })
    }
  }

  const onAddSubGroup = async (subgroupList) => {
    // setSubGroupEdit(subgroupList)
    if (JSON.stringify(subgroupList) == JSON.stringify(subGroupEdit) || subgroupList.length < subGroupEdit.length) {
      setSubGroupEdit(subgroupList)
      return;
    }

    const subgroup = subgroupList[subgroupList.length - 1]
    setSubGroupEdit(subgroupList)
    const { data } = await addSubGroup({
      GRUPO: grupoid,
      SUB_GRUPO: subgroup
    })

    const modelActual = models.find(item => item?.ID == grupoid);
    modelActual.SUBGRUPOS = [...modelActual.SUBGRUPOS, {
      ID: +data.id,
      SUB_GRUPO: subgroup,
      GRUPO: grupoid
    }]

    setModels([...models.filter(({ ID }) => ID != grupoid), modelActual])

  }

  const onRemoveSubGroup = async (subgroup) => {
    const group = models.find(item => item?.ID == grupoid);
    const id = group.SUBGRUPOS.find(({ SUB_GRUPO }) => SUB_GRUPO == subgroup).ID
    await removeSubGroup(id)
  }

  const handleVerifyModel = modelSelect => {
    if (modelSelect === undefined) {
      return false;
    }
    return true;
  }

  const handleChange = async (event) => {
    const itens = (event.target.value)?.filter(value => (typeof value !== "undefined")).map(item => item)
    if (itens.length > docsSelecteds.length) {
      await addDocument(selectedSubGroup?.ID, documents.find(({ DESCRICAO }) => DESCRICAO == itens[itens.length - 1])?.ID)
    }

    if (itens.length < docsSelecteds.length) {

      const docRemoved = docsSelecteds.find(item => !itens.includes(item));
      const idDocRemoved = docsSelectedsComplete.find(item => item.DESCRICAO == docRemoved)?.ID

      await removeDocument(idDocRemoved)
    }

    setDocsSelecteds([...itens])
  }

  async function updateEmailResponsavel(e) {
    e.preventDefault()
    try {
      await updateSubgroup(selectedSubGroup.ID, { EMAIL_RESPONSAVEL: subGroupResponsavel })
      toast('E-mail do responsável alterado!', {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    } catch (e) {
      toast('Erro ao alterar o E-mail do responsável.', {
        position: 'bottom-right',
        hideProgressBar: true,
      });
    }
  }

  return (
    <div className={classes.root}>
      <ToastContainer />
      <Container component="main" className={classes.container}>
        <Box className={classes.orangeSet} textAlign="center">
          <Box paddingTop={2} height={'100%'} display="flex" className={classes.mainCards}>
            <Typography className={classes.title} component="h2" variant="subtitle1">
              {nomeModel}
            </Typography>
            <Typography className={classes.subtitle} component="p" variant="subtitle2">
              {descricaoModel}
            </Typography>
          </Box>
        </Box>

        <Box>
          {requesting ? ('') : (
            models?.length > 0 ? (
              <ListModels modelsArray={models} handleSubmitDelete={handleSubmitDelete} editChange={editChange} modalEditChange={modalEditChange} />
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box className="header-div" pt={'1em'} textAlign="start">
                  <Box >
                    <Typography className={classes.textInfo} variant="subtitle2">
                      {generalTexts.noItems}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )
          )
          }

          <Box className={classes.filterDiv} paddingTop={2} >
            {edit ?
              <form noValidate onSubmit={e => handleSubmitUpdate(e, modalEdit?.id)}>
                <Box display="flex" alignItems="center">
                  <InputNewModel
                    value={modalEdit?.name}
                    placeholder="nome"
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />
                  <ButtonAddModel
                    type="submit"
                    className={classes.button}
                  >
                    <Typography className={classes.textTransformNone}>Salvar</Typography>
                  </ButtonAddModel>
                  <Box display="flex">
                    <button className={classes.buttonTransparent} type="button" onClick={() => editChange()}><CloseIcon className={classes.closeIcon} fontSize={'small'} onClick={() => editChange} /></button>
                  </Box>
                </Box>

              </form>
              :
              <form noValidate onSubmit={e => handleSubmit(e)}>
                <InputNewModel
                  value={nome}
                  placeholder="Nome"
                  onChange={(e) => setNome(e.target.value)}
                  required
                />
                <ButtonAddModel
                  type="submit"
                  className={classes.button}
                >
                  <Typography className={classes.textTransformNone}>Adicionar</Typography>
                </ButtonAddModel>

              </form>
            }

            {(isGroup && edit) && (
              <>
                {/* <form noValidate onSubmit={e => handleSubmitUpdate(e, modalEdit?.id)}>
                  <h2 style={{ margin: '1.5rem 0 .9rem 0' }}>RESPONSÁVEL DO GRUPO</h2>
                  <Box display="flex" alignItems="center">
                    <InputNewModel
                      value={modalEdit?.name}
                      placeholder="E-mail do responsável do grupo"
                      onChange={(e) => setNome(e.target.value)}
                      required
                      width="550px"
                    />
                    <ButtonAddModel
                      type="submit"
                      className={classes.button}
                    >
                      <Typography className={classes.textTransformNone}>Salvar</Typography>
                    </ButtonAddModel>
                  </Box>
                </form> */}

                <Box mt={6}>
                  <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
                    <Box className={classes.closeFornecedor}>
                      <Typography className={classes.titleFornecedor} gutterBottom variant="h5" component="h2">
                        SUBGRUPOS
                      </Typography>
                    </Box>
                    <Grid className={classes.closeFornecedor} item xs={12} sm={12} md={12} lg={12}>
                      <TagInput
                        value={subGroupEdit}
                        onChange={(e) => onAddSubGroup(e)}
                        onRemoved={(e) => onRemoveSubGroup(e)}
                        separators={["Enter", ","]}
                        name="subgrupos"
                        placeHolder="Adicione Subgrupos"
                      />
                    </Grid>
                  </Grid>
                </Box>
                {selectedSubGroup && (
                  <>
                    <form noValidate onSubmit={updateEmailResponsavel}>
                      <Box className={classes.closeFornecedor} style={{ margin: '1.5rem 0 0 -1rem' }}>
                        <Typography className={classes.titleFornecedor} gutterBottom variant="h5" component="h2">
                          RESPONSÁVEL DO SUBGRUPO
                        </Typography>
                      </Box>
                      {/* <h2 style={{ margin: '1.5rem 0 .9rem 0' }}>RESPONSÁVEL DO SUBGRUPO</h2> */}
                      <Box display="flex" alignItems="center">
                        <InputNewModel
                          value={subGroupResponsavel}
                          placeholder="E-mail do responsável do subgrupo"
                          onChange={(e) => setSubGroupResponsavel(e.target.value)}
                          required
                          width="550px"
                        />
                        <ButtonAddModel
                          type="submit"
                          className={classes.button}
                        >
                          <Typography className={classes.textTransformNone}>Salvar</Typography>
                        </ButtonAddModel>
                      </Box>
                    </form>
                    <Box mt={6}>
                      <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
                        <Box className={classes.closeFornecedor}>
                          <Typography className={classes.titleFornecedor} gutterBottom variant="h5" component="h2">
                            Documentos do {selectedSubGroup.SUB_GRUPO}
                          </Typography>
                        </Box>
                        <Grid className={classes.closeFornecedor} item xs={12} sm={12} md={12} lg={12}>
                          <SelectChipFormChallenge
                            id="mutiple-chip"
                            multiple
                            value={docsSelecteds}
                            onChange={(e) => handleChange(e)}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              handleVerifyModel(selected) ?
                                <div className={classes.chips}>
                                  {selected.map((value, key) => (
                                    <ChipFormChallenge key={key} label={value} className={classes.chip} />
                                  ))}
                                </div>
                                : ''
                            )}
                          >
                            {documents.map(model => (
                              <MenuItem key={model?.ID} value={model?.DESCRICAO} id={model?.ID} >
                                {model?.DESCRICAO}
                              </MenuItem>
                            ))}
                          </SelectChipFormChallenge>
                        </Grid>
                      </Grid>
                    </Box>

                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
}