import styled from "styled-components";
import ReactSelect from 'react-select'



export const InputSearch = styled(ReactSelect)`
  & .Select__control {
    background: #efefef;
  }
  & .Select__option:hover {
    background: #efefef;
  }
  & .Select__option--is-selected {
    background: #a1a1a1;
  }
`;

export const FolderHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 2rem;

  > h3 > span {
    cursor: pointer
  }
`

export const FilesContainer = styled.section`
  margin-top: 1.5rem;
  width: 100%;
  display: grid;
  /* display: flex;
  gap: 1rem;
  flex-wrap: wrap; */
  grid-template-columns: repeat(5, 150px);
  justify-content: space-between;

  @media (max-width: 1130px ) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 900px ) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 500px ) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const FolderFileContainer = styled.div`
  width: 150px;
  height: 200px;
  
  .square {
    width: 150px;
    height: 150px;
    background: #F6F6F6;
    border: 1px solid #ED6608;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
       background: #ed670816;
      .actions {
        display: flex;
      }
    }
  }

  p {
    margin: .6rem 0 1rem 0;
    text-align: center;
    color: #6C6C6C
  }
`;

export const Button = styled.button`
  background-color: #FE5D00;
  padding: .5rem 1rem;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

export const EmptyText = styled.p`
  color: #B7B7B7;
  text-align: center;
`;

export const InputFile = styled.label`
  background-color: #FE5D00;
  padding: .5rem 1rem;
  border: none;
  font-size: 13px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`

export const Actions = styled.div.attrs({
  className: 'actions'
})`
  display: none;
  flex-direction: column;
  gap: .5rem;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: .2rem;
  margin-right: .2rem;
`

export const ActionButton = styled.button`
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;


export const FolderMoveContainer = styled.div`
  width: 100px;
  height: 120px;
  
  .square {
    width: 100px;
    height: 100px;
    background: #F6F6F6;
    border: 1px solid #ED6608;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
       background: #ed670816;
    }
  }

  p {
    margin: .8rem 0 0 0;
    text-align: center;
    color: #6C6C6C;
    font-size: 14px;
  }
  `

export const ModalFoldersContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const MoveButton = styled.button`
  background-color: #FE5D00;
  padding: 10px 2rem;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  margin-top: 2rem;
  font-weight: bold;

  &:disabled {
    background: #6c6c6c73;
    cursor: not-allowed;
  }
`;

export const ModalActions = styled.section`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 22px;
  margin-left: 22px;
  margin-right: 22px;
  gap: 1rem;
`