import api from './api';


export const cadastrarFornecedor = async (obj) => {
  return await api.post(`usuario/registro`, obj).then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });
}

export const getAllFornecedor = async () => {
  return await api.get(`fornecedores`).then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });
}
export const getFornecedor = async (id) => {
  return await api.get(`fornecedores?ID=${id}`).then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });
}

export const updatePassword = async (body) => {
  return await api.post(`usuario/alterarSenha`, body).then(response => {
    return response
  }).catch(err => {
    return { status: 400, error: err.response.data.message }
  });
}

export const getNegotiations = async (supplier) => {
  const negotiations = await api.get(`ocs/negociacoes?ID=${supplier}`);
  return negotiations.data;
}

export const updateSupplier = async (id, supplier) => {
  const negotiations = await api.put(`fornecedores?ID=${id}`, supplier);
  return negotiations.data;
}

export const getUsers = async (supplier) => {
  const response = await api.get(`fornecedores/usuarios`)
  return response.data
}

// novo
export async function registerSupplierUser(data, supplier) {
  return api.post(`fornecedores/usuarios/${supplier}`, data)
}