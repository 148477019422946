import React, { createRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoModal from '../InfoModal';
import { Button } from '../Button';
import { DetailModal } from './DetailModal';
import api from '../../services/api';

export default function BudgetTable({ budget }) {
  const [yearBudget, setYearBudget] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',
      padding: '14px 22px',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0px 0px 5px #FE5D00',
      },
    },
    countUp: {
      fontSize: '48px',
    },
    title: {
      color: '#000000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start',
      marginRight: '1rem',
    },
    media: {
      height: 140,
      display: 'flex',
    },
    expand: {
      margin: '0 0 0 auto',
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'start',
    },
    cardPadding: { padding: 0, '&:last-child': { padding: 0 } },
  }));

  const classes = useStyles();
  const cardRef = createRef();
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const lastRowData = {
    prazoMedio:
      budget.map(({ PRAZO_MEDIO }) => PRAZO_MEDIO).reduce(sum, 0) /
      budget.length,
    meta: budget.map(({ META }) => META).reduce(sum, 0),
    total: budget.map(({ VALOR_TOTAL }) => VALOR_TOTAL).reduce(sum, 0),
  };

  function sum(num1, num2) {
    return num1 + num2;
  }

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    api.get(`fornecedores/orcamento?year=${year}`).then((e) => {
      setYearBudget(e.data);
    });
  }, []);

  return (
    <Card ref={cardRef} className={classes.root}>
      <CardContent className={classes.cardPadding} padding={0}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box textAlign="left" display="flex" alignItems="center">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              Compras e prazo de pagamento
            </Typography>
            <InfoOutlinedIcon
              htmlColor="#FE5D00"
              fontSize={'small'}
              onClick={() => setOpen(true)}
            />
          </Box>

          <Button title="Ver detalhes" onClick={() => setOpenDetail(true)} />
        </Box>
        {budget.length > 0 && (
          <Box textAlign="left" mt={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Agrupamento
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Prazo médio
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Meta</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Vlr Total
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {budget?.map((row) => (
                    <TableRow key={row.GRUPO}>
                      <TableCell style={{ fontWeight: 'normal' }}>
                        {row.GRUPO}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'normal' }}>
                        {row.PRAZO_MEDIO}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'normal' }}>
                        {row.META.toReal()}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'normal' }}>
                        {row.VALOR_TOTAL.toReal()}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'normal' }}>
                        {((row.VALOR_TOTAL * 100) / lastRowData.total).toFixed(
                          2
                        )}
                        %
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Total Geral
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {lastRowData.prazoMedio}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {lastRowData.meta.toReal()}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {lastRowData.total.toReal()}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>100%</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
        )}
      </CardContent>
      <InfoModal open={open} handleClose={() => setOpen(false)} type="budget" />
      <DetailModal
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        budgets={yearBudget}
      />
    </Card>
  );
}

// eslint-disable-next-line no-extend-native
Number.prototype.toReal = function () {
  return this.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};
