/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FornecedoresTable from '../TabelaFornecedores';
import * as S from './styles'
import { getAllModels } from '../../services/models';


export default function DialogFornecedores({ openDialog = false, setOpenDialog }) {
  const [groups, setGroups] = useState([]);

  const [subGroups, setSubGroups] = useState([]);

  const [searchName, setSearchName] = useState('');
  const [searchGroup, setSearchGroup] = useState('');
  const [searchSubGroup, setSubSearchGroup] = useState('');
  const [searchDoc, setSearchDoc] = useState('');

  useEffect(() => {
    initialRequest()
  }, [])

  useEffect(() => {
    if (searchGroup) {
      setSubGroups(groups.find(s => s.ID == searchGroup).SUBGRUPOS)
    }
  }, [searchGroup, groups])

  async function initialRequest() {
    const data = await getAllModels('grupos')
    setGroups(data.data)
  }

  function handleClose() {
    setSearchName('');
    setSearchGroup('');
    setSubSearchGroup('');
    setSearchDoc('');
    setOpenDialog(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="paper"
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <S.DialogHeader>

        <DialogTitle id="max-width-dialog-title">
          <p style={{
            color: "#000000", fontSize: '1.25rem',
            fontWeight: 'bold', textAlign: 'start'
          }}
            component="h1" variant="subtitle1">
            Fornecedores
          </p>

        </DialogTitle>
        <S.Input placeholder="Nome" onChange={({ target }) => setSearchName(target.value)} />

        <S.Select onChange={(e) => setSearchGroup(e.target.value)}>
          <option value="">Selecione um grupo</option>
          {groups?.length > 0 && groups.map(({ ID, NOME }) => (<option key={ID} value={ID}>{NOME}</option>))}
        </S.Select>

        <S.Select onChange={(e) => setSubSearchGroup(e.target.value)}>
          <option value="">Selecione um subgrupo</option>
          {subGroups?.length > 0 && subGroups.map(({ ID, SUB_GRUPO }) => (<option key={ID} value={ID}>{SUB_GRUPO}</option>))}
        </S.Select>

        <S.Select onChange={(e) => setSearchDoc(e.target.value)}>
          <option value="">Status dos documentos</option>
          <option value="Tudo certo">Tudo certo</option>
          <option value="Vencido">Vencidos</option>
          <option value="Incompleto">Incompletos</option>
        </S.Select>
      </S.DialogHeader>
      <DialogContent>
        <FornecedoresTable
          searchName={searchName}
          searchGroup={searchGroup}
          searchSubGroup={searchSubGroup}
          searchDoc={searchDoc}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}