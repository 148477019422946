import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { TagsInput } from "react-tag-input-component";

export const InputNewModel = styled.input`
    height: 2.5em;
    width: ${props => props.width ? props.width : '15em'};
    padding: 0.5em 1em;
    margin: 0.5em;
    margin-right: 1.2em;
    margin-left: 0;
    color: inherit;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #6C6C6C;
    border-radius: 5px;
    opacity: 1;
    border: 1px solid #C6C6C6;
    font-family: inherit;
    font-size:16px;
    font-weight:300;
    &:focus {
        outline: none;
    }
`

export const TagInput = styled(TagsInput)`
 .rti--container {
    --rti-tag: red !important
 }
`


export const ButtonAddModel = styled(Button)`
    height: 2.5em;
    padding: 6px 10px;
    margin-left: 0;
    font-weight: 500;
    color: #FE5D00;
    border: 2px solid #FE5D00;
`