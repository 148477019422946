import api from "./api"

export const getNews = async (limit = null) => {
  let route = 'noticias';
  if (limit) route += `?LIMIT=${limit}`
  const news = await api.get(route);
  return news.data;
}

export const register = async (notice) => {
  const news = await api.post('noticias', notice);
  return news;
}

export const update = async (id, notice) => {
  const news = await api.put(`noticias?ID=${id}`, notice);
  return news;
}

export const destroy = async (id) => {
  const news = await api.delete(`noticias?ID=${id}`);
  return news;
}