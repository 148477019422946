import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export default function ListModels({ modelsArray = [], handleSubmitDelete, modalEditChange, editChange }) {

  const useStyles = makeStyles((theme) => ({

    filterDiv: {
      width: '70vw',
      margin: '16px auto auto auto',
    },
    buttonRoot: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    button: {
      margin: `10px auto`
    },
    marginRightNone: {
      margin: '10px 0 10px auto !important'
    },
    marginLeftNone: {
      margin: '10px auto 10px 0 !important'
    },
    labelButtonIcon: {
      height: '1.25rem',
      marginRight: theme.spacing(4),
      textTransform: 'none'
    }
  }));

  const classes = useStyles();

  function handleUpdate(id, name) {
    modalEditChange(id, name)
    editChange()
  }

  return (
    <Box display="flex">
      <Grid container className={`${classes.filterDiv}`}>
        {modelsArray?.map((model, index) => (
          <Box m={'auto 10px auto 0'} key={model?.ID}>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.buttonRoot} ${index === 1 ? classes.marginLeftNone : classes.button} ${index === modelsArray.length ? classes.marginRightNone : classes.button}`}
            >
              <label className={classes.labelButtonIcon}>{model?.NOME}</label>
              <EditIcon fontSize={'small'} onClick={() => handleUpdate(model?.ID, model?.NOME)} className={classes.zIndexFeatured} />
              <Box ml={1 / 2} />
              <CloseIcon fontSize={'small'} onClick={(e) => handleSubmitDelete(e, model?.ID)} className={classes.zIndexFeatured} />
            </Button>
          </Box>
        ))}
      </Grid>
    </Box>
  );
}