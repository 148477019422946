import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { raiseAlert, raiseSuccess } from '../../components/Alert';
import {
  generalTexts,
  textsCadastroPage,
  textsProfilePage,
  mockPath,
} from '../../utils/utils';
import { registerSupplierUser } from '../../services/fornecedores';
import { LineScalePulseOut } from 'react-pure-loaders';
import { validMail } from '../../utils/functions';
import { useStyles } from './styles';
import { Alert } from '@material-ui/lab';

export default function SignUpSupplier() {
  const classes = useStyles();
  const history = useHistory();
  const [disable, setDisable] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const { supplier } = useParams();

  function goToRegulation() {
    window.open(process.env.REACT_APP_REGULATION_ADDRESS);
  }

  function handleAlert(event) {
    event.preventDefault();
    raiseAlert(generalTexts.formDisable);
  }

  async function handleSignUp(event) {
    event.preventDefault();

    const isValidEmail = await validMail(formData.email);

    if (!isValidEmail) {
      setError(textsProfilePage.validEmail);
      return;
    }

    setRequesting(true);
    try {
      const response = await registerSupplierUser(formData, supplier);
      setFormData({
        name: '',
        email: '',
      });
      raiseSuccess(response.data.message || textsCadastroPage.registerSuccess);
      history.push(`${mockPath}login`);
    } catch (e) {
      raiseAlert(e.error);
      setRequesting(false);
    } finally {
      setRequesting(false);
    }
  }

  return (
    <div className={`centralize ${classes.root}`}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} textAlign="center">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              {textsCadastroPage.title}
            </Typography>
            <Box className={`xs-screen ${classes.subtitle}`}>
              <Typography variant="subtitle2">
                {textsCadastroPage.subtitle}
              </Typography>

              <Button
                color="primary"
                onClick={() => history.push(`${mockPath}login`)}
                className={`${classes.textAdapt}`}
              >
                <Typography
                  color="inherit"
                  className={`${classes.fakeLink}`}
                  variant="subtitle1"
                >
                  {textsCadastroPage.link}
                </Typography>
                <span
                  style={{
                    color: '#000000',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'none' },
                  }}
                >
                  .
                </span>
              </Button>
            </Box>
          </Box>

          <form
            className={classes.form}
            noValidate
            onSubmit={(event) => {
              disable ? handleAlert(event) : handleSignUp(event);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">Nome</Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  name="name"
                  autoComplete="name"
                  className={classes.input}
                  onChange={(event) =>
                    setFormData({ ...formData, name: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {textsCadastroPage.email}
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  className={classes.input}
                  onChange={(event) =>
                    setFormData({ ...formData, email: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {error.length > 0 ? (
                  <Alert severity="error">{error}</Alert>
                ) : (
                  ''
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="agree"
                      classes={{
                        root: classes.checkColor,
                        checked: classes.checkColor,
                      }}
                      onChange={(_, value) => setDisable(!value)}
                      name="accord"
                    />
                  }
                  label={
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="subtitle2">
                          {textsCadastroPage.agreeWith}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={goToRegulation}
                          className={`${classes.textAdaptCheckBox}`}
                        >
                          <Typography
                            color="inherit"
                            className={`${classes.fakeLink}`}
                            variant="subtitle1"
                          >
                            {textsCadastroPage.regulation}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {textsCadastroPage.ofProgram}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={disable}
            >
              {requesting ? (
                <LineScalePulseOut
                  color={'#FFFFFF'}
                  className="loading-center"
                  loading={requesting}
                />
              ) : (
                'Cadastrar'
              )}
            </Button>
            <Box mb={2} />
          </form>
        </Paper>
      </Container>
    </div>
  );
}
