import api from './api';


export const uploadImage = async (image) => {

    var imageFile = image[0].file
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let data = new FormData();
    data.append('file', imageFile);

    await api.post('utils/upload', data, config).then(response => {
        return response
    })
        .catch(err => {
            return { status: 400, error: err.response.data.message }
        });

}

export const upload = async (file) => {

    const form = new FormData();
    form.append('file', file);

    const response = await api.post('utils/upload', form, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })

    return response.data
} 