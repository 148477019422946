import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { UserContext } from '../../providers/UserProvider';
import { isBibiUser } from '../../utils/roles';

export default function CustomButton({
    first,
    last,
    text,
    active,
    setSelected,
    buttonOnly,
}) {
    const { userState } = useContext(UserContext);

    const useStyles = makeStyles((theme) => ({
        finished: {
            minWidth: '8em',
            border: '1px solid #FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        finishedAdmin: {
            border: '1px solid #000000',
        },
        finishedText: {
            textTransform: 'capitalize',
            color: theme.palette.secondary.main,
            fontWeight: '',
            // fontFamily
        },
        selected: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        selectedText: {
            textTransform: 'capitalize',
            color: theme.palette.default.main,
        },
    }));

    const classes = useStyles();

    return (
        <div className={`buttons-group`}>
            <Box
                className="button-div"
                component="div"
                ml={first ? 0 : 1}
                mr={last ? 0 : 1}
            >
                <Button
                    variant="outlined"
                    className={clsx(classes.finished, {
                        [classes.selected]: active,
                        [classes.finishedAdmin]: isBibiUser(userState?.tipo) && active
                    })}
                    onClick={() => {
                        if (!buttonOnly) setSelected(text);
                    }}
                >
                    <Typography
                        className={clsx(classes.finishedText, {
                            [classes.selectedText]: active,
                        })}
                        component="h1"
                        variant="subtitle1"
                    >
                        {text}
                    </Typography>
                </Button>
            </Box>
        </div>
    );
}
