import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#000000",
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'start',
    marginBottom: '2rem'
  },
  subtitle: {
    color: "#000000",
    '@media(max-width: 425px)': {
      textAlign: 'justify'
    }
  },
  conntentAcordion: {
    width: '100%',
    boxShadow: '0px 0px 0px #6C6C6C',
    border: '1px solid #00000029',
    borderRadius: '5px'
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  cardNotice: {
    minHeight: '250px',
    position: 'relative',
    cursor: 'pointer',
  },
  cardContent: {
    padding: '16px'
  },
  noticeContent: {
    color: "#B7B7B7",
    fontSize: 14,
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'normal',
    "& img": {
      width: '90px'
    }
  },
  timeAgo: {
    position: 'absolute',
    bottom: 0,
    marginBottom: '15px',
    textAlign: "left",
    display: "flex",
    alignItems: "center"
  },
  positionText: {
    color: "#FE5D00",
    fontSize: 12,
  },
  moreButton: {
    minHeight: '215px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));