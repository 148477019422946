import styled from "styled-components";
import backgroundOne from "../../../../assets/backgroundOne.png";

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(${backgroundOne});
  justify-content: space-around;
  width: 100%;
`;

export const Inovacao = styled.img`
  /* max-height: 350px; */
  /* width: 100%; */
  height: 180px;

  @media (max-width: 480px) {
    height: 120px;
  }
`;

export const DivOne = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  ul {
    max-width: 500px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    display: grid;
    list-style: none;
    line-height: 70px;
    span {
      font-size: 40px;
    }
    @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .negocios {
    @media (max-width: 500px) {
      font-size: 35px !important;
    }
  }
`;

export const DivTwo = styled.div`
  margin-top: 50px;

  @media (max-width: 500px) {
    margin-top: 80px;
  }

  display: flex;
  justify-content: center;
  height: 150px;
  button {
    font-family: Nunito;
    font-size: 24px;
    color: #ffffff;
    height: 70px;
    width: 270px;
    background-color: #fe5d00;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
    border-style: none;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    &:hover {
      cursor: pointer;
    }
  }
`;
