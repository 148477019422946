import React from "react";
import threeMedals from "../../../../assets/threeMedals.png";
import { Container, FakeButton, MedalsImage } from "./styles";
import { textLandingPage2 } from "../../../../utils/utils";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";



import slideup1 from '../../../../assets/slides/slideup1.jpeg';
import slideup2 from '../../../../assets/slides/slideup2.jpeg';
import slideup3 from '../../../../assets/slides/slideup3.jpeg';
import slide1 from '../../../../assets/slides/slide1.jpeg';
import slide2 from '../../../../assets/slides/slide2.jpeg';
import slide3 from '../../../../assets/slides/slide3.jpeg';
import slide4 from '../../../../assets/slides/slide4.jpeg';
import slide5 from '../../../../assets/slides/slide5.jpeg';
import slide6 from '../../../../assets/slides/slide6.jpeg';
import slide7 from '../../../../assets/slides/slide7.jpeg';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 36,
    color: "#000000",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    margin: '3.5rem'
  },
  positioneItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media(max-width: 938px)': {
      display: 'block',
      textAlign: 'center'
    }
  },
  texts: {
    margin: '0px 80px 80px 0px',
    width: '45%',
    maxWidth: '60%',
    '@media(max-width: 938px)': {
      maxWidth: '95%',
      width: '90%',
      margin: 30
    }
  },
  texts2: {
    fontSize: 20,
    textAlign: 'inherit',

    '@media (max-width: 700px)': {
      textAlign: 'center'
    }
  },
  textButton: {
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '33px',
  },
  customButtons: {
    width: '60vw',
  },
  customWidth: {
    width: '70vw',
  },
  orangeSet: {
    margin: '2em',
    backgroundColor: "#FE5D00",
    width: '100vw',
    minHeight: '7em',
    height: 'auto',
    '@media(max-width: 938px)': {
      //height:'16em',
    },
    justifyContent: 'space-evenly',
    display: 'flex'
  },
  inBoxOrange: {
    '@media(max-width: 938px)': {
      alignSelf: 'center',
      textAlign: 'center',
      margin: 'auto auto',
      //height:'16em',
    }
  },
  changeCarousel: {
    height: '15rem'
  },
  boxSlide: {
    width: '45%',
    marginRight: '5%',
    '@media(max-width: 938px)': {
      width: '100%',
      marginRight: '0%',
    }
  },
  boxText: {
    width: '50%',
    marginLeft: '5%',
    '@media(max-width: 938px)': {
      width: '100%',
      marginLeft: '0%',
      marginTop: '10%'
    }
  },
  noLeft: {
    '@media(max-width: 938px)': {
      marginLeft: 0
    }
  }
}));

export default function Premiacao() {
  const classes = useStyles();

  return (
    <Container id="premiacao">
      <Box textAlign="center">
        <Box paddingTop={2} textAlign="center" className="">
          <Typography className={classes.title} component="h1">
            {textLandingPage2.premiacaoTitle}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.positioneItem}>
        <Box className={classes.texts}>

          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.premiacaoText1}
            {" "}
            <Typography className={classes.texts2} variant='body2' component='b'>
              {textLandingPage2.premiacaoTextProgram}
            </Typography>
          </Typography>
          &nbsp;
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.premiacaoText2}
          </Typography>
          &nbsp;
        </Box>
        <MedalsImage src={threeMedals} alt="medalhas" />
      </Box>
      <Box className={`${classes.orangeSet}`} >
        <Box className={classes.inBoxOrange}>
          <Box ml={0} mt={1}>
            {['Qualidade', 'Inovação', 'Pós-venda', 'Flexibilidade'].map((item, index) => (
              <FakeButton key={`first_line_item_${index}`}>
                {item}
              </FakeButton>
            ))
            }
          </Box>
          <Box className={classes.noLeft} ml={6} mb={1}>
            {['Velocidade', 'Sustentabilidade', 'Confiabilidade', 'Competitividade'].map((item_sec, index_sec) => (
              <FakeButton key={`second_line_item_${index_sec}`}>
                {item_sec}
              </FakeButton>
            ))}
          </Box>
        </Box>

      </Box>
      <Box mt={4} mb={4} className={`${classes.positioneItem} ${classes.customWidth}`}>
        <Box className={classes.boxSlide}>
          <Carousel width={`100%`} autoPlay={true} infiniteLoop showStatus={false} showThumbs={false}>
            {[slideup1,
              slideup2,
              slideup3, slide1, slide2, slide3, slide4, slide5, slide6, slide7].map((el, index) => (
                <div key={`div_carousel_slide${index}`}>
                  <MedalsImage key={`carousel_slide${index}`} src={el} alt={`slide${index + 1}`} />
                </div>
              ))}
          </Carousel>
        </Box>

        <Box className={classes.boxText} textAlign={'start'}>
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.premiacaoText3}
            {" "}
            <Typography className={classes.texts2} variant='body2' component='b'>
              {textLandingPage2.premiacaoText4}
            </Typography>
          </Typography>


          &nbsp;
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.premiacaoText5}
          </Typography>
          &nbsp;
        </Box>
      </Box>
    </Container>
  );
}
