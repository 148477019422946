import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterDiv: {
    width: '70vw',
    margin: '16px auto auto auto',
  },
  tittleContact: {
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    textTransform: 'none',
    margin: 'auto 30px 0 0',
  },
  copyButton: {
    width: '20%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export function ProfileAdditionalData({ additionalData }) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" style={{ marginTop: '25px' }}>
        <Box className={`header-div mob ${classes.filterDiv}`}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              className={classes.tittleContact}
              component="h2"
              variant="subtitle1"
            >
              Dados SFT
            </Typography>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>CNPJ</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Telefone
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Insc. Estadual
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Prazo de pagamento
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Endereço
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalData.map((data) => {
                    return (
                      <TableRow key={data.CODIGO_PESSOA}>
                        <TableCell style={{ fontWeight: 'normal' }} width="20%">
                          {data.CNPJ.replace(
                            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            '$1.$2.$3/$4-$5'
                          )}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {data.TELEFONE}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {data.IE}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {data.PRAZO_PAGTO}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'normal' }}>
                          {data.ENDERECO}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
