
import React, { useState, useEffect, useContext } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import * as yup from 'yup';
import { DropzoneAreaBase } from 'material-ui-dropzone'
import CancelIcon from '@material-ui/icons/Cancel';
import { TextareaAutosizeCustom } from '../TextareaAutosize/style'
import { SubmitButton } from '../SubmitButton/style';
import { InputFormChallenge, SelectFormChallenge } from './style';
import { textsFooterDetail, textsFormChallenge, textsSubmitButtons, textsDropzone, textsTextAreaPlaceholder, generalTexts, mockPath } from '../../utils/utils';
import { getDetalhesDesafioWithParams, postDesafio, putDesafio } from '../../services/desafios';
import SelectMultipleModel from '../SelectMultipleModel';
import ListOptionsModel from '../ListOptionsModel';
import { UserContext } from '../../providers/UserProvider';
import { useHistory } from 'react-router';
import { raiseAlert, raiseSuccess } from '../Alert';
import api from '../../services/api';
import clsx from 'clsx';
import ListFiles from '../ListFiles';

import { uploadMaterials } from '../../services/file';
import { LineScalePulseOut } from 'react-pure-loaders';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        fontSize: '1.35em',
        fontWeight: 600,
        color: "#FFFFFF",
        textTransform: 'none',
        margin: '0 auto 0 25px'
    },
    subtitle: {
        color: "#000000",
        margin: '2em auto auto 25px',
    },
    textCenter: {
        textAlign: 'center'
    },
    paddingRightCustom: {
        paddingRight: '10px',
        '@media(max-width: 780px)': {
            padding: 0
        }
    },
    paddingRight: {
        paddingRight: '10px'
    },
    paddingLeft: {
        paddingLeft: '10px'
    },
    paddinRightLeft: {
        padding: '0 10px'
    },
    marginH2: {
        margin: '16px auto 16px 0'
    },
    marginH2Init: {
        margin: '0 auto 16px 0'
    },
    marginTopImage: {
        '@media(min-width: 460px)': {
            marginTop: '10px'
        }
    },
    marginField: {
        '@media(max-width: 460px)': {
            marginTop: '26px'
        }
    },
    right: {
        margin: 'auto auto auto 0'
    },
    caractersSize: {
        height: 'fit-content',
        margin: 'auto 0'
    },
    textAreaPlaceholder: {
        height: 'fit-content',
        letterSpacing: '-1.06px',
        color: '#000000',
    },
    boxImage: {
        objectFit: 'cover',
        maxWidth: "100%",
        height: '190px',
        maxHeight: '190px',
    },
    gridTextImage: {
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        '@media(max-width: 780px)': {
            display: 'grid',
            padding: '10px'
        }
    },
    boxTextImage: {
        '@media(max-width: 780px)': {
            maxWidth: '100%',
            flexBasis: '100%',
            padding: '0 !important'
        }
    },
    boxGridFildsMobile: {
        '@media(max-width: 460px)': {
            display: 'grid'
        }
    },
    boxFildsMobile: {
        '@media(max-width: 460px)': {
            maxWidth: '100%',
            flexBasis: '100%',
            padding: '0 !important'
        }
    },
    iconChangeEdit: {
        cursor: 'pointer',
        fontSize: '22px',
        position: 'absolute',
        right: '0',
        marginTop: '5px',
        marginRight: '5px',
        color: '#FFFFFF',
        backgroundColor: '#FE5D00',
        borderRadius: '10px'
    },
    label: {
        fontSize: 20
    }
}));

let schemaCreated = yup.object().shape({
    name: yup.string().required(),
    category: yup.string().required(),
    objective: yup.string().required(),
    details: yup.string().required(),
    //video: yup.string().required(),
    spotlight: yup.string().required(),
    areasIds: yup.array().min(1, 'Adicione pelo menos uma área!').max(5, 'Informe no máximo 5 áreas!').required()
});

//ficou opcional
/* let schemaImage = yup.object().shape({
    image: yup.array().min(1, 'Adicione pelo menos uma imagem!').max(1, 'Adicione apenas uma imagem!').required('Adicione uma imagem!'),
}); */

export default function FormChallenge({
    edit = false,
    canEdit = false
}) {
    const classes = useStyles();
    const history = useHistory();
    const navigateTo = () => history.push(`${mockPath}principal`);
    const { userState } = useContext(UserContext);

    let limitCharsName = 30
    let limitCharsObjective = 120
    let limitCharsDetails = 4000
    let limitCharsArea = 5

    const STATUS_FORM_CREATE = "Fechado"
    const [editImage, setEditImage] = useState(false);
    const [loadDesafio, setLoadDesafio] = useState(false);
    const [loadFiles, setLoadFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [image, setImage] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [objective, setObjective] = useState('');
    const [details, setDetails] = useState('');
    const [video, setVideo] = useState('');
    const [status, setStatus] = useState(STATUS_FORM_CREATE);
    const [spotlight, setSpotlight] = useState('N');
    const [areas, setAreas] = useState([]);
    const [areasIds, setAreasIds] = useState([]);
    const [remainCharsName, setRemainCharsName] = useState(limitCharsName);
    const [remainCharsObjective, setRemainCharsObjective] = useState(limitCharsObjective);
    const [remainCharsDetails, setRemainCharsDetails] = useState(limitCharsDetails);
    const [remainCharsArea, setRemainCharsArea] = useState(limitCharsArea);
    const [requesting, setRequesting] = useState(false);

    useEffect(() => {
        setRemainCharsName(limitCharsName - name.length)
    }, [name, limitCharsName])

    useEffect(() => {
        setRemainCharsObjective(limitCharsObjective - objective.length)
    }, [objective, limitCharsObjective])

    useEffect(() => {
        setRemainCharsDetails(limitCharsDetails - details.length)
    }, [details, limitCharsDetails])

    useEffect(() => {
        setRemainCharsArea(limitCharsArea - areas.length)
    }, [areas, limitCharsArea])

    useEffect(() => {
        const loadChallengeDetails = async () => {
            await getDetalhesDesafioWithParams(`ID=` + userState?.DESAFIO_ID).then((response) => {
                setName(response?.data?.NOME);
                setCategory(response?.data?.CATEGORIA);
                setObjective(response?.data?.OBJETIVO);
                setDetails(response?.data?.DETALHES);
                setVideo(response?.data?.URL_VIDEO);
                setImageUrl(process.env.REACT_APP_BASE_URL + response?.data?.IMAGEM)
                setStatus(response?.data?.STATUS);
                setSpotlight(response?.data?.DESTAQUE);
                setAreas(response?.data?.AREAS);
                setLoadFiles(response?.data?.MATERIAIS);
            }).catch(err => {
                raiseAlert(err.error)
                history.goBack();
            })
        }
        if (edit || loadDesafio) {
            loadChallengeDetails(userState?.DESAFIO_ID)
        }
    }, [edit, history, loadDesafio, userState?.DESAFIO_ID])

    const handleAddImage = newImage => {
        setImage([...newImage]);
    };

    const handleDeleteImage = () => {
        setImage([]);
    };

    const handleAdd = newFiles => {
        newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
        setFiles([...files, ...newFiles]);
    };

    const handleDelete = deleted => {
        setFiles(files.filter(f => f !== deleted));
    };

    const handleChangeAreas = newAreas => {
        setAreas(newAreas)
    }

    const handleUpdate = async (e) => {
        e.preventDefault();

        const isValidCreated = await schemaCreated.isValid({
            category,
            name,
            objective,
            details,
            //imageUrl,
            spotlight,
            //video,
            areasIds//areas: [1,2,3]
        })


        let resultImage = '';
        if (isValidCreated) {
            setRequesting(true)
            if (image.length > 0) {
                var imageFile = image[0].file
                const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                let img = new FormData();
                img.append('file', imageFile);
                await api.post('utils/upload', img, config).then(async response => {
                    if (response?.status === 200) {
                        setImageUrl(response.data.url)
                        resultImage = response.data.url
                    } else {
                        raiseAlert(response.error)
                    }
                })
                    .catch(err => {
                        raiseAlert(err.error)
                    });
            }
            if (files.length > 0) {
                await uploadMaterials(userState?.DESAFIO_ID, files).then(response => {
                    let exists = response.some(item => (item?.status !== 200))
                    if (exists) raiseAlert("Erro no envio de algum material.")

                }).catch(err => {
                    raiseAlert(err.error)
                })
            }
            const dataPut = {
                "CATEGORIA": category,
                "NOME": name,
                "OBJETIVO": objective,
                "DETALHES": details,
                "IMAGEM": resultImage,
                "DESTAQUE": spotlight,
                "URL_VIDEO": video,
                "STATUS": status,
                "AREAS": areasIds//[4,5,6] || areas,
            }
            if (resultImage === '') { delete dataPut["IMAGEM"] }
            await putDesafio(userState?.DESAFIO_ID, dataPut).then(async resp => {
                if (resp?.status === 200) {
                    setRequesting(false)
                    navigateTo()
                    raiseSuccess("Desafio atualizado com sucesso!");
                } else {
                    raiseAlert(resp.error)
                }
            }).catch(err => {
                raiseAlert(generalTexts.errorMessage)
            })
        } else {
            raiseAlert(generalTexts.notEmpty)
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidImage = true //opcional  ## await schemaImage.isValid({image}) ##
        const isValidCreated = await schemaCreated.isValid({
            category,
            name,
            objective,
            details,
            spotlight,
            //video,//opcional
            areasIds
        })

        if (isValidImage && isValidCreated) {
            setRequesting(true)
            let respImgUrl = null
            if (image.length > 0) {
                var imageFile = image[0].file
                const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                let img = new FormData();
                img.append('file', imageFile);

                respImgUrl = await api.post('utils/upload', img, config).then(async response => {
                    return response.data.url || null
                }
                );
                if (respImgUrl) { setImageUrl(respImgUrl) }
            }        //alert(response.data.url)

            const dataPost = {
                "CATEGORIA": category,
                "NOME": name,
                "OBJETIVO": objective,
                "DETALHES": details,
                "IMAGEM": respImgUrl || imageUrl,
                "DESTAQUE": spotlight, //S ou N
                "URL_VIDEO": video,
                "STATUS": STATUS_FORM_CREATE,
                "AREAS": areasIds, // [1,2,3] MAX: 5
            }

            await postDesafio(dataPost).then(async resp => {
                if (resp?.status === 200) {
                    setRequesting(false)
                    raiseSuccess("Desafio criado com sucesso!");
                    navigateTo()
                } else {
                    raiseAlert(resp.error)
                }
            }).catch(err => {
                raiseAlert(generalTexts.errorMessage)
            })

            /*        }else {
                       raiseAlert(response.error)
                   }
               })
               .catch(err => {
                   raiseAlert(err.error)
               }); */
        } else if (!isValidImage) {
            raiseAlert(generalTexts.notEmptyImage)
        } else {
            raiseAlert(generalTexts.notEmpty)
        }
    }
    const getImageError = () => {
        setImageUrl('')
    }

    return (
        <Box width={'100%'}>
            <form onSubmit={edit ? (e) => handleUpdate(e) : (e) => handleSubmit(e)}>
                <Box className={classes.root} mt={4}>
                    <Grid container spacing={3} >
                        <Grid container className={classes.boxGridFildsMobile} item xs={12}>
                            <Grid container item className={`${classes.boxFildsMobile} ${classes.paddingRight}`} xs={6}>
                                <Grid item xs={12}>
                                    <Box display='flex'>
                                        <Typography className={`${classes.label} ${classes.marginH2Init}`} component="h2" variant="h2">
                                            {textsFormChallenge.titleName}
                                        </Typography>
                                        <Typography className={`${classes.label} ${classes.caractersSize}`} component="p" variant="subtitle2">
                                            {remainCharsName}
                                        </Typography>
                                    </Box>
                                    <InputFormChallenge
                                        value={name}
                                        maxLength={30}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={`${classes.boxFildsMobile} ${classes.paddingLeft} ${classes.marginField}`} xs={6}>
                                <Typography className={`${classes.label} ${classes.marginH2Init}`} style={{ width: 'fit-content' }} component="h2" variant="h2">
                                    {textsFormChallenge.titleCategory}
                                </Typography>

                                <SelectFormChallenge
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    displayEmpty
                                >
                                    <ListOptionsModel urlParam="categorias" />
                                </SelectFormChallenge>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.gridTextImage} item xs={12}>
                            <Grid container item className={classes.boxTextImage} xs={9}>
                                <Grid item xs={12}>
                                    <Box mt={2} mb={2} display='flex'>
                                        <Typography className={`${classes.label} ${classes.right}`} component="h2" variant="h2">
                                            {textsFormChallenge.titleObjective}
                                        </Typography>
                                        <Typography className={classes.caractersSize} component="p" variant="subtitle2">
                                            {remainCharsObjective}
                                        </Typography>
                                    </Box>
                                    <TextareaAutosizeCustom
                                        className={classes.textAreaPlaceholder}
                                        maxLength={120}
                                        placeholder={textsTextAreaPlaceholder.textFormChallengeObjective}
                                        rowsMin={5}
                                        value={objective}
                                        onChange={(e) => setObjective(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={clsx(`gridDropzoneImage ${classes.boxTextImage} ${classes.paddingLeft}`)} xs={3}>
                                {editImage || imageUrl === '' ? (
                                    <React.Fragment>
                                        <DropzoneAreaBase
                                            acceptedFiles={['image/*']}
                                            dropzoneClass="dropzone dropzoneImage"
                                            dropzoneText={image?.length < 1 ? textsDropzone.textImage : ''}
                                            showAlerts={false}
                                            filesLimit={1}
                                            maxFileSize={1000000000}
                                            fileObjects={image}
                                            onAdd={handleAddImage}
                                            onDelete={handleDeleteImage}
                                        />
                                        {editImage && image.length === 0 ? <Box display='flex' width='100%' mt='-189px'>
                                            <CancelIcon className={classes.iconChangeEdit} onClick={() => { setEditImage(false) }} />
                                        </Box> : ''}
                                    </React.Fragment>
                                ) : (<Box className={classes.boxImage} onClick={() => { setEditImage(true) }}>
                                    <img className={classes.boxImage} src={imageUrl} alt="image_perfil" onError={getImageError} />
                                </Box>
                                )}


                            </Grid>
                        </Grid>

                        <Grid container item xs={12} >
                            <Grid item xs={12}>
                                <Box mt={2} mb={2} display='flex'>
                                    <Typography className={`${classes.label} ${classes.right}`} component="h2" variant="h2">
                                        {textsFormChallenge.titleDetails}
                                    </Typography>
                                    <Typography className={classes.caractersSize} component="p" variant="subtitle2">
                                        {remainCharsDetails}
                                    </Typography>
                                </Box>
                                <TextareaAutosizeCustom
                                    className={classes.textAreaPlaceholder}
                                    maxLength={4000}
                                    placeholder={textsTextAreaPlaceholder.textFormChallenge}
                                    rowsMin={5}
                                    value={details}
                                    onChange={(e) => setDetails(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.boxGridFildsMobile} item xs={12} >
                            <Grid container item className={`${classes.boxFildsMobile} ${classes.paddingRight}`} xs={4}>
                                <Typography className={`${classes.label} ${classes.marginH2}`} component="h2" variant="h2">
                                    {textsFormChallenge.titleVideo}
                                </Typography>
                                <InputFormChallenge
                                    value={video}
                                    placeholder="id youtube"
                                    onChange={(e) => setVideo(e.target.value)}
                                />
                            </Grid>
                            <Grid container item className={`${classes.boxFildsMobile} ${classes.marginField} ${classes.paddinRightLeft}`} xs={4}>
                                <Typography className={`${classes.label} ${classes.marginH2}`} component="h2" variant="h2">
                                    {textsFormChallenge.titleStatus}
                                </Typography>
                                {edit ?
                                    <SelectFormChallenge
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        required
                                    >
                                        <option key={0} value="Aberto">Aberto</option>
                                        <option key={1} value="Finalizado">Finalizado</option>
                                        <option key={2} value="Fechado">Fechado</option>
                                    </SelectFormChallenge>
                                    :
                                    <InputFormChallenge
                                        value={status}
                                        disabled
                                        required
                                    />
                                }
                            </Grid>
                            <Grid container item className={`${classes.boxFildsMobile} ${classes.marginField} ${classes.paddingLeft}`} xs={4}>
                                <Typography className={`${classes.label} ${classes.marginH2}`} component="h2" variant="h2">
                                    {textsFormChallenge.titleSpotlight}
                                </Typography>
                                <SelectFormChallenge
                                    value={spotlight}
                                    onChange={(e) => setSpotlight(e.target.value)}
                                    displayEmpty
                                >''
                                    <option key={0} value="N">Não</option>
                                    <option key={1} value="S">Sim</option>
                                </SelectFormChallenge>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Box mt={2} mb={2} display='flex'>
                                    <Typography className={`${classes.label} ${classes.right}`} component="h2" variant="h2">
                                        {textsFormChallenge.titleZones}
                                    </Typography>
                                    <Typography className={classes.caractersSize} component="p" variant="subtitle2">
                                        {remainCharsArea >= 0 ? remainCharsArea : 'No máximo 5 áreas'}
                                    </Typography>
                                </Box>
                                <SelectMultipleModel
                                    urlParam="areas"
                                    areas={areas}
                                    setAreasIds={setAreasIds}
                                    handleChangeAreas={handleChangeAreas}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                {edit ? <Box mt={4}>
                    <Box mt={2} mb={2} display="flex">
                        <Typography className={`${classes.label} ${classes.right}`} component="h2" variant="h2">
                            {textsFormChallenge.titleMaterial}
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        {loadFiles.length > 0 ? <ListFiles files={loadFiles} edit={edit} canEdit={canEdit} isMaterials setUpdateChallenge={setLoadDesafio} /> : ''}
                    </Box>

                    <Box>
                        <DropzoneAreaBase
                            dropzoneClass="dropzone"
                            dropzoneText="Clique para adicionar ou solte os arquivos aqui. Máximo até 10 mb"
                            filesLimit={5}
                            maxFileSize={1000000000}
                            showFileNames={true}
                            fileObjects={files}
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            showAlerts={false}
                        />
                    </Box>
                </Box> : ''}

                <Box mt={4} textAlign={'center'}>
                    <Typography component="p" className={`${classes.textCenter}`} variant="subtitle2">
                        {textsFooterDetail.textFormChallenge}
                    </Typography>
                </Box>

                <Box mt={4}>
                    <SubmitButton type="submit">
                        {requesting ? (
                            <LineScalePulseOut
                                color={'#FFFFFF'}
                                className="loading-center"
                                loading={requesting}
                            />
                        ) : (
                            textsSubmitButtons.textFormChallenge
                        )}

                    </SubmitButton>
                </Box>
            </form>
        </Box>
    )

}