import styled from "styled-components";

import backgroundTwo from "../../../../assets/backgroundTwo.svg";

export const Container = styled.div`
  background-image: url(${backgroundTwo});
  background-size: cover;
  width: 100%;
  margin-top: 766px;
  h1 {
    margin: 80px;
    text-align: center;
  }
`;

export const ContentComoFunciona = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  height: 200px;
  background-color: #fe5d00;
  border-radius: 10px;

  @media (max-width: 630px) {
    margin-left:10px;
    margin-right:10px;
  }

  p {
    font-size: 14px;
    color: #ffffff;
    line-height: 2em;
    font-weight: bold;
    margin-left: 20px;
    @media (max-width: 630px) {
      margin-right: 20px;
    }
  }
`;

export const InovacaoSubtitle = styled.h2`
  color: #fe5d00;
  font-size: 50px;
  margin-right: auto;
  @media (max-width: 630px) {
    font-size: 40px;
  }
`;

export const InvencaoSubtitle = styled.h2`
  color: #fe5d00;
  font-size: 50px;
  margin-left: auto;
  @media (max-width: 630px) {
    font-size: 40px;
  }
`;

export const AspasImgA = styled.img`
  margin-top: 60px;
  @media (max-width: 630px) {
    display: none;
  }
`;

export const AspasImgB = styled.img`
  margin-top: 200px;
  margin-right: 10px;
  @media (max-width: 630px) {
    display: none;
  }
`;

export const SpanOne = styled.p`
  margin-left: 100px;
  font-size: 20px;
  line-height: 2em;
  @media (max-width: 630px) {
    margin-left: 20px;
    font-size: 16px;
  }
`;

export const SpanTwo = styled.p`
  font-size: 20px;
  line-height: 2em;
  text-align: right;
  margin-right: 100px;
  @media (max-width: 630px) {
    margin-right: 20px;
    font-size: 16px;
  }
  /* margin-bottom: 50px; */
`;

export const DivSpanText = styled.div`
  width: 100%;
  padding: 50px 0px;
`;
