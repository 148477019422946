import { Box, Card, CardContent, Modal, Typography } from '@material-ui/core';
import texts from './texts';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '30%',
    padding: '15px 1rem 0 1rem',

    '@media (max-width: 1290px)': {
      width: '50%',
    },
    '@media (max-width: 800px)': {
      width: '90%',
    }
  }
}))

export default function InfoModal({ open = false, handleClose, type }) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={open}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.card}>
          <CardContent >
            <Box textAlign="left" display={"flex"} justifyContent="flex-start" >
              <Box mr={1} >
                <Typography component="h1" variant="body2">
                  {texts[type].title}
                </Typography>
              </Box>
            </Box>
            <Box textAlign="left" display={"flex"} justifyContent="flex-start" style={{ marginTop: '15px' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontSize: '16px' }}>
                {texts[type].content}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </div >
  );
}
