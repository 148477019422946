import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { textsMainPage } from '../../../utils/utils';
import CardInvoiceHomeSupplierGraph from '../../../components/CardInvoiceHomeSupplierGraph';
import CardProfileSupplier from '../../../components/CardProfileSupplier';
import CardRankingSupplier from '../../../components/CardRankingSupplier';
import CardINegotiationEvolutionSupplierGraph from '../../../components/CardINegotiationEvolutionSupplierGraph';
import { UserContext } from '../../../providers/UserProvider';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { getSupplierData } from '../../../services/home';
import AccordionChallengesLastMessageSupplier from '../../../components/AccordionChallengesLastMessage';
import ListNews from '../../../components/ListNews';
import { getChallengeResponses } from '../../../services/propostas';
import { getFornecedor, getNegotiations } from '../../../services/fornecedores';
import { getNews } from '../../../services/news';
import { getOrderPizzaGraph } from '../../../services/orders';
import { useLoading } from '../../../providers/LoadingProvider';
import { useParams } from 'react-router-dom';
import { raiseAlert } from '../../../components/Alert';
import { isBibiUser } from '../../../utils/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  finished: {
    border: '1px solid #FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  finishedText: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  selectedText: {
    textTransform: 'capitalize',
    color: theme.palette.default.main
  },
  title: {
    fontSize: 36,
    color: "#FFFFFF",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    alignItems: "baseline",
    color: "#FFFFFF"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),
  },
  orangeSet: {
    backgroundColor: "#FE5D00",
    padding: '0rem 0rem 5rem 0rem'
  },
  whiteSet: {
    backgroundColor: "#FFFFFF",
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem'
  }
}));

export default function Home() {
  const classes = useStyles();
  const [challenges, setChallenges] = useState([])
  const [negotiations, setNegotiations] = useState([])
  const [news, setNews] = useState([])
  const [ranking, setRanking] = useState(null)
  const { userState, setUserState } = useContext(UserContext);
  const [nome, setNome] = useState('')
  const [orderPizza, setOrderPizza] = useState(null)
  const [countSupplier, setCountSupplier] = useState(0)
  const { setLoading } = useLoading();
  const [parceiroDesde, setParceiroDesde] = useState('')
  const [pendingDocuments, setPendingDocuments] = useState('')
  const { id, pessoa } = useParams();

  let permissions = [];
  if (Array.isArray(userState?.permissoes)) {
    permissions = userState?.permissoes
  }
  const isFinancialRole = permissions.includes('FI') || userState.tipo !== 'F'
  const isOSRole = permissions.includes('OS') || userState.tipo !== 'F'

  useEffect(() => {
    async function requestNegotiations() {
      try {
        setLoading(true);
        const supplierData = await getFornecedor(userState?.fornecedor);
        const count = await getSupplierData()
        if (supplierData?.data[0]?.COD_PESSOA_SFT) {
          const data = await getNegotiations(supplierData.data[0].COD_PESSOA_SFT);
          const pizza = await getOrderPizzaGraph(supplierData.data[0].COD_PESSOA_SFT);
          setNegotiations(data)
          setOrderPizza(pizza)
          setRanking(supplierData.data[0].POS_RANKING)
          setNome(supplierData.data[0].NOME)
          setParceiroDesde(supplierData.data[0]?.PARCEIRO_DESDE)
          setUserState({ ...userState, NOME: supplierData.data[0]?.NOME, EMAIL: supplierData.data[0]?.EMAIL })
          setPendingDocuments(supplierData.data[0].STATUS === 'Incompleto')
        }
        const chalengeResponse = await getChallengeResponses(userState?.ID);
        const newsData = await getNews(4);
        setNews(newsData.slice(0, 4))
        setCountSupplier(count.data.FORNECEDORES)
        setChallenges(chalengeResponse.data)
        setLoading(false);
      } catch (err) {
        if (err.message.includes('401')) {
          raiseAlert('Seu acesso expirou')
          // history.push(`${mockPath}sair`)
        }
        setLoading(false);
      }
    }

    async function requestNegotiationsByAdmin() {
      setLoading(true);
      const supplierData = await getFornecedor(id);
      const chalengeResponse = await getChallengeResponses(id);
      if (pessoa) {
        const data = await getNegotiations(pessoa);
        const pizza = await getOrderPizzaGraph(pessoa);
        setNegotiations(data)
        setOrderPizza(pizza)
      }
      const count = await getSupplierData()
      const newsData = await getNews(4);
      setNews(newsData.slice(0, 4))
      setNome(supplierData.data[0].NOME)
      setParceiroDesde(supplierData.data[0]?.PARCEIRO_DESDE)
      setPendingDocuments(supplierData.data[0].STATUS === 'Incompleto')
      setCountSupplier(count.data.FORNECEDORES)
      setChallenges(chalengeResponse.data)
      setRanking(supplierData.data[0].POS_RANKING)
      setLoading(false);
    }

    // eslint-disable-next-line no-restricted-globals
    if (!location.pathname.includes('visualizar-fornecedor')) {
      requestNegotiations();
    } else {
      requestNegotiationsByAdmin();
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box className={clsx(`${classes.orangeSet} `, {
          'fit': isBibiUser(userState?.tipo)
        })}
          textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsMainPage.homeTitle}
            </Typography>
          </Box>
        </Box>
        <Box className={`header-div ${classes.whiteSet}`}>
          <Box className={`${classes.mainCards}`}>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={'auto'} md={5} lg={5} >
                <CardProfileSupplier userState={userState} nome={nome} parceiroDesde={parceiroDesde} pendingDocuments={pendingDocuments} />
              </Grid>
              {(permissions.includes('RA') || userState.tipo !== 'F') &&
                <Grid item xs={12} sm={'auto'} md={3} lg={3} >
                  <CardRankingSupplier ranking={ranking} totalSuppliers={countSupplier} />
                </Grid>
              }
            </Grid>
            <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
              {isOSRole &&
                <Grid item xs={12} sm={6} md={4} lg={4} >
                  <CardInvoiceHomeSupplierGraph respData={orderPizza} userState={userState} padding="24px" />
                </Grid>
              }
              {isFinancialRole &&
                <Grid item xs={12} sm={6} md={8} lg={8} >
                  <CardINegotiationEvolutionSupplierGraph graphData={negotiations} width={12} />
                </Grid>
              }
              {(permissions.includes('NO') || userState.tipo !== 'F') &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <ListNews news={news} />
                </Grid>
              }
              {(permissions.includes('DE') || userState.tipo !== 'F') &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <AccordionChallengesLastMessageSupplier challenges={challenges} />
                </Grid>
              }
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}