import React from 'react'

import Model from '../../../components/Model/index'
import { textsTitles, textsDescriptions } from '../../../utils/utils';

export default function AdminZone() {
  
  return (
    <React.Fragment>
      <Model urlModel="areas" nomeModel={textsTitles.textTitleZone} descricaoModel={textsDescriptions.textDescriptionZone} />
    </React.Fragment>
  );
}
