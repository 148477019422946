import React, { useState, useRef, createRef, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { challengesMainStatus, filterKey, myChallengesStatus, mockPathDesafios } from '../../utils/utils';
import { Collapse, IconButton, useMediaQuery, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import logoBibi from "../../assets/logo.svg";
import { useHistory } from 'react-router-dom';
import { raiseAlert } from '../Alert';
import { UserContext } from '../../providers/UserProvider';
import { isBibiUser, isSupplierUser } from '../../utils/roles';

export default function CardMain({ item }) {
  const useStyles = makeStyles(theme => ({
    root: {

      textAlign: 'center',
      width: 'auto',
      minWidth: 'fit-content',

      '&:hover': {
        cursor: "pointer",
        boxShadow: '0px 0px 5px #FE5D00'
      }
    },
    image: {
      height: 150,
      width: 150,
      borderRadius: 5,
      marginBottom: 8,
      objectFit: 'cover'
    },
    title: {
      //textAlign:'center',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'start'
    },
    media: {
      height: 140,
      display: 'flex'
    },
    expand: {
      margin: '0 0 0 auto'
    },
    category: {
      marginBottom: 0,
      marginTop: 'auto',
      backgroundColor: theme.palette.primary.main
    },
    categoryTag: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(2 / 3, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600
    },
    collapseText: {
      color: '#6C6C6C',
      fontSize: 14,
      fontWeight: 500,
      //lineHeight: '1.8em',
      textAlign: 'start'
    }
  }));

  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [cardImage, setCardImage] = useState('');
  const matches = useMediaQuery('(max-width:900px)');
  const cardRef = createRef();
  const { userState, setUserState } = useContext(UserContext);
  const expansionCardRef = useRef(null);
  useEffect(() => {
    if (item?.IMAGEM) {
      setCardImage((process.env.REACT_APP_BASE_URL + item?.IMAGEM))
    } else {
      setCardImage(logoBibi)
    }
  }, [item])
  const handleRedirect = async (item) => {
    if (!item?.ID) { raiseAlert('Desafio não encontrado!'); return; }
    if (item?.STATUS_PROPOSTA) {
      /*compara status para saber se está Inscrito */
      if (item.STATUS_PROPOSTA === myChallengesStatus[0]) {
        setUserState({ ...userState, DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID })
        history.push(`${mockPathDesafios}meu-desafio/enviar-proposta`, { DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID, STATUS: item?.STATUS })
      } else {
        /*compara status para saber se está finalizado */
        if (item?.STATUS === filterKey[challengesMainStatus[1]]) {
          raiseAlert('Desafio finalizado!');
          history.push(`${mockPathDesafios}meu-desafio`, { DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID, STATUS: item?.STATUS })
        } else {
          setUserState({ ...userState, DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID })
          history.push(`${mockPathDesafios}meu-desafio`, { DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID, STATUS: item?.STATUS })
        }
      }
    } else {
      if (item?.STATUS === filterKey[challengesMainStatus[0]]) {
        setUserState({ ...userState, DESAFIO_ID: item?.ID })
        history.push(`${mockPathDesafios}desafio-detalhes`, { DESAFIO_ID: item?.ID })
      } else {
        setUserState({ ...userState, DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID })
        if (isBibiUser(userState?.tipo)) {
          history.push(`${mockPathDesafios}editar-desafio`, { DESAFIO_ID: item?.ID, PROP_ID: item?.PROP_ID, STATUS: item?.STATUS })
        } else if (isSupplierUser(userState?.tipo)) {
          history.push(`${mockPathDesafios}desafio-detalhes`, { DESAFIO_ID: item?.ID })
        }
      }
    }
  }
  const handleExpanded = async (input) => {
    setExpanded(input)
  }

  return (
    <Card ref={cardRef} className={classes.root} onMouseEnter={() => { handleExpanded(true) }} onMouseLeave={() => { handleExpanded(false) }}>
      <CardActionArea onClick={() => handleRedirect(item)}>
        <CardMedia
          className={classes.media}
          image={cardImage || logoBibi}
          title={item?.NOME || 'Sem informação'}
        />
        {/* </CardMedia> */}
        <Box textAlign="start" className={classes.category}>
          <Typography className={classes.categoryTag} variant="subtitle2" component="h5">
            {item?.NOME_CATEGORIA}
          </Typography>
        </Box>

        <CardContent>

        </CardContent>
      </CardActionArea>
      {matches ? (
        <CardActions disableSpacing>
          <Typography className={classes.title} gutterBottom variant="h5" component="h2">
            {item?.NOME}
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={(event) => { handleExpanded(expanded ? false : true) }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>) : (
        <CardActions disableSpacing>
          <Typography className={classes.title} gutterBottom variant="h5" component="h2">
            {item?.NOME}
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={(event) => { handleExpanded(expanded ? false : true) }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      )}


      <Collapse ref={expansionCardRef} in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" component="p" className={classes.collapseText}>
            {item?.OBJETIVO}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}