import { Box, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Calendar from "../../assets/calendar.png";
import BaseModal from '../BaseModal';

import ReactTimeAgo from 'react-time-ago';
// import TimeAgo from 'javascript-time-ago';
// import pt from 'javascript-time-ago/locale/pt.json';

// TimeAgo.addDefaultLocale(pt)

export function ShowModal({ notice, open, onClose }) {
  const matches = useMediaQuery('(max-width:900px)');

  return (
    <BaseModal
      open={open}
      handleClose={() => onClose()}
      cardStyle={{ padding: '0 1rem', width: matches ? '90%' : '50%', minHeight: 0, maxHeight: '90vh', overflowY: 'scroll' }}
      contentStyle={{ padding: '0 8px', fontWeight: 'normal' }}>
      <h1 style={matches ? {
        fontSize: 22,
      } : {}}>{notice?.TITULO}</h1>
      <Box textAlign="left" display={"flex"} alignItems={"center"} >
        <img src={Calendar} alt="calendario" />
        <Box ml={1} >
          <Typography component="h1" variant="subtitle2" style={{
            color: "#FE5D00",
            fontSize: 14,
            fontWeight: 'bold'
          }}>
            <ReactTimeAgo date={new Date(notice?.DATA_CADASTRO)} />
          </Typography>
        </Box>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: notice?.CONTEUDO }} />
    </BaseModal>
  )
}