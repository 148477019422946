import React, { useState, useEffect, useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import { myChallengesStatus, textsMyChallenges, filterKey } from '../../../utils/utils';
import CustomButtonGroup from '../../../components/CustomButtonGroup';
import CardMainGroup from '../../../components/CardMainGroup';
import { UserContext } from '../../../providers/UserProvider';
import { getMeusDesafiosWithParams } from '../../../services/desafios';
import { raiseAlert } from '../../../components/Alert';
import { isSupplierUser } from '../../../utils/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit'
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'

  },
  finished: {
    border: '1px solid #FFFFFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  finishedText: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  selectedText: {
    textTransform: 'capitalize',
    color: theme.palette.default.main
  },
  title: {
    fontSize: 36,
    color: "#FFFFFF",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  subtitle: {
    //display:"flex",
    alignItems: "baseline",
    color: "#FFFFFF"
  },
  form: {

    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fakeLink: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    }
  },
  textAdapt: {
    color: "#FE5D00",
    textTransform: 'none',
    backgroundColor: 'transparent!important',
    padding: theme.spacing(1 / 2),


  },
  orangeSet: {
    backgroundColor: "#FE5D00",
    //minHeight:'45vh',
    padding: '0rem 0rem 5rem 0rem'

  },
  whiteSet: {
    backgroundColor: "#FFFFFF",
    height: 0
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-5rem'
  }

}));

export default function MyChallenges() {
  const classes = useStyles();
  const [selected, setSelected] = useState('Inscrito')
  const [load, setLoad] = useState(null)
  const [requesting, setRequesting] = useState(false)
  const [filter, setFilter] = useState(`STATUS=${filterKey[selected]}`)
  const { userState } = useContext(UserContext)

  useEffect(() => {
    setRequesting(true)
    setFilter(`STATUS=${filterKey[selected]}`)
  }, [selected]);

  useEffect(() => {
    async function loadMyChallenges() {
      await getMeusDesafiosWithParams(userState?.ID, filter).then(resp => {
        if (resp.status === 200) {
          setRequesting(false)
          setLoad(resp.data)
        }
      }).catch(async err => {
        raiseAlert(err.error)
        setRequesting(false)
      });
    }
    if (userState?.ID && filter) loadMyChallenges();
  }, [filter, userState]);

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box className={clsx(`orangeDiv ${classes.orangeSet} `, {
          'fit-div-challenges': isSupplierUser(userState?.tipo)
        })} textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography className={classes.title} component="h1" variant="subtitle1">
              {textsMyChallenges.title}
            </Typography>
            <Typography className={classes.subtitle} component="h1" variant="subtitle2">
              {textsMyChallenges.subtitle}
            </Typography>
            <Box className="inner-div-main-buttons" mt={2} mb={2} display="flex" justifyContent="center">
              <CustomButtonGroup load={myChallengesStatus}
                selected={selected === '' ? myChallengesStatus[0] : selected}
                setSelected={setSelected} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.whiteSet}>
          <Box className={`header-div mob ${classes.mainCards}`}>
            <CardMainGroup requesting={requesting} load={load} />
          </Box>
        </Box>
      </Container>
    </div>
  );
}