import React from "react";
import sustentabilidade from "../../../../assets/sustentabilidade.png";
import treeVector from "../../../../assets/treeVector.svg";
import { Container, DivTwo, SustentabilidadeImage } from "./styles";
import { SUSTENTABILITY_GUIDE_ADDRESS, textLandingPage2 } from "../../../../utils/utils";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 36,
    color: "#000000",
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    margin: '3.5rem'
  },
  positioneItem: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    '@media(max-width: 938px)': {
      display: 'block',
      textAlign: 'center'
    }
  },
  texts: {
    margin: '80px',
    width: '45%',
    maxWidth: '60%',
    '@media(max-width: 938px)': {
      maxWidth: '95%',
      width: '90%',
      margin: 30
    }
  },
  texts2: {
    fontSize: 20
  },
  textButton: {
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '33px',
  },
  textSustentability: {
    fontWeight: 600
  },
  tree: {
    marginLeft: 0,
    marginRight: 12,
  }
}));

// function useWindowSize() {
//   const [windowSize, setWindowSize] = useState({
//     width: undefined,
//     height: undefined,
//   });

//   useEffect(() => {
//     function handleResize() {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }

//     window.addEventListener("resize", handleResize);
//     handleResize();
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   if (windowSize.width <= 360) {
//     return "30px";
//   }
//   if (windowSize.width <= 500) {
//     return "50px";
//   }
//   if (windowSize.width <= 900) {
//     return "53px";
//   }
//   return "70px";
// }

export default function Sustentabilidade() {
  const classes = useStyles();

  function ButtonClick() {

    window.open(`${SUSTENTABILITY_GUIDE_ADDRESS}`)
  }

  return (
    <Container id="sustentabilidade">
      {/* <Box textAlign="center">
          <Box paddingTop={2} textAlign="center" className="header-div">
              <Typography className={classes.title} component="h1">
                Sustentabilidade
              </Typography>
          </Box>
      </Box> */}
      <Box className={classes.positioneItem}>
        <Box className={classes.texts}>
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.sustainabilityText1}
          </Typography>
          &nbsp;
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.sustainabilityText2}
          </Typography>
          &nbsp;
          <Typography className={classes.texts2} variant='subtitle2' component='p'>
            {textLandingPage2.sustainabilityText3}
          </Typography>
        </Box>
        <SustentabilidadeImage src={sustentabilidade} alt="sustentabilidade" />
      </Box>
      <DivTwo>
        <Button endIcon={<img className={classes.tree} src={treeVector} height={35} alt="arvore" />}
          onClick={ButtonClick}
        >
          <Box style={{ display: 'table' }}>
            <Typography className={classes.textButton} component='p'>
              Veja o relatório de
              {" "}
              <Typography className={`${classes.textButton} ${classes.textSustentability}`} component='b'>Sustentabilidade Bibi</Typography >
            </Typography>
          </Box>
        </Button>
        {/* <button onClick={ButtonClick}>{textsSlideTop.buttonText}</button> */}
      </DivTwo>
    </Container>
  );
}
