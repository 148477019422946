import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-family: 'Nunito';
`;

export const TitleToSmooth = styled.div`
  opacity: 0;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
`;
