import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

export default function MenuListComposition({ parent = { text: '', children: [] }, goToPage, goToAddress, permissoes, tipo }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  function goToPageMenu(item) {
    handleClose();
    item?.open ? goToAddress(item.path) : goToPage(item.path)
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
        className='menu-dynamic'
        disableTouchRipple>
        <Typography color="inherit" className={'text-adapt fake-link text-menu'} variant="subtitle1">
          {parent.text}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {parent.children?.length > 0 ? (
          parent.children.map((item, index) => (
            <MenuItem key={`${parent.text}item${index}`} onClick={() => { goToPageMenu(item) }} >
              <Typography className={'over-option'} variant="subtitle1">
                {item.text}
              </Typography>
            </MenuItem>
          ))
        ) : ('')}
      </Menu>
    </div>

  );
}
